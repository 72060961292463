import _ from 'lodash';
import React from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AddressDetailsModal from './AddressDetailsModal';
import { useBaselineComparison } from './baselineComparison';
import { useEditHoursOfOperationSettings } from './hoursTabUtil';
import PickLocationToActivateModal from './PickLocationToActivateModal';
import { LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import {
  DataInProgress,
  NoDataAvailableError,
  NoDimensionValueError,
  NoLocationsError,
  NotEnoughHistoricalData,
  NoValidDatasetsError,
} from './revenueDashboardErrors';
import {
  getDimColumnKey,
  gatherKnownLocationAndDimValueIds,
  useSevenDayForecastWindow,
  useFilteredRows,
  useFilteredForecastStatsRows,
  useMinMaxHourlyGridHours,
  usePricingRows,
  useDimensionPreviews,
} from './revenueUpliftDashboardComputations';
import { DomainContext, DashboardContext } from './revenueUpliftContexts';
import {
  useApprovedPricesApi,
  useDashboardSelectionState,
  useSelectTrialOrFirstFreeLocation,
} from './revenueUpliftDashboardHooks';
import { useLocationOptions, useStaffingRolesApi } from './staffingTabUtil';
import { useSavedRevenueUpliftState, useSaveOnChange } from './statePersistence';
import { parseDatasetDate, useDateRange, useProductDims } from './timePeriod';
import useConfigurableTimeDims from './useConfigurableTimeDims';
import {
  ALL_HOURS,
  CLOSE_TIME,
  DAYS_OF_WEEK,
  IS_CLOSED,
  OPEN_24_HOURS,
  OPEN_TIME,
} from '../../../accountSettings/locationSettings/locationManagementConstants';
import {
  endsOnNextDay,
  parseOpenCloseHour,
} from '../../../accountSettings/locationSettings/locationHoursOfOperationUtil';
import { Industry, DataType } from '../../../data-center/datasetConfig/DatasetConfigFormConstants';
import { ConfigSection } from '../../../generic/customerConfigConstants';
import RetryOrGoToFallback from '../../../generic/errorBoundary/RetryOrGoToFallback';
import {
  isLocationActive,
  KaizenProduct,
  KaizenProductStatus,
  SubscriptionType,
} from '../../../generic/subscriptions/SubscriptionConstants';
import Header from '../../../header/header';
import { useSubscriptionStatusForProduct } from '../../../routing/subscriptionStatusHooks';
import { HOUR_DIMENSION_TYPE, Objective, Output, USER_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { getWorkflowTitle, isEoPriceoptWorkflow } from '../../../workflow/workflowFunctions';
import {
  useAggregatedMetricHistorySummary,
  useAverageProductRevenueHistory,
  usePricingHistorySummary,
  useProcessedHistorySummaryMeta,
} from '../../../../data-access/query/aggregatedHistory';
import { useCustomerConfigSection } from '../../../../data-access/query/config';
import { useDatasets } from '../../../../data-access/query/datasets';
import { useDimensions } from '../../../../data-access/query/dimensions';
import { useDimensionValues } from '../../../../data-access/query/dimensionValues';
import { useForecastStats, useSummaryForecastStats } from '../../../../data-access/query/forecastStats';
import { useHoursOfOperation } from '../../../../data-access/query/hoursOfOperation';
import { useLocations } from '../../../../data-access/query/locations';
import { useWorkflow, useWorkflowBatchesWithStatus, useWorkflows } from '../../../../data-access/query/workflows';
import {
  useAverageProductRevenueForecast,
  useDailyForecastMeta,
  useDailyForecastStats,
  useKaizenPricingScenario,
  useKaizenPricingScenarioMeta,
  usePaceForecastStats,
  usePriceStatsMeta,
  usePricingRecommendationStats,
  useRecentPricingScenario,
  useRecentPricingScenarioMeta,
} from '../../../../data-access/query/priceStats';
import { useBillingInfo, useShopifyBillingInfo } from '../../../../data-access/query/subscriptions';
import { logExecutionTime } from '../../../../utils/performanceMonitoring';
import './revenue-uplift-dashboard.css';
import './detailed-view-toggle.css';

const ALLOWED_INDUSTRIES = Object.freeze([Industry.EO, Industry.MOVIE]);

const useFocusedDimensionState = () => {
  const [focusedDimensionId, setFocusedDimensionId] = React.useState();
  const clearFocusedDimension = () => setFocusedDimensionId(undefined);

  return { focusedDimensionId, setFocusedDimensionId, clearFocusedDimension };
};

/**
 * The dataset could contain obsolete data (e.g. locations that become inactive and/or
 * dimension values that got remapped/unmapped) so here we filter out those rows so they are not present
 * when the dashboard begins performing its computations
 */
const sanitizeRows = (rows, locations, dimensions, dimensionValues, dimensionIds) => {
  if (rows.length === 0) {
    return [];
  }

  const start = performance.now();

  const dimValueIdsByDimId = gatherKnownLocationAndDimValueIds(locations, dimensions, dimensionValues);

  const dimValueIdIsAcceptable = (row, dimensionId) => {
    const dimValueId = row[getDimColumnKey(dimensionId)];
    const setOfAcceptedDimValueIds = dimValueIdsByDimId[dimensionId]?.dimValueIds ?? new Set();

    return setOfAcceptedDimValueIds.has(dimValueId);
  };

  const acceptedRows = rows.filter(row => dimensionIds.every(dimensionId => dimValueIdIsAcceptable(row, dimensionId)));

  const end = performance.now();
  logExecutionTime('Data sanitation', start, end);

  return acceptedRows;
};

const useSanitizedRows = (rows, { userDimensionsOnly } = { userDimensionsOnly: false }) => {
  const { dimensions, locations, dimensionValues } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const productDimensionIds = dimensions
      .map(dim => dim.product_dimension_id)
      .filter(dim => (userDimensionsOnly ? dim.dimension_type === USER_DIMENSION_TYPE : true));
    const dimensionIds = [LOCATIONS_DIMENSION_ID, ...productDimensionIds];

    return sanitizeRows(rows, locations, dimensions, dimensionValues, dimensionIds);
  }, [rows, locations, dimensions, dimensionValues, userDimensionsOnly]);
};

const useSanitizedForecastStatsRows = rows => {
  const { dimensions, locations, dimensionValues } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const hourDimensionId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE);
    const dimensionIds = [LOCATIONS_DIMENSION_ID, hourDimensionId?.product_dimension_id];

    return sanitizeRows(rows, locations, dimensions, dimensionValues, dimensionIds);
  }, [rows, locations, dimensions, dimensionValues]);
};

const isForecastStatWorkflow = (workflow, currentWorkflow, dimensions) => {
  return (
    workflow.objective === Objective.TIPFORECAST &&
    workflow.outputs.some(output => output === Output.FORECAST_STATS) &&
    workflow.time_granularity === currentWorkflow.time_granularity &&
    dimensions.some(dimension => dimension.dimension_type === HOUR_DIMENSION_TYPE)
  );
};

const DashboardContextController = ({ children, workflowId }) => {
  const {
    dimensions,
    dimensionValues,
    locations,
    configTimeDimState,
    processedHistorySummaryMeta,
    dailyForecastMeta,
    paceHistoryRows,
    paceForecastRows,
    weeklyForecastRows,
    pricingRecommendationRows,
    savedDashboardState,
    format,
    forecastStatsDataset,
    forecastStatsSummary,
    historySummaryStatsRows,
    forecastStatsHistorySummaryMeta,
    progressComplete,
    batchInProgress,
    kaizenScenarioMeta,
    recentScenarioMeta,
    hoursOfOperation,
  } = React.useContext(DomainContext);

  const [pricingScenariosEnabled, setPricingScenariosEnabled] = React.useState(false);
  const [staffingEnabled, setStaffingEnabled] = React.useState(false);
  const [hoursEnabled, setHoursEnabled] = React.useState(false);

  // API for allowing the user to specify what dimension values they want to filter to in the dashboard
  const initialSavedSelectionState = _.get(savedDashboardState.data, 'selection');
  const dashboardSelectionState = useDashboardSelectionState(
    workflowId,
    initialSavedSelectionState,
    dimensions,
    dimensionValues,
    locations,
    configTimeDimState,
  );
  const { selectedDatasetDimValuesSets, selectionState, getDimValueLabel } = dashboardSelectionState;

  const [summaryDimensionId, setSummaryDimensionId] = React.useState(() => {
    const savedSummaryDimId = _.get(savedDashboardState.data, 'summary_tab.view_by_dim_id');

    if (savedSummaryDimId != null && savedSummaryDimId in selectionState) {
      // Used whatever was saved previously
      return savedSummaryDimId;
    }

    // Use any product dimension's id as the initial value
    const dimIds = Object.keys(selectionState);

    return dimIds
      .map(dimId => {
        try {
          return parseInt(dimId, 10);
        } catch {
          return null;
        }
      })
      .find(dimId => _.isFinite(dimId));
  });
  useSaveOnChange(workflowId, 'summary_tab.view_by_dim_id', summaryDimensionId);

  const { data: kaizenPricingScenario = [], isFetching: isFetchingKaizenPricingScenario } = useKaizenPricingScenario(
    workflowId,
    {
      enabled: pricingScenariosEnabled,
    },
  );
  const { data: recentPricingScenario = [], isFetching: isFetchingRecentPricingScenario } = useRecentPricingScenario(
    workflowId,
    {
      enabled: pricingScenariosEnabled,
    },
  );
  const { data: pricingHistory = [], isFetching: isFetchingPricingHistory } = usePricingHistorySummary(workflowId, {
    enabled: pricingScenariosEnabled,
  });

  const {
    data: averageProductRevenueForecast = [],
    isFetching: isFetchingAverageProductRevenueForecast,
  } = useAverageProductRevenueForecast(workflowId, {
    enabled: hoursEnabled,
  });
  const {
    data: averageProductRevenueHistory = [],
    isFetching: isFetchingAverageProductRevenueHistory,
  } = useAverageProductRevenueHistory(workflowId, {
    enabled: hoursEnabled,
  });

  const focusedDimensionState = useFocusedDimensionState();

  const sanitizedPaceHistory = useSanitizedRows(paceHistoryRows);
  const sanitizedPaceForecast = useSanitizedRows(paceForecastRows);
  const sanitizedWeeklyForecast = useSanitizedRows(weeklyForecastRows);
  const sanitizedPricingRecommendations = useSanitizedRows(pricingRecommendationRows, { userDimensionsOnly: true });

  const sanitizedKaizenPricingScenario = useSanitizedRows(kaizenPricingScenario);
  const sanitizedRecentPricingScenario = useSanitizedRows(recentPricingScenario);
  const sanitizedPricingHistory = useSanitizedRows(pricingHistory);

  const sanitizedAverageProductRevenueForecast = useSanitizedRows(averageProductRevenueForecast);
  const sanitizedAverageProductRevenueHistory = useSanitizedRows(averageProductRevenueHistory);

  const sanitizedForecastStats = useSanitizedForecastStatsRows(forecastStatsDataset);
  const sanitizedForecastStatsSummary = useSanitizedForecastStatsRows(forecastStatsSummary);
  const sanitizedHistorySummaryStats = useSanitizedForecastStatsRows(historySummaryStatsRows);

  const hasForecastStats = sanitizedForecastStats.length > 0;

  const minAndMaxStatisticHistoryDates = React.useMemo(() => {
    const { min_date: minDate, max_date: maxDate } = processedHistorySummaryMeta;
    return [minDate, maxDate];
  }, [processedHistorySummaryMeta]);

  const minAndMaxPriceStatsDates = React.useMemo(() => {
    const { min_date: minDate, max_date: maxDate } = dailyForecastMeta;
    return [minDate, maxDate];
  }, [dailyForecastMeta]);

  const forecastStatsMinAndMaxHistoryDates = React.useMemo(() => {
    const { min_date: minDate, max_date: maxDate } = forecastStatsHistorySummaryMeta;
    return [minDate, maxDate];
  }, [forecastStatsHistorySummaryMeta]);

  const pricingScenariosMinAndMaxDates = React.useMemo(() => {
    const { min_date: kaizenMinDate, max_date: kaizenMaxDate } = kaizenScenarioMeta;
    const { min_date: recentMinDate, max_date: recentMaxDate } = recentScenarioMeta;

    const minDate = _.max([kaizenMinDate, recentMinDate]);
    const maxDate = _.min([kaizenMaxDate, recentMaxDate]);

    if (minDate > maxDate) {
      return [null, null];
    }

    return [minDate, maxDate];
  }, [kaizenScenarioMeta, recentScenarioMeta]);

  const baselineComparison = useBaselineComparison();
  const productDimsState = useProductDims();
  const dimensionPreviews = useDimensionPreviews(dashboardSelectionState);

  const { openHoursPerDayPerLocation, shiftHoursPerDayPerLocation } = React.useMemo(() => {
    const dayOpenHours = {};
    const dayShiftHours = {};

    hoursOfOperation.forEach(({ location_id: locationId, hours_of_operation_by_day: hoursByDay = [] }) => {
      const openHoursPerDay = {};
      const shiftHoursPerDay = {};

      DAYS_OF_WEEK.forEach(day => {
        openHoursPerDay[day] = [];
        shiftHoursPerDay[day] = [];
      });

      hoursByDay.forEach(({ day_of_week: day, hours_of_operation: hoursOfOperationArray }) => {
        if (!DAYS_OF_WEEK.includes(day)) {
          return;
        }

        const dayHours = hoursOfOperationArray[0] ?? {}; // There should only be one set of hours per day
        const {
          [IS_CLOSED]: isClosed,
          [OPEN_24_HOURS]: open24,
          [OPEN_TIME]: openTime,
          [CLOSE_TIME]: closeTime,
        } = dayHours;
        const openParsed = parseOpenCloseHour(openTime, OPEN_TIME);
        const closeParsed = parseOpenCloseHour(closeTime, CLOSE_TIME);

        if (open24) {
          openHoursPerDay[day] = ALL_HOURS;
          shiftHoursPerDay[day] = ALL_HOURS.map(hour => ({ hour, isNextDay: false }));
        } else if (!isClosed && ALL_HOURS.includes(openParsed) && ALL_HOURS.includes(closeParsed)) {
          const openTimeIndex = ALL_HOURS.indexOf(openParsed);
          const closeTimeIndex = ALL_HOURS.indexOf(closeParsed);
          const currentDayHours = openHoursPerDay[day] ?? [];

          if (endsOnNextDay(openTime, closeTime)) {
            const nextDay = DAYS_OF_WEEK[(DAYS_OF_WEEK.indexOf(day) + 1) % DAYS_OF_WEEK.length];
            const nextDayHours = openHoursPerDay[nextDay] ?? [];

            openHoursPerDay[day] = [...currentDayHours, ...ALL_HOURS.slice(openTimeIndex)];
            openHoursPerDay[nextDay] = [...ALL_HOURS.slice(0, closeTimeIndex), ...nextDayHours];

            shiftHoursPerDay[day] = [
              ...ALL_HOURS.slice(openTimeIndex).map(hour => ({ hour, isNextDay: false })),
              ...ALL_HOURS.slice(0, closeTimeIndex).map(hour => ({ hour, isNextDay: true })),
            ];
          } else {
            openHoursPerDay[day] = [...currentDayHours, ...ALL_HOURS.slice(openTimeIndex, closeTimeIndex)];

            shiftHoursPerDay[day] = ALL_HOURS.slice(openTimeIndex, closeTimeIndex).map(hour => ({
              hour,
              isNextDay: false,
            }));
          }
        }
      });

      dayOpenHours[locationId] = openHoursPerDay;
      dayShiftHours[locationId] = shiftHoursPerDay;
    });

    return { openHoursPerDayPerLocation: dayOpenHours, shiftHoursPerDayPerLocation: dayShiftHours };
  }, [hoursOfOperation]);

  const filteredPaceHistoryRows = useFilteredRows(sanitizedPaceHistory, selectedDatasetDimValuesSets, getDimValueLabel);
  const filteredPaceForecastRows = useFilteredRows(
    sanitizedPaceForecast,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );
  const filteredWeeklyForecastRows = useFilteredRows(
    sanitizedWeeklyForecast,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
    openHoursPerDayPerLocation,
  );
  const filteredPricingRecommendations = useFilteredRows(
    sanitizedPricingRecommendations,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
    {},
    { userDimensionsOnly: true },
  );

  const filteredAverageProductRevenueForecast = useFilteredRows(
    sanitizedAverageProductRevenueForecast,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );
  const filteredAverageProductRevenueHistory = useFilteredRows(
    sanitizedAverageProductRevenueHistory,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );

  const filteredForecastStats = useFilteredForecastStatsRows(
    sanitizedForecastStats,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
    openHoursPerDayPerLocation,
  );
  const filteredForecastStatsSummary = useFilteredForecastStatsRows(
    sanitizedForecastStatsSummary,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );
  const filteredHistorySummaryStats = useFilteredForecastStatsRows(
    sanitizedHistorySummaryStats,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );

  const pricingScenarioKaizenCompareRecentBuckets = React.useMemo(() => {
    const { price_change_buckets: priceChangeBuckets } = kaizenScenarioMeta;

    // this object will contain entries that are named after the comparison of the kaizen scenario to other scenarios
    const { recent } = priceChangeBuckets ?? {};

    return recent ?? {};
  }, [kaizenScenarioMeta]);

  const { filteredPricingHistory, pricingRows, unfilteredPricingRows } = usePricingRows(
    sanitizedKaizenPricingScenario,
    sanitizedRecentPricingScenario,
    pricingScenarioKaizenCompareRecentBuckets,
    sanitizedPricingHistory,
    selectedDatasetDimValuesSets,
  );

  const [, maxForecastDate] = minAndMaxPriceStatsDates;
  const sevenDayForecastApi = useSevenDayForecastWindow(
    filteredWeeklyForecastRows,
    filteredForecastStats,
    parseDatasetDate(maxForecastDate),
  );
  const { sevenDayForecastRows, sevenDayForecastStatsRows } = sevenDayForecastApi;

  const minMaxSevenDayForecastHours = useMinMaxHourlyGridHours(
    sevenDayForecastRows,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );
  const minMaxSevenDayForecastStatsHours = useMinMaxHourlyGridHours(
    sevenDayForecastStatsRows,
    selectedDatasetDimValuesSets,
    getDimValueLabel,
  );

  const initialTimePeriod = _.get(savedDashboardState.data, 'time_period');
  const dateRangeState = useDateRange(
    workflowId,
    initialTimePeriod,
    kaizenScenarioMeta,
    pricingScenariosMinAndMaxDates,
    baselineComparison,
  );

  const { selectedRange } = dateRangeState;
  const approvedPricesApi = useApprovedPricesApi(selectedRange);

  // Prevent the user from viewing the dashboard when there is no viable pricing data after sanitization
  const noAtAGlanceData =
    sanitizedWeeklyForecast.length === 0 ||
    sanitizedPricingRecommendations.length === 0 ||
    sanitizedPaceForecast.length === 0 ||
    sanitizedPaceHistory.length === 0;
  const noPricingData =
    (pricingScenariosEnabled && !isFetchingKaizenPricingScenario && sanitizedKaizenPricingScenario.length === 0) ||
    (pricingScenariosEnabled && !isFetchingRecentPricingScenario && sanitizedRecentPricingScenario.length === 0);
  const noHoursData =
    (!isFetchingAverageProductRevenueHistory && hoursEnabled && sanitizedAverageProductRevenueHistory.length === 0) ||
    (!isFetchingAverageProductRevenueForecast && hoursEnabled && sanitizedAverageProductRevenueForecast.length === 0);

  let error = null;

  if (noAtAGlanceData && error == null) {
    error = new NotEnoughHistoricalData();
  }

  if ((noPricingData || noHoursData) && error == null) {
    error = new NoDataAvailableError();
  }

  if (
    minAndMaxStatisticHistoryDates.some(date => date == null) ||
    minAndMaxPriceStatsDates.some(date => date == null) ||
    pricingScenariosMinAndMaxDates.some(date => date == null) ||
    (hasForecastStats && forecastStatsMinAndMaxHistoryDates.some(date => date == null)) ||
    !kaizenScenarioMeta.relative_dates
  ) {
    error = new NoDataAvailableError();
  }

  if (error != null) {
    throw progressComplete ? error : new DataInProgress(batchInProgress, workflowId);
  }

  const currencySymbol = format?.currency_symbol ?? '$';

  const locationsApi = useLocationOptions(selectionState, locations);
  const staffingRolesApi = useStaffingRolesApi({
    currentLocationId: locationsApi.selectedLocation.value,
    locations,
    staffingEnabled,
  });
  const hoursOfOperationSettingsApi = useEditHoursOfOperationSettings({
    currentLocationId: locationsApi.selectedLocation.value,
    locations,
    hoursEnabled,
  });

  const dashboardContextValue = {
    savedDashboardState,
    baselineComparison,
    summaryDimensionId,
    setSummaryDimensionId,
    approvedPricesApi,
    sevenDayForecastApi,
    dimensionPreviews,
    ...dashboardSelectionState,
    ...focusedDimensionState,
    ...dateRangeState,
    ...productDimsState,
    currencySymbol,
    minAndMaxStatisticHistoryDates,
    minAndMaxPriceStatsDates,
    forecastStatsMinAndMaxHistoryDates,
    minMaxSevenDayForecastHours,
    minMaxSevenDayForecastStatsHours,
    paceHistoryRows: filteredPaceHistoryRows,
    paceForecastRows: filteredPaceForecastRows,
    weeklyForecastRows: filteredWeeklyForecastRows,
    pricingRecommendationRows: filteredPricingRecommendations,
    hasForecastStats,
    weeklyForecastStatsRows: filteredForecastStats,
    forecastStatsSummaryRows: filteredForecastStatsSummary,
    historySummaryStatsRows: filteredHistorySummaryStats,
    isLoadingPricingScenarios:
      isFetchingKaizenPricingScenario || isFetchingRecentPricingScenario || isFetchingPricingHistory,
    isLoadingHours: isFetchingAverageProductRevenueHistory || isFetchingAverageProductRevenueForecast,
    pricingRows,
    unfilteredPricingRows,
    pricingHistory: filteredPricingHistory,
    averageProductRevenueForecast: filteredAverageProductRevenueForecast,
    averageProductRevenueHistory: filteredAverageProductRevenueHistory,
    locationsApi,
    staffingRolesApi,
    hoursOfOperationSettingsApi,
    openHoursPerDayPerLocation,
    shiftHoursPerDayPerLocation,
  };

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      {children({ setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled })}
    </DashboardContext.Provider>
  );
};

/**
 * This component is responsible for fetching all the relevant customer data needed to:
 * 0) Require any necessary actions by taken by the user before the dashboard can be used
 * 1) Interpret KDSP output data/datasets
 * 2) Throw errors if the dashboard cannot be accessed
 * 2) Render the various filtering controls
 */
export const DomainContextController = props => {
  const { children, Loading, workflowId, setWorkflowTitle } = props;

  const { data: priceStatsMeta = {}, isFetching: isFetchingPriceStatsMeta } = usePriceStatsMeta(workflowId);
  const { data: dailyForecastMeta = {}, isFetching: isFetchingDailyForecastMeta } = useDailyForecastMeta(workflowId);
  const {
    data: processedHistorySummaryMeta = {},
    isFetching: isFetchingProcessedHistorySummaryMeta,
  } = useProcessedHistorySummaryMeta(workflowId);
  const { data: kaizenScenarioMeta = {}, isFetching: isFetchingKaizenScenarioMeta } = useKaizenPricingScenarioMeta(
    workflowId,
  );
  const { data: recentScenarioMeta = {}, isFetching: isFetchingRecentScenarioMeta } = useRecentPricingScenarioMeta(
    workflowId,
  );

  // Fetch workflow data
  const { data: dimensions = [], isError: dimensionsError, isFetching: isFetchingDimensions } = useDimensions(true);
  const {
    data: dimensionValues,
    isError: dimensionValuesError,
    isFetching: isFetchingDimensionValues,
  } = useDimensionValues(false);
  const {
    subscriptionId,
    isFetching: isFetchingSubscription,
    isStripeSubscription,
    isShopifySubscription,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);
  const { data: locations = [], isError: locationsError, isFetching: isFetchingLocations } = useLocations();
  const { data: workflow, isError: workflowError, isFetching: isFetchingWorkflow } = useWorkflow(workflowId);
  const { data: datasets = [], isError: datasetsError, isFetching: isFetchingDatasets } = useDatasets();
  const {
    progressComplete = true,
    batchInProgress,
    isFetching: isFetchingWorkflowBatches,
  } = useWorkflowBatchesWithStatus(workflowId);

  React.useEffect(() => {
    if (workflow != null) {
      setWorkflowTitle(getWorkflowTitle(workflow));
    }
  }, [workflow, setWorkflowTitle]);

  const configTimeDimState = useConfigurableTimeDims(priceStatsMeta);

  const { data: format = {}, isFetching: isFetchingCustomerConfig } = useCustomerConfigSection(ConfigSection.FORMAT);

  const { data: workflows = [], isFetching: isFetchingWorkflows, isError: workflowsError } = useWorkflows();

  const savedDashboardState = useSavedRevenueUpliftState(workflowId);
  const { isFetching: isFetchingSavedDashboardState } = savedDashboardState;

  const {
    data: paceHistoryRows = [],
    isFetching: isFetchingPaceHistory,
    isError: paceHistoryError,
  } = useAggregatedMetricHistorySummary(workflowId);
  const {
    data: paceForecastRows = [],
    isFetching: isFetchingPaceForeast,
    isError: paceForecastError,
  } = usePaceForecastStats(workflowId);
  const {
    data: weeklyForecastRows = [],
    isFetching: isFetchingWeeklyForecast,
    isError: weeklyForecastError,
  } = useDailyForecastStats(workflowId);
  const {
    data: pricingRecommendationRows = [],
    isFetching: isFetchingPricingRecommendation,
    isError: pricingRecommendationError,
  } = usePricingRecommendationStats(workflowId);

  const dataFetchingErrors = [
    dimensionValuesError,
    dimensionsError,
    locationsError,
    workflowError,
    datasetsError,
    workflowsError,
  ];
  const errorExists = dataFetchingErrors.some(error => error);
  useErrorHandler(errorExists ? new NoDataAvailableError() : null);

  const allWorkflowLocationGroupLocations = locations.filter(
    /* eslint-disable-next-line eqeqeq */
    location => location.location_group_id == workflow?.location_group_id,
  );
  const allRelevantLocations = allWorkflowLocationGroupLocations.filter(location =>
    ALLOWED_INDUSTRIES.includes(location.industry),
  );
  const activatedEOLocations = allRelevantLocations.filter(location => {
    const subscriptionStatus = location.status_by_subscription_id[subscriptionId]?.status;
    const locationActivated =
      isStripeSubscription || isShopifySubscription ? isLocationActive(subscriptionStatus) : true;
    return ALLOWED_INDUSTRIES.includes(location.industry) && locationActivated;
  });

  const {
    selectedLocation,
    setSelectedLocation,
    onSelectLocation,
    onHideAddressDetailsModal,
    onUpdateLocation,
    showPickLocationToActivateModal,
    showAddressDetailsModal,
    isSubmittingTrialLocationSelection,
  } = useSelectTrialOrFirstFreeLocation(isFetchingLocations, allRelevantLocations);

  // Filter to the dimensions and dimension values that belong to this workflow
  const [workflowDimensions, workflowDimensionValues] = React.useMemo(() => {
    const workflowDimensionsIds = workflow?.dimensions ?? [];

    const wfDimensions = dimensions.filter(dim => workflowDimensionsIds.includes(dim.product_dimension_id));
    const wfDimensionValues = dimensionValues.filter(dimValue =>
      (workflowDimensionsIds ?? []).includes(dimValue.dimension_id),
    );

    return [wfDimensions, wfDimensionValues];
  }, [dimensionValues, dimensions, workflow]);

  // Filter validated datasets
  const validDatasets = datasets.filter(
    dataset =>
      dataset.is_validated &&
      ((dataset.industry === Industry.EO && dataset.data_type === DataType.TRAN) ||
        (dataset.industry === Industry.MOVIE && dataset.data_type === DataType.TICKET)),
  );

  const hasNoValidDatasets = validDatasets.length === 0;

  const priceFrequencyDetails = workflow?.price_changes ?? [];

  const currentWorkflow = workflows?.find(wf => wf.workflow_id === workflowId);
  const tipForecastWorkflow = workflows?.find(wf => isForecastStatWorkflow(wf, currentWorkflow, workflowDimensions));
  const shouldLoadForecastStats = !!tipForecastWorkflow;

  const { data: hoursOfOperation = [], isFetching: isFetchingHoursOfOperation } = useHoursOfOperation();

  const {
    data: forecastStatsDataset = [],
    isFetching: isFetchingForecastData,
  } = useForecastStats(tipForecastWorkflow?.workflow_id, { enabled: shouldLoadForecastStats });
  const {
    data: forecastStatsSummary = [],
    isFetching: isFetchingForecastStatsSummary,
  } = useSummaryForecastStats(tipForecastWorkflow?.workflow_id, { enabled: shouldLoadForecastStats });
  const {
    data: historySummaryStatsRows = [],
    isFetching: isFetchingHistorySummaryStats,
  } = useAggregatedMetricHistorySummary(tipForecastWorkflow?.workflow_id, { enabled: shouldLoadForecastStats });
  const {
    data: forecastStatsHistorySummaryMeta = {},
    isFetching: isFetchingForecastStatsHistorySummaryMeta,
  } = useProcessedHistorySummaryMeta(tipForecastWorkflow?.workflow_id, { enabled: shouldLoadForecastStats });

  // this flag indicates if prices were optimized for revenue (false) or profit (true)
  const [isPriceOptimizationProfit, incomeMetricLabel] = React.useMemo(
    () => [
      priceStatsMeta?.workflow?.is_price_optimization_profit ?? false,
      priceStatsMeta?.workflow?.is_price_optimization_profit ?? false ? 'Profit' : 'Revenue',
    ],
    [priceStatsMeta],
  );

  const contextValue = {
    dimensions: workflowDimensions,
    dimensionValues: workflowDimensionValues,
    locations: activatedEOLocations,
    datasets,
    validDatasets,
    priceFrequencyDetails,
    workflowId,
    workflow,
    configTimeDimState,
    isPriceOptimizationProfit,
    incomeMetricLabel,
    priceStatsMeta,
    dailyForecastMeta,
    processedHistorySummaryMeta,
    format,
    workflows,
    paceHistoryRows,
    paceForecastRows,
    weeklyForecastRows,
    pricingRecommendationRows,
    savedDashboardState,
    forecastStatsDataset,
    forecastStatsSummary,
    forecastStatsHistorySummaryMeta,
    historySummaryStatsRows,
    progressComplete,
    batchInProgress,
    kaizenScenarioMeta,
    recentScenarioMeta,
    hoursOfOperation,
  };

  const isFetching = _.some([
    isFetchingDatasets,
    isFetchingDimensions,
    isFetchingDimensionValues,
    isFetchingLocations,
    isFetchingSubscription,
    isFetchingWorkflow,
    isFetchingPriceStatsMeta,
    isFetchingDailyForecastMeta,
    isFetchingProcessedHistorySummaryMeta,
    isFetchingPaceHistory,
    isFetchingPaceForeast,
    isFetchingWeeklyForecast,
    isFetchingPricingRecommendation,
    isFetchingCustomerConfig,
    isFetchingWorkflows,
    isFetchingSavedDashboardState,
    isFetchingWorkflowBatches,
    isFetchingKaizenScenarioMeta,
    isFetchingRecentScenarioMeta,
    shouldLoadForecastStats &&
      (isFetchingForecastData ||
        isFetchingForecastStatsSummary ||
        isFetchingHistorySummaryStats ||
        isFetchingForecastStatsHistorySummaryMeta),
    isFetchingHoursOfOperation,
  ]);

  if (showPickLocationToActivateModal) {
    return (
      <PickLocationToActivateModal
        locations={allRelevantLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        onSelectLocation={onSelectLocation}
        subscriptionType={SubscriptionType.TRIAL_PERIOD}
      />
    );
  }

  if (showAddressDetailsModal) {
    return (
      <AddressDetailsModal
        selectedLocation={selectedLocation}
        onHideAddressDetailsModal={onHideAddressDetailsModal}
        onUpdateLocation={onUpdateLocation}
        isSubmittingTrialLocationSelection={isSubmittingTrialLocationSelection}
      />
    );
  }

  let error = null;

  if (!isFetching) {
    /*
     * Prevent the user from viewing the dashboard if there are no EO locations.
     * This can happen if a user has not performed one of the following:
     *
     * (a) created locations for any of their data source settings
     * (b) finished setting up an integration
     * (c) manually uploaded a dataset that contains a location column
     */
    if (allRelevantLocations.length === 0 && error == null) {
      error = new NoLocationsError(workflowId);
    }

    // Prevent the user from viewing the dashboard if there are no valid datasets
    if (hasNoValidDatasets && error == null) {
      error = new NoValidDatasetsError();
    }

    // Prevent the user from viewing the dashboard when there are valid datasets but no dimension values
    const dimensionValuesExist = dimensionValues.length > 0;
    if (!dimensionValuesExist && validDatasets.length > 0 && error == null) {
      error = new NoDimensionValueError();
    }
  }

  if (isFetching) {
    return <Loading />;
  }

  if (showPickLocationToActivateModal) {
    return (
      <PickLocationToActivateModal
        locations={allRelevantLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        onSelectLocation={onSelectLocation}
        subscriptionType={SubscriptionType.TRIAL_PERIOD}
      />
    );
  }
  if (showAddressDetailsModal) {
    return (
      <AddressDetailsModal
        selectedLocation={selectedLocation}
        onHideAddressDetailsModal={onHideAddressDetailsModal}
        onUpdateLocation={onUpdateLocation}
        isSubmittingTrialLocationSelection={isSubmittingTrialLocationSelection}
      />
    );
  }

  if ((paceHistoryError || paceForecastError || weeklyForecastError || pricingRecommendationError) && error == null) {
    error = new NoDataAvailableError();
  }

  if (error != null) {
    throw progressComplete ? error : new DataInProgress(batchInProgress, workflowId);
  }

  return <DomainContext.Provider value={contextValue}>{children}</DomainContext.Provider>;
};

export const DashboardContextContainer = props => {
  const { children, workflowId } = props;

  return (
    <DashboardContextController workflowId={workflowId}>
      {({ setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled }) =>
        children({ setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled })
      }
    </DashboardContextController>
  );
};

const StripeTrialWarning = ({ subscriptionId }) => {
  const { data: billingInfo, isFetching } = useBillingInfo(subscriptionId, 'planInfo');

  if (isFetching) {
    return null;
  }

  const { trial_days_remaining: trialDaysRemaining } = billingInfo.plan_info;
  const daysText = trialDaysRemaining === 1 ? 'today!' : `in ${trialDaysRemaining} days.`; // pluralize if necessary

  if (trialDaysRemaining == null) {
    return null;
  }

  return (
    <div className="header-warning">
      <FontAwesomeIcon className="Icon" name="editClose" icon={faExclamationTriangle} />
      <span className="days-remaining mr-1">Your free trial ends {daysText}</span>
      <span className="auto-upgrade">
        Click <a href="/subscription-management">here</a> to manage your subscription.
      </span>
    </div>
  );
};

const ShopifyTrialWarning = ({ subscriptionId }) => {
  const { data: billingInfo, isFetching } = useShopifyBillingInfo(subscriptionId);

  if (isFetching) {
    return null;
  }

  const { trial_days_remaining: trialDaysRemaining } = billingInfo ?? {};
  const daysText = trialDaysRemaining === 1 ? 'today!' : `in ${trialDaysRemaining} days.`; // pluralize if necessary

  if (trialDaysRemaining == null) {
    return null;
  }

  return (
    <div className="header-warning">
      <FontAwesomeIcon className="Icon" name="editClose" icon={faExclamationTriangle} />
      <span className="days-remaining mr-1">Your free trial ends {daysText}</span>
      <span className="auto-upgrade">
        Click <a href="/subscription-management/shopify">here</a> to manage your subscription.
      </span>
    </div>
  );
};

const WarningMessage = () => {
  const {
    isFetching: isFetchingSubscriptionStatus,
    isStripeSubscription,
    isShopifySubscription,
    hasSubscription,
    kaizenProductStatus,
    subscriptionsByKaizenProduct,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  if (
    !isFetchingSubscriptionStatus &&
    hasSubscription &&
    isStripeSubscription &&
    kaizenProductStatus === KaizenProductStatus.TRIALING
  ) {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;

    return <StripeTrialWarning subscriptionId={kaizenPriceSubscriptionId} />;
  }

  if (
    !isFetchingSubscriptionStatus &&
    hasSubscription &&
    isShopifySubscription &&
    kaizenProductStatus === KaizenProductStatus.TRIALING
  ) {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;

    return <ShopifyTrialWarning subscriptionId={kaizenPriceSubscriptionId} />;
  }

  return null;
};

export const RevenueUpliftDashboardPage = props => {
  const { children } = props;
  const [workflowTitle, setWorkflowTitle] = React.useState('');

  const history = useHistory();
  let { workflowId } = useParams();
  workflowId = +workflowId;

  const { data: workflows = [] } = useWorkflows();
  const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);
  const displayTitle = kpWorkflows.length > 1;

  const DashboardUnloadableRetry = renderProps => {
    const { error } = renderProps;

    let primaryOnLeave;
    if (error instanceof NoDimensionValueError) {
      primaryOnLeave = () => history.push('/dimensionValuesSettings');
    }

    return <RetryOrGoToFallback {...renderProps} primaryOnLeave={primaryOnLeave} />;
  };

  return (
    <>
      <Header headerSubText={displayTitle ? workflowTitle : null} warning={<WarningMessage />} />

      <ErrorBoundary fallbackRender={DashboardUnloadableRetry}>
        {children({ workflowId, setWorkflowTitle })}
      </ErrorBoundary>
    </>
  );
};
