import classnames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusCircle, faEllipsisH, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import AdminRoleCheck from './AdminRoleCheck';
import LoadingIndicator from '../common/LoadingIndicator';
import SettingsPageWrapper from '../SettingsPageWrapper';
import SideBar from '../sidebar';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import { hasFailedPayment, shouldBeHidden } from '../../generic/subscriptions/invoiceUtil';
import { ChargeStatus, KaizenProduct, InvoiceStatus } from '../../generic/subscriptions/SubscriptionConstants';
import Header from '../../header/header';
import { BACKGROUND_GRAY } from '../../../colors';
import { useSubscriptionInvoices } from '../../../data-access/query/subscriptions';
import { gaEmitDownloadInvoiceActionClick } from '../../../google-analytics/subscriptionSettings';
import downloadIcon from '../../../images/download-icon.svg';
import './invoices.css';

const GRAY_TEXT = { color: '#797979' };

const currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const COLUMN_HEADERS = ['Date', 'Invoice Number', 'Amount', 'Status', 'Action'];

const DownloadButton = ({ pdfUrl }) => {
  const inactive = pdfUrl == null;

  const altText = inactive ? 'PDF is not yet available' : 'Download this invoice (pdf)';
  const icon = <img src={downloadIcon} alt={altText} style={{ opacity: inactive ? 0.5 : 1, minWidth: 25 }} />;

  return inactive ? (
    icon
  ) : (
    <a href={pdfUrl} onClick={gaEmitDownloadInvoiceActionClick}>
      {icon}
    </a>
  );
};

const LoadingRow = React.memo(() => (
  <tr>
    {COLUMN_HEADERS.map(d => (
      <td key={d} style={{ paddingBottom: 10 }}>
        <LoadingIndicator pull="center" />
      </td>
    ))}
  </tr>
));
LoadingRow.displayName = 'LoadingRow';

const InvoicesManagement = ({ allInvoices = [], isLoading }) => {
  const invoices = allInvoices.filter(invoice => !shouldBeHidden(invoice));

  const anyChargeFailed = invoices.find(hasFailedPayment) != null;

  const headers = COLUMN_HEADERS.map(columnName => (
    <th key={columnName} className={classnames(anyChargeFailed && columnName === 'Action' && 'payment-failed')}>
      {columnName}
    </th>
  ));

  const rows = invoices.map(invoice => {
    const {
      created,
      invoice_status: invoiceStatus,
      charge_status: chargeStatus,
      total: invoiceTotal,
      invoice_pdf: pdfUrl,
    } = invoice;

    const date = new Date(created * 1000).toLocaleDateString();
    const formattedTotal = currencyFormatter.format(invoiceTotal / 100);

    return (
      <tr key={invoice.number}>
        <td>{date}</td>
        <td>{invoice.number}</td>
        <td style={GRAY_TEXT}>{formattedTotal}</td>
        <td>
          <PaymentStatus invoiceStatus={invoiceStatus} chargeStatus={chargeStatus} />
        </td>
        <td>
          <ActionButtons
            anyChargeFailed={anyChargeFailed} // Applies special styling
            isFailedCharge={hasFailedPayment(invoice)}
            invoiceId={invoice.id}
            invoicePdf={pdfUrl}
          />
        </td>
      </tr>
    );
  });

  return (
    <HeaderAwarePage>
      <Header />
      <SideBar />
      <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY }}>
        <div className="d-flex flex-column" style={{ maxWidth: '100%' }}>
          <LinkWithDirectionalArrow
            target="/subscription-management"
            text="Back to Subscription"
            direction="left"
            arrowRelativeToText="left"
            linkClassName="back-to-subscription-link"
          />
          <div className="subscription-invoices-header">Invoices</div>
          <div>
            <table className="invoices-table">
              <thead>
                <tr>{headers}</tr>
              </thead>
              <tbody>{isLoading ? <LoadingRow /> : rows}</tbody>
            </table>
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export const ActionButtons = ({ isFailedCharge, anyChargeFailed, invoiceId, invoicePdf }) => (
  <div className={classnames(anyChargeFailed && 'invoice-action-button')}>
    {isFailedCharge && invoiceId && <PayInvoiceButton invoiceId={invoiceId} />}
    <div>
      <DownloadButton key={invoicePdf} pdfUrl={invoicePdf} />
    </div>
  </div>
);

export const PaymentStatus = ({ invoiceStatus, chargeStatus }) => {
  const props = {};
  if (invoiceStatus === InvoiceStatus.PAID) {
    props.status = 'Paid';
    props.iconClassName = 'invoice-success-circle-icon';
    props.textClassName = 'invoice-success-text';
    props.icon = faCheckCircle;
    props.border = false;
  } else if (invoiceStatus === InvoiceStatus.OPEN) {
    if (chargeStatus === ChargeStatus.PENDING) {
      props.status = 'Open';
      props.iconClassName = 'invoice-pending-icon';
      props.textClassName = 'invoice-pending-text';
      props.icon = faEllipsisH;
      props.border = true;
    } else if (chargeStatus === ChargeStatus.FAILED) {
      props.status = 'Payment Failed';
      props.iconClassName = 'invoice-failed-icon';
      props.textClassName = 'invoice-failed-text';
      props.icon = faMinusCircle;
      props.border = false;
    }
  }

  return <PaymentStatusContent {...props} />;
};

export const PaymentStatusContent = ({ status, iconClassName, textClassName, icon, border }) => {
  return (
    <div style={{ paddingLeft: '85px' }}>
      <div className="d-flex align-items-center">
        <div>
          <FontAwesomeIcon className={iconClassName} size="lg" icon={icon} border={border} />
        </div>
        <span className={textClassName}>{status}</span>
      </div>
    </div>
  );
};

const PayInvoiceButton = ({ invoiceId }) => {
  const history = useHistory();

  const onClick = () => history.push(`/pay-invoice/${invoiceId}`);

  return (
    <div className="pay-invoice-top-level-div">
      <ProfitRoverPrimaryButton className="pay-invoice-button" onClick={onClick}>
        Pay Invoice <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '0.5rem' }} />
      </ProfitRoverPrimaryButton>
    </div>
  );
};

const KaizenPriceInvoicesHandler = () => {
  const { data: invoices = [], isLoading } = useSubscriptionInvoices(KaizenProduct.KP);

  return <InvoicesManagement allInvoices={invoices} isLoading={isLoading} />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <KaizenPriceInvoicesHandler {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
