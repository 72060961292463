import React from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import classnames from 'classnames';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import { addMonths, addQuarters, addYears, format, startOfMonth, startOfQuarter, startOfYear, addDays } from 'date-fns';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ApprovedPriceEditor from './ApprovedPriceEditor';
import ApprovedPriceRenderer from './ApprovedPriceRenderer';
import { DEMAND_METRIC, INCOME_METRIC, METRIC_TO_LABEL } from './atAGlanceTabConstants';
import { PRICE_FREQ_COL_TO_HEADER } from './priceChangeFrequencyUtil';
import { useExportData, usePriceRecDims, usePriceRecTableData, useTopPricingRecTableData } from './pricingTabUtil';
import { LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import { formatAsDatasetDate, parseDatasetDate } from './timePeriod';
import { SmallPriceTagIcon } from '../../d3/PriceTagIcon';
import FORMAT_TYPE from '../../formatType';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { compactFormatNumber, formatCurrency } from '../../../util/format';
import { useGridApi } from '../../../util/gridCommonFunctions';
import { PriceChangeFrequency } from '../../../workflow/workflowConstants';
import { FONT_BLACK } from '../../../../colors';
import PriceTagIcon from '../../../../images/price-tag-icon.svg';
import DownloadIcon from '../../../../images/download-icon.svg';
import { calendarDOW, calendarMonths } from '../../../../utils/date-handling';
import {
  gaEmitChangeTimePeriodClick,
  gaEmitDetailButtonClick,
  gaEmitDownloadRoverRecsButtonClick,
  gaEmitSeePricingForLocationDropdownClick,
  gaEmitSummaryButtonClick,
} from '../../../../google-analytics/pricingTab';
import './revenue-price-rec-table.scss';

const ProfitRoverIcon = '/images/ProfitRoverLogo.png';
const EMPTY_VALUE_SYMBOL = '- - -';

const convertToCSV = (keys, data, rowParser, headerLabels) => {
  return [
    keys.map(key => headerLabels[key]).join(','),
    ...data.map(row => keys.map(key => rowParser(key, row[key])).join(',')),
  ].join('\n');
};

const downloadDataAsCSV = (csv, fileName) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

const buildPriceHeaderTemplate = priceIcon => {
  return `
    <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
        <img class="price-rec-table-header-icon" src="${priceIcon}" />
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
      </div>
    </div>`;
};

const priceTagRender = props => {
  const priceChangePct = props.value;

  if (priceChangePct == null || Number.isNaN(priceChangePct)) {
    return '-';
  }

  return (
    <SmallPriceTagIcon
      className="price-rec-chng-tag"
      value={priceChangePct}
      format={FORMAT_TYPE.PERCENTAGE}
      symbolForZero={EMPTY_VALUE_SYMBOL}
    />
  );
};

const upliftTotalRenderer = props => {
  if (props.value == null || !Number.isFinite(props.value)) {
    return '-';
  }

  const isPositive = props.value >= 0;
  const isZero = props.value === 0;
  const { currencySymbol } = props;
  const displayValue = currencySymbol
    ? formatCurrency(props.value, false, currencySymbol)
    : compactFormatNumber(props.value, { formatAsCurrency: false, fixedDecimalDigits: 1 });

  return (
    <span className="price-rec-gain-total">
      <div
        className={!isZero ? classnames('graph-total-triangle', isPositive ? 'triangle-up ' : 'triangle-down') : null}
      />
      {!isZero ? displayValue : EMPTY_VALUE_SYMBOL}
    </span>
  );
};

const currencyValueFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return formatCurrency(params.value, true, currencySymbol);
    }

    return '-';
  };
};

const grossSalesFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: true, currencySymbol, showCents: false });
    }

    return '-';
  };
};

const demandFormatter = () => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: false, fixedDecimalDigits: 1 });
    }

    return '-';
  };
};

const borderClass = 'border-right';

const defaultColDef = {
  sortable: true,
  resizable: true,
  headerClass: borderClass,
  cellClass: borderClass,
  minWidth: 100,
};

const comparator = (valueOne, valueTwo) => {
  if (valueOne === valueTwo) {
    return 0;
  }

  return valueOne > valueTwo ? 1 : -1;
};

const createSortCalendarFrequency = sortOrder => {
  return (rowValueOne, rowValueTwo) => {
    const valueOneIndex = sortOrder.indexOf(rowValueOne?.toLowerCase());
    const valueTwoIndex = sortOrder.indexOf(rowValueTwo?.toLowerCase());

    return comparator(valueOneIndex, valueTwoIndex);
  };
};

const sortByMonth = createSortCalendarFrequency(calendarMonths);
const sortByDOW = createSortCalendarFrequency(calendarDOW);

const frameworkComponents = {
  ApprovedPriceRenderer,
  ApprovedPriceEditor,
  priceTagRender,
  upliftTotalRenderer,
};

// Unused but kept for when we add the approved price column back:
// const approvedPriceValueGetter = params =>
//   params.context.approvedPricesApi.selectors.getApprovedPricePoint(params.data).price;

// Allows us to use "immutableData" for optimal row update behavior
const getRowNodeId = data => data.rowId;

const useFilteredLocations = () => {
  const { locations } = React.useContext(DomainContext);
  const { selectionState } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const locationsFilterState = selectionState[LOCATIONS_DIMENSION_ID];

    const isUnselected = ({ isSelected }) => !isSelected;
    if (_.every(locationsFilterState, isUnselected)) {
      // If all locations are filtered out, show all of them in the dropdown
      return locations;
    }

    // Otherwise, only show locations in the dropdown that are not filtered out
    const selectedLocations = locations.filter(location => {
      const filterStateForLoc = locationsFilterState.find(
        ({ dimensionValueId }) => location.location_id === dimensionValueId,
      );

      return filterStateForLoc != null && filterStateForLoc.isSelected;
    });

    return selectedLocations;
  }, [locations, selectionState]);
};

const useSortedLocations = () => {
  const filteredLocations = useFilteredLocations();

  return React.useMemo(() => _.sortBy(filteredLocations, 'location_description'), [filteredLocations]);
};

const parseDay = dateStr => {
  const date = parseDatasetDate(dateStr);
  return format(date, 'MMM d, yyyy');
};

const usePeriodOptions = () => {
  const { priceFrequencyDetails } = React.useContext(DomainContext);
  const { minAndMaxBaselineDates } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const [minBaselineDate, maxBaselineDate] = minAndMaxBaselineDates;
    const startDate = parseDatasetDate(minBaselineDate);
    const endDate = parseDatasetDate(maxBaselineDate);

    if (priceFrequencyDetails.includes(PriceChangeFrequency.DAY)) {
      const days = [];

      for (let date = startDate; date <= endDate; date = addDays(date, 1)) {
        days.push({
          datasetValue: formatAsDatasetDate(date),
          label: parseDay(formatAsDatasetDate(date)),
        });
      }

      return { periodOptions: days, frequency: PriceChangeFrequency.DAY };
    }

    if (priceFrequencyDetails.includes(PriceChangeFrequency.MONTH)) {
      let currentStartOfMonth = startOfMonth(startDate);
      const months = [];

      do {
        months.push({
          datasetValue: format(currentStartOfMonth, 'MMMM yyyy'),
          label: format(currentStartOfMonth, 'MMM yyyy'),
        });
        currentStartOfMonth = addMonths(currentStartOfMonth, 1);
      } while (currentStartOfMonth <= endDate);

      return { periodOptions: months, frequency: PriceChangeFrequency.MONTH };
    }

    if (priceFrequencyDetails.includes(PriceChangeFrequency.QUARTER)) {
      let currentStartOfQuarter = startOfQuarter(startDate);
      const quarters = [];

      do {
        quarters.push({
          datasetValue: format(currentStartOfQuarter, 'Q yyyy'),
          label: format(currentStartOfQuarter, 'QQQ yyyy'),
        });
        currentStartOfQuarter = addQuarters(currentStartOfQuarter, 1);
      } while (currentStartOfQuarter <= endDate);

      return { periodOptions: quarters, frequency: PriceChangeFrequency.QUARTER };
    }

    if (priceFrequencyDetails.includes(PriceChangeFrequency.YEAR)) {
      let currentStartOfYear = startOfYear(startDate);
      const years = [];

      do {
        const year = format(currentStartOfYear, 'yyyy');
        years.push({
          datasetValue: year,
          label: year,
        });
        currentStartOfYear = addYears(currentStartOfYear, 1);
      } while (currentStartOfYear <= endDate);

      return { periodOptions: years, frequency: PriceChangeFrequency.YEAR };
    }

    return { periodOptions: [], frequency: null };
  }, [priceFrequencyDetails, minAndMaxBaselineDates]);
};

const TableButton = ({ label, isSelected, ...props }) => (
  <button
    type="button"
    className={classnames('table-button', {
      'table-button-selected': isSelected,
    })}
    {...props}
  >
    <p>{label}</p>
  </button>
);

const TableLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-self-center">
      <CenteredProfitRoverSpinner />
    </div>
  );
};

const PriceRecommendationTable = ({ isActive }) => {
  const { workflowId } = React.useContext(DomainContext);
  const { currencySymbol, baselineComparison, minAndMaxBaselineDates, approvedPricesApi } = React.useContext(
    DashboardContext,
  );
  const { baseline, newPrices } = baselineComparison;

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();
  const dimsById = usePriceRecDims();
  const sortedLocations = useSortedLocations();
  const { periodOptions, frequency } = usePeriodOptions();

  // Unused but kept for when we add the approved price column back:
  // const { isLoading: isLoadingWorkflowBatches, primaryForecastComplete } = useWorkflowBatchesWithStatus(workflowId);
  // const newHeaderText = useScenarioHeaderTextForPriceTable("New Price");

  const [selectedLocation, setSelectedLocation] = React.useState(sortedLocations[0]);
  const [selectedPeriod, setSelectedPeriod] = React.useState(periodOptions[0]);
  const [showDetail, setShowDetail] = React.useState(false);
  const [downloadDisabled, setDownloadDisabled] = React.useState(false);

  const { productDimCols, priceFrequencyCols, priceRecData } = usePriceRecTableData(
    approvedPricesApi,
    selectedLocation,
    frequency,
    selectedPeriod?.datasetValue,
  );
  const { keys, rowParser, headerLabels, exportData } = useExportData(approvedPricesApi);

  React.useEffect(() => {
    if (!sortedLocations.includes(selectedLocation)) {
      setSelectedLocation(sortedLocations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedLocations]);

  React.useEffect(() => {
    if (!periodOptions.includes(selectedPeriod)) {
      setSelectedPeriod(periodOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOptions]);

  const onClickSummary = () => {
    gaEmitSummaryButtonClick();
    setShowDetail(false);
  };

  const onClickDetail = () => {
    gaEmitDetailButtonClick();
    setShowDetail(true);
  };

  const [minBaselineDate, maxBaselineDate] = minAndMaxBaselineDates;
  const minDate = parseDatasetDate(minBaselineDate);
  const maxDate = parseDatasetDate(maxBaselineDate);
  const selectedDate = parseDatasetDate(selectedPeriod?.datasetValue);

  const setDate = date => {
    const formattedDate = formatAsDatasetDate(date);
    const periodOption = periodOptions.find(option => option.value.start === formattedDate);
    setSelectedPeriod(periodOption);
  };

  const selectNextPeriod = () => {
    const currentPeriodIndex = periodOptions.findIndex(option => option === selectedPeriod);
    const nextPeriod = periodOptions[currentPeriodIndex + 1];

    if (nextPeriod == null) {
      return;
    }

    gaEmitChangeTimePeriodClick();
    setSelectedPeriod(nextPeriod);
  };

  const selectPreviousPeriod = () => {
    const currentPeriodIndex = periodOptions.findIndex(option => option === selectedPeriod);
    const previousPeriod = periodOptions[currentPeriodIndex - 1];

    if (previousPeriod == null) {
      return;
    }

    gaEmitChangeTimePeriodClick();
    setSelectedPeriod(previousPeriod);
  };

  const onDownloadClick = () => {
    gaEmitDownloadRoverRecsButtonClick();
    setDownloadDisabled(true);
    const csv = convertToCSV(keys, exportData, rowParser, headerLabels);
    downloadDataAsCSV(csv, 'profitrover-prices.csv');

    // Add a delay to prevent multiple downloads
    setTimeout(() => {
      setDownloadDisabled(false);
    }, 1500);
  };

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    // The timeout with a delay of 0 will push the execution of gridApi.sizeColumnsToFit()
    // to the end of the JavaScript event loop, ensuring that it is executed after the render,
    // when the columns that need to be displayed/hidden are already being rendered accordingly.
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, priceRecData, showDetail, isActive]);

  const isLoading = gridApi == null;

  // Unused but kept for when we add the approved price column back:
  // if (isLoadingWorkflowBatches) {
  //   return (
  //     <div className="pricing-table-container">
  //       <CenteredProfitRoverSpinner />
  //     </div>
  //   );
  // }

  const onLocationChange = location => {
    gaEmitSeePricingForLocationDropdownClick();
    setSelectedLocation(location);
  };

  const onChangeDate = date => {
    gaEmitChangeTimePeriodClick();
    setDate(date);
  };

  const onChangePeriod = period => {
    gaEmitChangeTimePeriodClick();
    setSelectedPeriod(period);
  };

  return (
    <div className="pricing-table-container mb-1">
      <div className="selectors-container mb-2 w-100">
        <div className="d-flex align-items-center">
          <div className="location-label">See Pricing For:</div>
          <div style={{ minWidth: 160 }} className="ml-2">
            <RevenueUpliftSelect
              options={sortedLocations}
              getOptionLabel={location => location.location_description}
              getOptionValue={_.identity}
              onChange={onLocationChange}
              value={selectedLocation}
              isDisabled={isLoading}
            />
          </div>
        </div>
        {periodOptions.length > 0 && (
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="arrow-button mr-1"
              disabled={selectedPeriod === periodOptions[0]}
              onClick={selectPreviousPeriod}
            >
              <FontAwesomeIcon icon={faAngleLeft} color={FONT_BLACK} />
            </button>
            {frequency === PriceChangeFrequency.DAY ? (
              <div className="date-range-picker-anchor">
                <DatePicker selected={selectedDate} onChange={onChangeDate} minDate={minDate} maxDate={maxDate} />
              </div>
            ) : (
              <div style={{ minWidth: 140 }}>
                <RevenueUpliftSelect
                  options={periodOptions}
                  getOptionValue={_.identity}
                  onChange={onChangePeriod}
                  value={selectedPeriod}
                  isDisabled={isLoading}
                />
              </div>
            )}
            <button
              type="button"
              className="arrow-button ml-1"
              disabled={selectedPeriod === periodOptions[periodOptions.length - 1]}
              onClick={selectNextPeriod}
            >
              <FontAwesomeIcon icon={faAngleRight} color={FONT_BLACK} />
            </button>
          </div>
        )}
        <ProfitRoverSecondaryButton onClick={onDownloadClick} disabled={downloadDisabled}>
          <img className="download-icon" src={DownloadIcon} alt="" />
          Download RoverRecs
        </ProfitRoverSecondaryButton>
        <div style={{ flex: 1 }} />
        <div className="detail-button-container">
          <TableButton label="Summary" isSelected={!showDetail} onClick={onClickSummary} disabled={isLoading} />
          <TableButton label="Detail" isSelected={showDetail} onClick={onClickDetail} disabled={isLoading} />
        </div>
      </div>
      <div className="eo-price-rec-table-container" ref={containerRef}>
        {isLoading && <TableLoader />}
        <div className={classnames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
          <AgGridReact
            rowData={priceRecData}
            context={{ workflowId, approvedPricesApi }}
            immutableData
            getRowNodeId={getRowNodeId}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            groupHeaderHeight={30}
            headerHeight={30}
            maintainColumnOrder
            singleClickEdit
            // this preserves rows in their original state/order once sorting is disabled
            suppressModelUpdateAfterUpdateTransaction
            /**
             * This prop prevents the Grid from breaking down completely when changing the rowData
             * while a cell is still being edited.
             */
            stopEditingWhenCellsLoseFocus
            rowBuffer={70}
          >
            {priceFrequencyCols.map(column => {
              const headerName = PRICE_FREQ_COL_TO_HEADER[column] ?? column;

              return (
                <AgGridColumn
                  key={column}
                  field={column}
                  headerName={headerName}
                  headerClass={`${borderClass} center-text price-rec-table-header`}
                  comparator={column === 'dayOfWeek' ? sortByDOW : column === 'month' ? sortByMonth : undefined}
                />
              );
            })}
            {productDimCols.map(dimId => (
              <AgGridColumn
                key={dimId}
                field={dimId}
                headerName={dimsById[dimId].name}
                headerClass={`${borderClass} center-text`}
                minWidth={140}
              />
            ))}
            <AgGridColumn
              field="baselinePrice"
              headerName={baseline.label}
              headerClass={`${borderClass} center-text`}
              cellClass={`${borderClass} center-text`}
              valueFormatter={currencyValueFormatter(currencySymbol)}
              minWidth={120}
            />
            <AgGridColumn
              field="newPrice"
              headerName={newPrices.label}
              headerClass={`${borderClass} center-text`}
              cellClass={`${borderClass} center-text`}
              valueFormatter={currencyValueFormatter(currencySymbol)}
              minWidth={120}
            />
            {/* <AgGridColumn
              colId="approvedPrice"
              editable={primaryForecastComplete}
              headerName={newHeaderText}
              headerClass={`${borderClass} center-text`}
              cellClass={`${borderClass} center-text price-rec-approved-price`}
              cellRenderer="ApprovedPriceRenderer"
              cellRendererParams={{ primaryForecastComplete, currencySymbol }}
              cellEditor="ApprovedPriceEditor"
              cellEditorParams={{ currencySymbol }}
              minWidth={160}
              valueGetter={approvedPriceValueGetter}
            /> */}
            {showDetail && (
              <AgGridColumn
                field="priceDifferencePct"
                headerName="Price Change"
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text price-rec-chg-pct`}
                cellRenderer="priceTagRender"
                enableCellChangeFlash
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="uplift"
                headerName={`${METRIC_TO_LABEL[INCOME_METRIC]} Change`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                cellRenderer="upliftTotalRenderer"
                cellRendererParams={{ currencySymbol }}
                enableCellChangeFlash
                minWidth={130}
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="demandChange"
                headerName={`${METRIC_TO_LABEL[DEMAND_METRIC]} Change`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                cellRenderer="upliftTotalRenderer"
                enableCellChangeFlash
                minWidth={130}
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="newIncome"
                headerName={`${newPrices.label} ${METRIC_TO_LABEL[INCOME_METRIC]}`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                valueFormatter={grossSalesFormatter(currencySymbol)}
                enableCellChangeFlash
                minWidth={140}
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="currentIncome"
                headerName={`${baseline.label} ${METRIC_TO_LABEL[INCOME_METRIC]}`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                valueFormatter={grossSalesFormatter(currencySymbol)}
                enableCellChangeFlash
                minWidth={150}
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="newDemand"
                headerName={`${newPrices.label} ${METRIC_TO_LABEL[DEMAND_METRIC]}`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                valueFormatter={demandFormatter()}
                enableCellChangeFlash
                minWidth={130}
              />
            )}
            {showDetail && (
              <AgGridColumn
                field="currentDemand"
                headerName={`${baseline.label} ${METRIC_TO_LABEL[DEMAND_METRIC]}`}
                headerClass={`${borderClass} center-text`}
                cellClass={`${borderClass} center-text`}
                valueFormatter={demandFormatter()}
                enableCellChangeFlash
                minWidth={140}
              />
            )}
          </AgGridReact>
        </div>
      </div>
    </div>
  );
};

const NUMBER_OF_ROWS_TO_SHOW = 3;

export const TopPricingRecsTable = ({ isActive }) => {
  const { workflowId } = React.useContext(DomainContext);
  const { currencySymbol } = React.useContext(DashboardContext);

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();
  const dimsById = usePriceRecDims();
  const locations = useFilteredLocations();

  const { productDimCols, priceFrequencyCols, priceRecData } = useTopPricingRecTableData(NUMBER_OF_ROWS_TO_SHOW);

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    // The timeout with a delay of 0 will push the execution of gridApi.sizeColumnsToFit()
    // to the end of the JavaScript event loop, ensuring that it is executed after the render,
    // when the columns that need to be displayed/hidden are already being rendered accordingly.
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, priceRecData, locations, isActive]);

  const isLoading = gridApi == null;

  return (
    <div className="eo-price-rec-table-container" ref={containerRef}>
      {isLoading && <TableLoader />}
      <div className={classnames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
        <AgGridReact
          rowData={priceRecData}
          context={{ workflowId }}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          groupHeaderHeight={30}
          headerHeight={30}
          maintainColumnOrder
          singleClickEdit
          // this preserves rows in their original state/order once sorting is disabled
          suppressModelUpdateAfterUpdateTransaction
          /**
           * This prop prevents the Grid from breaking down completely when changing the rowData
           * while a cell is still being edited.
           */
          stopEditingWhenCellsLoseFocus
          rowBuffer={70}
        >
          {priceFrequencyCols.map(column => {
            const headerName = PRICE_FREQ_COL_TO_HEADER[column] ?? column;

            return (
              <AgGridColumn
                key={column}
                field={column}
                headerName={headerName}
                headerClass={`${borderClass} center-text price-rec-table-header`}
                comparator={column === 'dayOfWeek' ? sortByDOW : column === 'month' ? sortByMonth : undefined}
              />
            );
          })}
          {productDimCols.map(dimId => (
            <AgGridColumn
              key={dimId}
              field={dimId}
              headerName={dimsById[dimId].name}
              headerClass={`${borderClass} center-text`}
              minWidth={140}
            />
          ))}
          {locations.length > 1 && (
            <AgGridColumn
              field="locationDescription"
              headerName="Location"
              headerClass={`${borderClass} center-text`}
              minWidth={90}
            />
          )}
          <AgGridColumn
            field="currentPrice"
            headerName="Recent Price"
            headerComponentParams={{
              template: buildPriceHeaderTemplate(PriceTagIcon),
            }}
            headerClass={`${borderClass} center-text`}
            cellClass={`${borderClass} center-text`}
            valueFormatter={currencyValueFormatter(currencySymbol)}
            minWidth={120}
          />
          <AgGridColumn
            field="kaizenPrice"
            headerName="RoverRecs"
            headerComponentParams={{
              template: buildPriceHeaderTemplate(ProfitRoverIcon),
            }}
            headerClass={`${borderClass} center-text`}
            cellClass={`${borderClass} center-text`}
            valueFormatter={currencyValueFormatter(currencySymbol)}
            minWidth={120}
          />
          <AgGridColumn
            field="uplift"
            headerName="90-Day Revenue Uplift"
            headerClass={`${borderClass} center-text`}
            cellClass={`${borderClass} center-text`}
            cellRenderer="upliftTotalRenderer"
            cellRendererParams={{ currencySymbol }}
            enableCellChangeFlash
            minWidth={130}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default PriceRecommendationTable;
