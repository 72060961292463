import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faExclamationTriangle, faCog } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Row } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import AnimatedTileOutline from './AnimatedTileOutline';
import GuidedSetupCompleteModal from './GuidedSetupCompleteModal';
import { useUserData, useWelcomePageData } from './welcomePageHooks';
import ShopifyStartPlanBanner from '../accountSettings/subscriptionManagement/shopify/ShopifyStartPlanBanner';
import GenericLoadingPage from '../generic/GenericLoadingPage';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import StartPlanBanner from '../generic/subscriptions/StartPlanBanner';
import {
  KaizenProduct,
  KaizenProductStatus,
  ObjectiveToKaizenProduct,
} from '../generic/subscriptions/SubscriptionConstants';
import UnpaidInvoiceBanner from '../generic/subscriptions/UnpaidInvoiceBanner';
import Header from '../header/header';
import { useSubscriptionStatusForProduct } from '../routing/subscriptionStatusHooks';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import { Objective } from '../workflow/workflowConstants';
import { getWorkflowTitle, isEoPriceoptWorkflow } from '../workflow/workflowFunctions';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import { endTrialEarly } from '../../data-access/mutation/subscriptions';
import { useBillingInfo, useShopifyBillingInfo } from '../../data-access/query/subscriptions';
import { gaEmitEditWorkflowGearClick } from '../../google-analytics/welcomePage';
import { useNavigateToDashboard } from '../../hooks/navigationHooks';
import './welcomePage.css';
import '../workflow/workflow-settings.css';

/**
 * Some workflows do not directly map to a distinct dashboard to be viewed. Thus, we should not
 * render a <WorkflowTile> for them.
 */
const isWorkflowTileable = workflow => workflow.objective !== Objective.TIPFORECAST;

const TrialBanner = ({ subscriptionId }) => {
  const { data: billingInfo, isFetching } = useBillingInfo(subscriptionId, 'planInfo');

  if (isFetching) {
    return null;
  }

  const { trial_days_remaining: trialDaysRemaining } = billingInfo.plan_info;
  const daysText = trialDaysRemaining === 1 ? 'day' : 'days'; // pluralize if necessary

  return (
    <div className="trial-banner">
      <div className="container pt-4">
        <span className="days-remaining">
          You have{' '}
          <span className="days-remaining-value">
            {trialDaysRemaining} {daysText}
          </span>{' '}
          remaining on your free trial.
        </span>
        <StartPlanBanner />
      </div>
    </div>
  );
};

const ShopifyTrialBanner = ({ subscriptionId }) => {
  const { data: billingInfo, isFetching } = useShopifyBillingInfo(subscriptionId);
  const [isLoading, setIsLoading] = React.useState(false);

  if (isFetching) {
    return null;
  }

  const { trial_days_remaining: trialDaysRemaining } = billingInfo ?? {};
  const daysText = trialDaysRemaining === 1 ? 'day' : 'days'; // pluralize if necessary

  const onClickContinue = async () => {
    setIsLoading(true);

    try {
      const response = await endTrialEarly(subscriptionId);
      const { confirmation_url: confirmationUrl } = response?.data ?? {};

      if (confirmationUrl) {
        // Navigate to Shopify in the same tab
        window.open(confirmationUrl, '_self');
      } else {
        window.location.reload();
      }
    } catch (_) {
      setIsLoading(false);
    }
  };

  return (
    <div className="trial-banner">
      <div className="container pt-4">
        <span className="days-remaining">
          You have{' '}
          <span className="days-remaining-value">
            {trialDaysRemaining} {daysText}
          </span>{' '}
          remaining on your free trial.
        </span>
        <ShopifyStartPlanBanner disabled={isLoading} onClick={onClickContinue} />
      </div>
    </div>
  );
};

const WorkflowConfigurationIcon = ({ objective, workflow, cursorStyle, disabled }) => {
  const history = useHistory();

  const openUpdateWorkflow = e => {
    gaEmitEditWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflow', { workflow });
  };

  const openUpdateWorkflowDimensions = e => {
    gaEmitEditWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflowDimension', { workflow });
  };

  if (objective === Objective.PRICEELASTICITY) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon
          className="Icon"
          style={{ ...cursorStyle, position: 'absolute', right: 5, top: -2 }}
          name="editClose"
          icon={faEllipsisH}
          onClick={!disabled ? openUpdateWorkflow : undefined}
        />
      </Can>
    );
  }
  if (objective === Objective.PRICEOPT) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon
          style={cursorStyle}
          className="Icon edit-dim-icon"
          name="editDimension"
          icon={faCog}
          onClick={!disabled ? openUpdateWorkflowDimensions : undefined}
        />
      </Can>
    );
  }

  return null;
};

const NormalWelcomePage = props => {
  const { history, location } = props;
  const { showCompletedGuidedSetupModal = false } = location?.state ?? {};

  const {
    loading,
    workflows,
    datasets,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    hasSubscription,
    kaizenProductStatus,
    isShopifySubscription,
  } = useWelcomePageData();

  const [showSetupCompletionModal, setShowCompletionModal] = React.useState(showCompletedGuidedSetupModal);

  let trialBanner;
  if (hasSubscription && isStripeSubscription && kaizenProductStatus === KaizenProductStatus.TRIALING) {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;

    trialBanner = <TrialBanner subscriptionId={kaizenPriceSubscriptionId} />;
  } else if (hasSubscription && isShopifySubscription && kaizenProductStatus === KaizenProductStatus.TRIALING) {
    const kaizenPriceSubscriptionId = subscriptionsByKaizenProduct[KaizenProduct.KP].subscription_id;

    trialBanner = <ShopifyTrialBanner subscriptionId={kaizenPriceSubscriptionId} />;
  }

  let { userName } = useUserData();
  userName = (userName ?? '').trim(); // Don't show whitespace if it happens to be present in the data
  const welcomeText = userName != null && userName !== '' ? `Welcome, ${userName}.` : 'Welcome.';

  const workflowsAvailable = (workflows?.length ?? 0) > 0;
  const datasetsAvailable = (datasets?.length ?? 0) > 0;

  let missingDataMessage;
  if (!datasetsAvailable) {
    missingDataMessage = (
      <div>
        <br />
        <p>
          You have no data to populate a dashboard. Please upload a dataset in the&nbsp;
          <Link to="/data-sources">Data Center</Link>.
        </p>
      </div>
    );
  } else if (!workflowsAvailable) {
    missingDataMessage = (
      <div>
        <br />
        <p>You have no viewable analyses.</p>
      </div>
    );
  }

  const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);
  const workflowRedirectPath =
    hasSubscription && kpWorkflows.length === 1 ? `/profitrover/${kpWorkflows[0].workflow_id}` : null;

  if (workflowRedirectPath) {
    return (
      <Redirect
        to={{
          pathname: workflowRedirectPath,
          state: { showCompletedGuidedSetupModal },
        }}
      />
    );
  }

  if (loading) {
    return <GenericLoadingPage />;
  }

  return (
    <>
      <Header />
      <HeaderAwarePage className="welcome-page">
        {showSetupCompletionModal && (
          <GuidedSetupCompleteModal
            onClose={() => {
              setShowCompletionModal(false);
              // Prevents confetti from replaying
              history.replace({ pathname: location.pathname, state: { showCompletedGuidedSetupModal: false } });
            }}
          />
        )}

        {/* Only one of these two banners should ever be visible at one time */}
        {trialBanner}
        <UnpaidInvoiceBanner spacingClassName="container" />

        <div className="container pt-4">
          <p className="display-4" style={{ fontSize: 42, paddingTop: trialBanner ? 0 : 10 }}>
            {welcomeText}
          </p>

          {loading ? (
            <CenteredProfitRoverSpinner className="welcome-page-spinner" />
          ) : (
            <div>
              {workflowsAvailable && datasetsAvailable && (
                <>
                  <p className="lead" style={{ fontSize: '18px', margin: '15px 0px' }}>
                    Select a dashboard to get started.
                  </p>
                  <WorkflowsSection workflows={workflows} />
                </>
              )}
              {missingDataMessage}
            </div>
          )}
        </div>
      </HeaderAwarePage>
    </>
  );
};

const WelcomePage = props => {
  return (
    <Can I={Action.IMPERSONATE} a={Subject.USER} passThrough>
      {allowed => (allowed ? <Redirect to="/impersonate-user" /> : <NormalWelcomePage {...props} />)}
    </Can>
  );
};

export default WelcomePage;

const WorkflowsSection = props => {
  const { workflows } = props;

  return (
    <Row>
      {workflows.filter(isWorkflowTileable).map(workflow => (
        <WorkflowTile key={workflow.workflow_id} workflow={workflow} />
      ))}
    </Row>
  );
};

// If workflow tiles become a thing again then this is what was used for the background image css
// const imageDarknessFactor = 0.4; // Higher is darker
// const linGradCSS = `linear-gradient(rgba(0, 0, 0, ${imageDarknessFactor}), rgba(0, 0, 0, ${imageDarknessFactor}))`;

const fallbackLinGrad = `linear-gradient(0.125turn, #4F4F4F, #CCC)`;

/**
 * Workflow objectives for which no required subscription information is required in order to view the dashboard
 */
const UNMONITORED_OBJECTIVES = [Objective.ANOMALY];

const WorkflowTile = props => {
  const { workflow } = props;

  const { objective } = workflow;

  const productForObjective = ObjectiveToKaizenProduct[objective];
  const { canViewDashboard } = useSubscriptionStatusForProduct(productForObjective);

  const isUnmonitoredProduct = UNMONITORED_OBJECTIVES.includes(objective);

  const disabled = !isUnmonitoredProduct && !canViewDashboard;

  const workflowTitle = getWorkflowTitle(workflow);

  const titleTextFontSize = String(workflowTitle).length < 20 ? 24 : 22;

  const backgroundImageCSS = fallbackLinGrad;

  const openDashboard = useNavigateToDashboard(workflow);

  const tooltipText = disabled ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
      <div style={{ textAlign: 'left' }}>
        Your subscription is not active. You cannot access this dashboard until you update your subscription
        information.
      </div>
    </div>
  ) : (
    undefined
  );

  const cursorStyle = { cursor: disabled ? 'not-allowed' : 'pointer' };

  return (
    <AnimatedTileOutline
      style={{
        position: 'relative',
        backgroundImage: backgroundImageCSS,
        opacity: disabled ? 0.3 : 1,
        fontSize: titleTextFontSize,
        ...cursorStyle,
      }}
      onClick={!disabled ? openDashboard : undefined}
      tooltipText={tooltipText}
    >
      <WorkflowConfigurationIcon
        objective={objective}
        workflow={workflow}
        cursorStyle={cursorStyle}
        disabled={disabled}
      />
      {workflowTitle}
    </AnimatedTileOutline>
  );
};
