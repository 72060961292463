/* eslint-disable camelcase */
import React from 'react';
import { useSubscriptionStatusForProduct } from './subscriptionStatusHooks';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import BasicErrorWithImageFallback from '../generic/errorBoundary/BasicErrorWithImageFallback';
import { hasFailedPayment } from '../generic/subscriptions/invoiceUtil';
import { KaizenProductStatus, ObjectiveToKaizenProduct } from '../generic/subscriptions/SubscriptionConstants';
import Header from '../header/header';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import trafficCone from '../../images/traffic-cone.png';
import './dashboard-route.css';

const DashboardRoute = ({ children, objective }) => {
  const productForObjective = ObjectiveToKaizenProduct[objective];
  const {
    isFetching,
    canViewDashboard,
    invoices,
    isStripeSubscription,
    isShopifySubscription,
    kaizenProductStatus,
  } = useSubscriptionStatusForProduct(productForObjective);

  // No restrictions
  if (objective == null) {
    return children;
  }

  // But if there are restrictions, but we don't have the state available yet, we can't show anything yet
  if (isFetching) {
    return (
      <HeaderAwarePage scrollable={false}>
        <Header />
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <CenteredProfitRoverSpinner />
        </div>
      </HeaderAwarePage>
    );
  }

  if (canViewDashboard) {
    return children;
  }

  return (
    <NotAccessibleNotice
      invoices={invoices}
      isStripeSubscription={isStripeSubscription}
      isShopifySubscription={isShopifySubscription}
      kaizenProductStatus={kaizenProductStatus}
    />
  );
};

const NotAccessibleNotice = ({ invoices, isStripeSubscription, isShopifySubscription, kaizenProductStatus }) => {
  const failedPaymentInvoices = invoices.filter(hasFailedPayment);
  const hasMultipleFailedPayments = failedPaymentInvoices.length > 1;

  const paymentFailed =
    (isShopifySubscription && kaizenProductStatus === KaizenProductStatus.PAST_DUE) ||
    kaizenProductStatus === KaizenProductStatus.UNPAID ||
    (isStripeSubscription && failedPaymentInvoices.length > 0);

  if (paymentFailed) {
    const payInvoiceUrl = hasMultipleFailedPayments ? '/invoices' : `/pay-invoice/${failedPaymentInvoices[0]?.id}`;

    const getErrorDescription = () => {
      if (isShopifySubscription) {
        return (
          <p className="error-description">
            Please pay your past due invoices in your Shopify Dashboard to regain access to your insights
          </p>
        );
      }

      return (
        <p className="error-description">
          Please pay your past due invoice{hasMultipleFailedPayments ? 's' : ''} <a href={payInvoiceUrl}>here</a> to
          regain access to your insights
        </p>
      );
    };

    return (
      <>
        <Header />
        <BasicErrorWithImageFallback
          imageSrc={trafficCone}
          top={<p className="error-description">Payment was unsuccessful</p>}
          bottom={getErrorDescription()}
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <BasicErrorWithImageFallback
        imageSrc={trafficCone}
        top={
          <p className="error-description">
            Your subscription is not active. You cannot access this dashboard until you update your subscription
            information.
          </p>
        }
        bottom={
          <p className="error-description">
            Please click <a href="/subscription-management">here</a> to renew
          </p>
        }
      />
    </>
  );
};

export default DashboardRoute;
