import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ProfitRoverPrimaryButton } from '../../../forms/ProfitRoverButtons';
import ProfitRoverSpinner from '../../../spinner/ProfitRoverSpinner';

const DEFAULT_BANNER_TEXT = 'Ready to unlock insights at more of your locations?';

const ShopifyStartPlanBanner = ({ onClick, disabled, text }) => {
  return (
    <div className="end-trial-early-box">
      <span className="unlock-text">{text || DEFAULT_BANNER_TEXT}</span>
      <ProfitRoverPrimaryButton className="end-trial-early-button" onClick={onClick} disabled={disabled}>
        Start My Plan Today{' '}
        {disabled ? (
          <ProfitRoverSpinner className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '0.5rem' }} />
        )}
      </ProfitRoverPrimaryButton>
    </div>
  );
};

export default ShopifyStartPlanBanner;
