import _ from 'lodash';
import React from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { useLocation, useHistory, Redirect, Link } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useCreatePartnerRecords } from './createDatabaseRecords';
import { useNavigateToPartnerOAuthInitiate } from './PartnerHooks';
import { KA_QUEUE } from '../IntegrationConstants';
import LoadingIndicator from '../../accountSettings/common/LoadingIndicator';
import { DataSourceDisplayName, RedirectBehavior } from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import GenericErrorBoundaryPage, {
  Message,
  MessageBubble,
  Title,
} from '../../generic/errorBoundary/GenericErrorBoundaryPage';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import { useRequestDataSync, useUpdateDatasetConfig } from '../../../data-access/mutation/datasetConfigs';
import { useUpdateGuidedSetup } from '../../../data-access/mutation/guidedSetup';
import { useCreatePartnerSchedule } from '../../../data-access/mutation/schedules';
import { useObtainPartnerAccessToken } from '../../../data-access/mutation/oauthPartner';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import { useSchedules } from '../../../data-access/query/schedules';

/**
 * The code in this file is used to obtain access/refresh tokens as part of an OAuth process.
 * for data integration purposes. Note that it may be necessary to alter the flow depending on the partner.
 *
 * Square: https://developer.squareup.com/docs/oauth-api/walkthrough
 * GoTab: https://docs.gotab.io/docs/authorization-code-grant-flow
 */

const LoadingMessage = ({ message }) => (
  <HeaderAwarePage scrollable={false}>
    <Header />
    <div
      className="container d-flex flex-column align-items-center justify-content-center text-center"
      style={{ height: '100%' }}
    >
      <h4 className="mb-5">{message}</h4>
      <LoadingIndicator />
    </div>
  </HeaderAwarePage>
);

const FINALIZATION_MESSAGE = 'Please wait a moment while we finalize the integration with your account.';

class AuthorizationError extends Error {}
class InvalidRequestError extends Error {}
class MalformedUrlError extends Error {}
class CompletionError extends Error {}

const usePartnerRedirectUrlParams = partner => {
  const location = useLocation();
  const { search } = location;

  const urlParams = new URLSearchParams(search);

  const csrfFromPartnerOAuth = urlParams.get('state');
  const localStoragePartnerState = localStorage.getItem(`${_.lowerCase(partner)}_state`);

  // Verify token to help mitigate csrf attacks
  if (csrfFromPartnerOAuth !== localStoragePartnerState) {
    throw new InvalidRequestError('Invalid authorization request');
  }

  return urlParams;
};

const PartnerAuthParseRedirectUrl = ({ partner, onFinish }) => {
  const urlParams = usePartnerRedirectUrlParams(partner);

  const responseType = urlParams.get('response_type');
  const authCode = urlParams.get('code');
  const error = urlParams.get('error');
  const state = urlParams.get('state');

  const obtainPartnerAccessToken = useObtainPartnerAccessToken(partner, { useErrorBoundary: true });
  const handleError = useErrorHandler();

  if (error) {
    const errorDescription = urlParams.get('error_description');

    if (error === 'access_denied' && errorDescription === 'user_denied') {
      throw new AuthorizationError('Authorization failed: Access Denied');
    } else {
      throw new AuthorizationError(`Authorization failed - Error: ${error}, Details=${errorDescription}`);
    }
  }

  if (authCode == null) {
    throw new MalformedUrlError('No authorization code was provided');
  } else if (responseType && responseType !== 'code') {
    throw new MalformedUrlError('Unrecognized response type');
  }

  const consumeAuthCodeToCreateCredentials = async () => {
    await obtainPartnerAccessToken.mutateAsync({ authCode, state });

    try {
      await onFinish();
    } catch {
      handleError(new CompletionError('Failed to finish setup after redeeming auth code'));
    }
  };

  // Permission was granted by the user, so now we must...
  useEffectOnce(() => {
    consumeAuthCodeToCreateCredentials();
  });

  return <LoadingMessage message={FINALIZATION_MESSAGE} />;
};

const useCompletionBehavior = (partner, redirectBehavior) => {
  const datasetId = Number(localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_id`));
  const datasetConfigId = Number(localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_config_id`));
  const datasetAncillaryId = Number(localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_ancillary_id`));
  const datasetConfigAncillaryId = Number(
    localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_config_ancillary_id`),
  );
  const customerName = localStorage.getItem('customer_name');

  const { data: schedules = [], isFetching: isFetchingSchedules } = useSchedules();
  const { data: datasetConfigs = [], isFetching: isFetchingDatasetConfigs } = useDatasetConfigs();

  const isLoading = isFetchingSchedules || isFetchingDatasetConfigs;

  const history = useHistory();
  const createPartnerSchedule = useCreatePartnerSchedule();
  const updateDatasetConfig = useUpdateDatasetConfig();

  const requestDataSyncMutation = useRequestDataSync();
  const requestDataSync = () => requestDataSyncMutation.mutateAsync({ datasetConfigId }).catch(() => _.noop());
  const requestAncillaryDataSync = () =>
    requestDataSyncMutation.mutateAsync({ datasetConfigAncillaryId }).catch(() => _.noop());

  const processSchedule = schedules.find(e => e.message.dataset_ids === datasetId && e.ka_queue === KA_QUEUE.PROCESS);
  const processScheduleAncillary = schedules.find(
    e => e.message.dataset_ids === datasetAncillaryId && e.ka_queue === KA_QUEUE.PROCESS,
  );
  const datasetConfig = datasetConfigs.find(e => e.dataset_config_id === datasetConfigId);
  const datasetConfigAncillary = datasetConfigs.find(e => e.dataset_config_id === datasetConfigAncillaryId);

  const { industry, data_type: dataType } = datasetConfig ?? {};
  const { industry: industryAncillary, data_type: dataTypeAncillary } = datasetConfigAncillary ?? {};

  const onFinish = async () => {
    if (!processSchedule) {
      await createPartnerSchedule.mutateAsync({
        datasetId,
        industry,
        dataType,
        description: `${DataSourceDisplayName[partner]} integration for ${customerName}`,
      });
    }

    if (!processScheduleAncillary) {
      await createPartnerSchedule.mutateAsync({
        datasetId: datasetAncillaryId,
        industry: industryAncillary,
        dataType: dataTypeAncillary,
        description: `${DataSourceDisplayName[partner]} integration for ${customerName}`,
      });
    }

    if (redirectBehavior !== RedirectBehavior.RECONNECT) {
      // Sends a PROCESS message as a side-effect
      updateDatasetConfig.mutateAsync({ datasetConfigId, datasetConfig: { is_editable: false } }).catch(() => _.noop());
      updateDatasetConfig
        .mutateAsync({
          datasetConfigId: datasetConfigAncillaryId,
          datasetConfig: { is_editable: false },
        })
        .catch(() => _.noop());
    } else {
      // Send PROCESS messages
      requestDataSync();
      requestAncillaryDataSync();
    }

    history.push('/data-sources');
  };

  return { isLoading, onFinish };
};

/**
 * This Component serves double-time for the ADD_NEW_DATA_SOURCE and RECONNECT flows since they are
 * _almost_ identical in behavior.
 */
const AddNewDataSourceRedirect = ({ partner, redirectBehavior }) => {
  const { isLoading, onFinish } = useCompletionBehavior(partner, redirectBehavior);

  if (isLoading) {
    return <LoadingMessage message="Verifying app permissions..." />;
  }

  return <PartnerAuthParseRedirectUrl partner={partner} onFinish={onFinish} />;
};

const GuidedSetupRedirect = ({ partner }) => {
  const customerName = localStorage.getItem('customer_name');
  const { data: guidedSetup = {}, isLoading: isFetchingGuidedSetup } = useGuidedSetup();
  const {
    naics_industry: { naics_code: naicsCode, ka_code: kaCode } = {},
    data_source: { item_classes: itemClasses } = {},
  } = guidedSetup;
  let { schedule_id: primaryScheduleId } = guidedSetup;
  const { data: schedules = [], isLoading: isFetchingSchedules } = useSchedules();

  const isLoading = isFetchingGuidedSetup || isFetchingSchedules;

  const { isInitializing, createPartnerRecords } = useCreatePartnerRecords();
  const updateDatasetConfig = useUpdateDatasetConfig();
  const createPartnerSchedule = useCreatePartnerSchedule();
  const updateGuidedSetup = useUpdateGuidedSetup();
  const history = useHistory();

  const onFinish = async () => {
    const {
      primaryConfig,
      ancillaryConfig,
      datasetConfigId,
      datasetId,
      workflowId,
      datasetConfigAncillaryId,
      datasetAncillaryId,
    } = await createPartnerRecords({ partner, itemClasses, naicsCode, kaCode });

    // Complete dataset config to trigger PROCESSing
    updateDatasetConfig.mutateAsync({ datasetConfigId, datasetConfig: { is_editable: false } }).catch(() => _.noop());

    if (datasetConfigAncillaryId) {
      updateDatasetConfig
        .mutateAsync({
          datasetConfigId: datasetConfigAncillaryId,
          datasetConfig: { is_editable: false },
        })
        .catch(() => _.noop());
    }

    const processSchedule = schedules.find(e => e.message.dataset_ids === datasetId && e.ka_queue === KA_QUEUE.PROCESS);
    const processScheduleAncillary = schedules.find(
      e => e.message.dataset_ids === datasetAncillaryId && e.ka_queue === KA_QUEUE.PROCESS,
    );

    // Create PROCESS schedules for pulling partner data periodically
    if (!processSchedule) {
      const { schedule_ids: scheduleIds } = await createPartnerSchedule.mutateAsync({
        datasetId,
        industry: primaryConfig.industry,
        dataType: primaryConfig.dataType,
        description: `${DataSourceDisplayName[partner]} integration for ${customerName}`,
      });

      [primaryScheduleId] = scheduleIds;
    }

    if (!processScheduleAncillary) {
      await createPartnerSchedule.mutateAsync({
        datasetId: datasetAncillaryId,
        industry: ancillaryConfig.industry,
        dataType: ancillaryConfig.dataType,
        description: `${DataSourceDisplayName[partner]} integration for ${customerName}`,
      });
    }

    await updateGuidedSetup.mutateAsync({
      dataset_config_id: datasetConfigId,
      dataset_id: datasetId,
      workflow_id: workflowId,
      schedule_id: primaryScheduleId,
      is_complete: true,
    });

    history.push('/welcome', { showCompletedGuidedSetupModal: true });
  };

  if (isLoading || isInitializing) {
    return <LoadingMessage message="Please wait a moment..." />;
  }

  return <PartnerAuthParseRedirectUrl partner={partner} onFinish={onFinish} />;
};

const PERMISSIONS_GRANT_DENIED_ERROR_MESSAGE = partner =>
  `We require these permissions for integration with your ${DataSourceDisplayName[partner]} account. 
  To proceed, you must grant permission for us to access your data when prompted.`;

const REQUEST_CANNOT_BE_COMPLETED_MESSAGE = (
  <>
    This request cannot be completed at this time. Contact our <Link to="/help">support team</Link>.
  </>
);
const URL_MALFORMED_SUPPORT_REQUEST_ERROR_MESSAGE = (
  <>
    This URL is invalid. Contact our <Link to="/help">support team</Link>.
  </>
);
const DEFAULT_GENERIC_MESSAGE = (
  <>
    An unknown error occurred. Contact our <Link to="/help">support team</Link>.
  </>
);

export function useNavigateToPartnerInstructions(partner, redirectBehavior) {
  const history = useHistory();

  const datasetId = localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_id`);
  const datasetConfigId = localStorage.getItem(`${_.lowerCase(partner)}_redirect_dataset_config_id`);

  const MAP_REDIRECT_BEHAVIOR_TO_HISTORY_ENTRY = {
    [RedirectBehavior.ADD_NEW_DATA_SOURCE]: `/data-sources/add-new/${_.lowerCase(partner)}`,
    [RedirectBehavior.GUIDED_SETUP]: {
      pathname: `/guided-setup/partner`,
      state: { datasetId, datasetConfigId, partner },
    },
    [RedirectBehavior.RECONNECT]: `/data-sources/reconnect/${_.lowerCase(partner)}`,
  };

  const nextHistoryEntry = MAP_REDIRECT_BEHAVIOR_TO_HISTORY_ENTRY[redirectBehavior];

  return () => {
    if (nextHistoryEntry) {
      history.push(nextHistoryEntry);
    }
  };
}

const ErrorFallback = ({ error, resetErrorBoundary, partner, redirectBehavior }) => {
  const initiateOauth = useNavigateToPartnerOAuthInitiate(partner);
  const navigateBack = useNavigateToPartnerInstructions(partner, redirectBehavior);

  const title = `${DataSourceDisplayName[partner]} Integration Setup Failure`;
  let friendlyErrMessage = DEFAULT_GENERIC_MESSAGE;
  let buttons = (
    <>
      <ProfitRoverPrimaryButton onClick={initiateOauth}>Try Again</ProfitRoverPrimaryButton>
      <ProfitRoverSecondaryButton onClick={navigateBack}>Go Back</ProfitRoverSecondaryButton>
    </>
  );

  if (error instanceof AuthorizationError) {
    friendlyErrMessage = PERMISSIONS_GRANT_DENIED_ERROR_MESSAGE(partner);
  } else if (error instanceof InvalidRequestError) {
    friendlyErrMessage = REQUEST_CANNOT_BE_COMPLETED_MESSAGE;
  } else if (error instanceof MalformedUrlError) {
    friendlyErrMessage = URL_MALFORMED_SUPPORT_REQUEST_ERROR_MESSAGE;
    buttons = (
      <>
        <ProfitRoverPrimaryButton onClick={resetErrorBoundary}>Retry</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={navigateBack}>Go Back</ProfitRoverSecondaryButton>
      </>
    );
  }

  // TODO: Remove this entry from the history stack?

  return (
    <GenericErrorBoundaryPage showingHeader={false}>
      <MessageBubble>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="mb-5">
            <Title>{title}</Title>
            <Message>{friendlyErrMessage}</Message>
          </div>
          <div className="d-flex">{buttons}</div>
        </div>
      </MessageBubble>
    </GenericErrorBoundaryPage>
  );
};

const PartnerAuthRedirect = ({ partner }) => {
  const redirectBehavior = localStorage.getItem(`${_.lowerCase(partner)}_redirect_behavior`);

  let component = null;
  if (redirectBehavior === RedirectBehavior.GUIDED_SETUP) {
    component = (
      <ErrorBoundary
        fallbackRender={props => <ErrorFallback {...props} partner={partner} redirectBehavior={redirectBehavior} />}
      >
        <GuidedSetupRedirect partner={partner} />
      </ErrorBoundary>
    );
  }

  if ([RedirectBehavior.ADD_NEW_DATA_SOURCE, RedirectBehavior.RECONNECT].includes(redirectBehavior)) {
    component = (
      <ErrorBoundary
        fallbackRender={props => <ErrorFallback {...props} partner={partner} redirectBehavior={redirectBehavior} />}
      >
        <AddNewDataSourceRedirect partner={partner} redirectBehavior={redirectBehavior} />
      </ErrorBoundary>
    );
  }

  return component ?? <Redirect to="/login-redirect" />;
};

export default PartnerAuthRedirect;
