import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingPage from './common/LoadingPage';
import CommunicationPreferences from './CommunicationPreferences';
import SettingsPageWrapper from './SettingsPageWrapper';
import Sidebar from './sidebar';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { isEoPriceoptWorkflow } from '../workflow/workflowFunctions';
import { useWorkflows } from '../../data-access/query/workflows';

const Preferences = () => {
  const { data: workflows = [], isLoading: isLoadingWorkflows } = useWorkflows();
  const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);

  // This page should only be accessible if the user has one KP workflow
  if (!isLoadingWorkflows && kpWorkflows.length !== 1) {
    return <Redirect to="/personalSettings" />;
  }

  if (isLoadingWorkflows) {
    return <LoadingPage />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <Sidebar />
      <SettingsPageWrapper>
        <div className="container">
          <CommunicationPreferences />
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default Preferences;
