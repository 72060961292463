/* eslint-disable react/display-name */
import React from 'react';
import _ from 'lodash';
import ReactTable from 'react-table';
import Select from 'react-select';
// import { Prompt } from 'react-router-dom'; // TODO: Use this appropriately
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faQuestionCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { useRawDimValueRowApi } from './ManageDimensionValueMappingsHooks';
import LoadingPage from '../common/LoadingPage';
import IconButton from '../components/IconButton';
import SettingsPageWrapper from '../SettingsPageWrapper';
import Sidebar from '../sidebar';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import Header from '../../header/header';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import { FONT_WHITE, MID_GREEN } from '../../../colors';
import { useDimensions } from '../../../data-access/query/dimensions';
import { useDimensionValues, useRawDimensionValues } from '../../../data-access/query/dimensionValues';
import { useEditRawDimensionValuesMutations } from '../../../data-access/mutation/dimensionValues';
import {
  gaEmitEditDimensionValueMappingCheckmarkClick,
  gaEmitSaveChangesDimensionValueMappingButtonClick,
} from '../../../google-analytics/dataSettings';
import './manage-dimension-value-mappings.css';

const tooltipText = "Filters can't be changed until pending changes are saved";

const MappingFilteringArea = props => {
  const { filterState, selectedDimensionState, dimensions, masterDimValues, pageState, changesPending } = props;
  const {
    rawValueSearchText,
    setRawValueSearchText,
    ignoredFilter,
    setIgnoredFilter,
    selectedMasterValue,
    setSelectedMasterValue,
  } = filterState;

  const [selectedDimension, setSelectedDimension] = selectedDimensionState;
  const [, setCurrentPage] = pageState;

  const onDimensionChange = dimension => {
    const newDimensionSelected = !Object.is(dimension, selectedDimension);
    const dimensionCleared = dimension == null;

    setSelectedDimension(dimension);

    if (newDimensionSelected || dimensionCleared) {
      setRawValueSearchText('');
      setSelectedMasterValue(null);
      setCurrentPage(0);
    }
  };

  const dimensionOptions = _.sortBy(dimensions, value => {
    return value.name.toLocaleLowerCase();
  });
  const masterDimValueOptions = _.sortBy(masterDimValues, value => {
    return value.value.toLocaleLowerCase();
  });

  const disableInputs = selectedDimension == null || changesPending;

  const StagedChangesTooltip = React.useMemo(
    () => ({ children }) => (
      <ProfitRoverTooltip
        shouldDisplayTooltip={changesPending}
        tooltipText={tooltipText}
        tooltipId="disabled-filter-tooltip"
        placement="bottom"
      >
        {children}
      </ProfitRoverTooltip>
    ),
    [changesPending],
  );

  return (
    <div className="filtering-section">
      <div className="filter-selection-container">
        <div>Manage Dimension Values for:</div>

        <StagedChangesTooltip>
          <div style={{ width: '100%', minWidth: 255 }}>
            <Select
              options={dimensionOptions}
              value={selectedDimension}
              getOptionLabel={dim => dim.name}
              getOptionValue={dim => dim}
              onChange={onDimensionChange}
              placeholder="Select a dimension..."
              isClearable
              isSearchable
              isMulti={false}
              isDisabled={changesPending}
            />
          </div>
        </StagedChangesTooltip>
      </div>

      <div
        className="filter-selection-container"
        style={{
          maxWidth: 500,
        }}
      >
        <div>Value from Data:</div>
        <StagedChangesTooltip>
          <input
            style={{
              padding: '0px 7px',
              height: 38,
              borderRadius: 4,
              border: '1px solid rgb(204, 204, 204)',
              minWidth: 205,
              background: 'white',
            }}
            value={rawValueSearchText}
            onChange={e => setRawValueSearchText(e.target.value)}
            placeholder="Type to search..."
            disabled={disableInputs}
          />
        </StagedChangesTooltip>
      </div>

      <div className="filter-selection-container">
        <div>Ignore?</div>
        <StagedChangesTooltip>
          <div style={{ minWidth: 120 }}>
            <Select
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              getOptionLabel={ignoreValue => ignoreValue.label}
              getOptionValue={ignoreValue => ignoreValue.value}
              value={ignoredFilter}
              onChange={value => setIgnoredFilter(value)}
              isMulti={false}
              isClearable
              isDisabled={disableInputs}
            />
          </div>
        </StagedChangesTooltip>
      </div>

      <div className="filter-selection-container" style={{ minWidth: 150 }}>
        <div>Master Value</div>
        <StagedChangesTooltip>
          <div style={{ minWidth: 300 }}>
            <Select
              options={masterDimValueOptions}
              getOptionLabel={dimValue => dimValue.value}
              getOptionValue={dimValue => dimValue}
              value={selectedMasterValue}
              onChange={value => setSelectedMasterValue(value)}
              isMulti={false}
              isClearable
              isDisabled={disableInputs}
              placeholder="Select a master dimension value..."
            />
          </div>
        </StagedChangesTooltip>
      </div>
    </div>
  );
};

const HEADER_STYLES = {
  background: MID_GREEN,
  color: FONT_WHITE,
  paddingTop: '8px',
  fontWeight: 'bold',
  fontSize: 14,
};

const TEXT_CELL_STYLES = {
  display: 'flex',
  alignItems: 'center',
};

const ICON_FONT_SIZE = 14;

const useFilteredRawDimValues = ({
  tableRawDimValues,
  selectedDimensionId,
  selectedMasterValue,
  ignoredFilter,
  rawValueSearchText,
}) => {
  const rawDimValuesInTable = tableRawDimValues.filter(rawValue => {
    const isTextSearching = rawValueSearchText !== '';

    const dimensionMatches = rawValue.getDimensionId() === selectedDimensionId;

    let searchTextMatches = true;
    if (isTextSearching) {
      let rawValueName = rawValue.getValue();

      rawValueName = rawValue.getValue().toLocaleLowerCase();

      searchTextMatches = rawValueName.includes(rawValueSearchText.toLocaleLowerCase());
    }

    const ignoreMatches = ignoredFilter?.value != null ? ignoredFilter.value === rawValue.getIsIgnore() : true;

    const selectedMasterValueMatches =
      selectedMasterValue != null ? selectedMasterValue.id === rawValue.getDimensionValueId() : true;

    const isBeingEdited = rawValue.isBeingEdited();
    const hasStagedChanges = rawValue.isEdited();

    return (
      (dimensionMatches && searchTextMatches && ignoreMatches && selectedMasterValueMatches) ||
      isBeingEdited ||
      hasStagedChanges
    );
  });

  return rawDimValuesInTable.sort((a, b) =>
    a.getValue().toLocaleLowerCase() > b.getValue().toLocaleLowerCase() ? 1 : -1,
  );
};

const MappingRawValuesTable = props => {
  const { selectedDimension, masterDimValues, filterState, onClickSave, pageState, pageSizeState, rowsApi } = props;
  const { tableRawDimValues, setIsEditing, updateMapping, updateIsIgnored, resetToOriginal } = rowsApi;

  const { rawValueSearchText, ignoredFilter, selectedMasterValue } = filterState;

  const [currentPage, setCurrentPage] = pageState;
  const [currentPageSize, setCurrentPageSize] = pageSizeState;

  const { product_dimension_id: selectedDimensionId } = selectedDimension ?? {};

  const masterDimValueOptions = _.sortBy(masterDimValues, value => {
    return value.value.toLocaleLowerCase();
  });

  // Filter to values that should be shown in the table
  const filterDependencies = {
    tableRawDimValues,
    selectedDimensionId,
    selectedMasterValue,
    ignoredFilter,
    rawValueSearchText,
  };
  const rawDimValuesInTable = useFilteredRawDimValues(filterDependencies);

  const columns = [
    {
      id: 'rawValue',
      Header: 'Value from Data',
      headerStyle: HEADER_STYLES,
      accessor: dimValueMapping => dimValueMapping.getValue(),
      style: TEXT_CELL_STYLES,
      minWidth: 400,
      Cell: cellProps => {
        const { original: dimValueMapping } = cellProps;
        return <div style={{ paddingLeft: 10, width: '100%' }}>{dimValueMapping.getValue()}</div>;
      },
    },
    {
      id: 'isIgnored',
      Header: 'Ignore?',
      headerStyle: HEADER_STYLES,
      accessor: dimValueMapping => dimValueMapping.getIsIgnore(),
      minWidth: 65,
      style: { ...TEXT_CELL_STYLES, justifyContent: 'center', overflow: 'visible' },
      Cell: cellProps => {
        const { original: dimValueMapping } = cellProps;
        const isChecked = dimValueMapping.getIsIgnore();

        const backgroundColor =
          !dimValueMapping.isBeingEdited() && dimValueMapping.isIgnoreIsEdited() ? '#ffffcc' : 'unset';

        return (
          <div style={{ backgroundColor, padding: '2px 8px' }}>
            <input
              id={dimValueMapping.getValue()}
              type="checkbox"
              disabled={!dimValueMapping.isBeingEdited()}
              checked={isChecked}
              onChange={() => updateIsIgnored(dimValueMapping, !isChecked)}
              style={{ backgroundColor }}
            />
          </div>
        );
      },
    },
    {
      id: 'mappedMasterValue',
      Header: 'Master Value',
      headerStyle: HEADER_STYLES,
      accessor: row => row.getDimensionValueId(),
      minWidth: 375,
      style: { ...TEXT_CELL_STYLES, overflow: 'visible' },
      Cell: cellProps => {
        const { original: dimValueMapping } = cellProps;

        const dimensionValueId = dimValueMapping.getDimensionValueId();
        const selectedMasterValueForRow = masterDimValues.find(dimValue => dimValue.id === dimensionValueId);

        if (!dimValueMapping.isBeingEdited()) {
          const backgroundColor = dimValueMapping.dimensionValueIdIsEdited() ? '#ffffcc' : 'unset';
          const label = selectedMasterValueForRow?.value ?? <i>(Unmapped)</i>;
          return <div style={{ backgroundColor, padding: '0px 10px' }}>{label}</div>;
        }

        return (
          <div id={cellProps.index} style={{ width: '100%', overflow: 'visible' }}>
            <Select
              isDisabled={!dimValueMapping.isBeingEdited()}
              options={masterDimValueOptions}
              getOptionLabel={dimValue => dimValue.value}
              getOptionValue={dimValue => dimValue}
              value={selectedMasterValueForRow}
              onChange={masterDimValue => updateMapping(dimValueMapping, masterDimValue)}
            />
          </div>
        );
      },
    },
    {
      minWidth: 85,
      Header: 'Actions',
      headerStyle: HEADER_STYLES,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: cellProps => {
        const { original: dimValueMapping } = cellProps;

        if (dimValueMapping.isBeingEdited()) {
          return (
            <>
              {/* Accept changes */}
              <IconButton
                key="accept"
                onClick={() => {
                  gaEmitEditDimensionValueMappingCheckmarkClick();
                  setIsEditing(dimValueMapping, false);
                }}
              >
                <FontAwesomeIcon className="Icon" icon={faCheck} fontSize={ICON_FONT_SIZE} />
              </IconButton>
              {/* Reset changes to original value */}
              <IconButton key="reset" onClick={() => resetToOriginal(dimValueMapping)}>
                <FontAwesomeIcon className="Icon" icon={faUndo} fontSize={ICON_FONT_SIZE} />
              </IconButton>
            </>
          );
        }

        return (
          <IconButton key="edit" onClick={() => setIsEditing(dimValueMapping, true)}>
            <FontAwesomeIcon className="Icon" icon={faEdit} fontSize={ICON_FONT_SIZE} />
          </IconButton>
        );
      },
    },
  ];

  const editedRawValues = tableRawDimValues.filter(rawValue => rawValue.isEdited());
  const currentlyBeingEditedValues = tableRawDimValues.filter(rawValue => rawValue.isBeingEdited());

  const editingInProgress = currentlyBeingEditedValues.length > 0;
  const stagedChanges = editedRawValues.length > 0;

  return (
    <div style={{ maxWidth: '100%' }}>
      <ReactTable
        data={rawDimValuesInTable}
        columns={columns}
        style={{ overflow: 'visible', background: 'white' }}
        getTableProps={() => ({ style: { overflow: 'visible' } })}
        getTbodyProps={() => ({ style: { overflow: 'visible' } })}
        getTrProps={() => ({ style: { overflow: 'visible', backgroundColor: 'white' } })}
        getTdProps={() => ({ style: { overflow: 'visible' } })}
        getTrGroupProps={() => ({ style: { overflow: 'visible' } })}
        defaultPageSize={10}
        pageSizeOptions={[10]}
        minRows={1}
        showPaginationBottom={rawDimValuesInTable.length > 10}
        filterable={false}
        resizable={false}
        sortable={false}
        page={currentPage}
        onPageChange={page => setCurrentPage(page)}
        pageSize={currentPageSize}
        onPageSizeChange={pageSize => setCurrentPageSize(pageSize)}
      />
      <div className="d-flex mt-3 save-btn-container">
        <ProfitRoverPrimaryButton
          disabled={editingInProgress || !stagedChanges}
          onClick={() => onClickSave(editedRawValues)}
        >
          Save Changes
        </ProfitRoverPrimaryButton>
      </div>
    </div>
  );
};

const useFilterState = () => {
  const [rawValueSearchText, setRawValueSearchText] = React.useState('');
  const [ignoredFilter, setIgnoredFilter] = React.useState(null);
  const [selectedMasterValue, setSelectedMasterValue] = React.useState();

  return {
    rawValueSearchText,
    setRawValueSearchText,
    ignoredFilter,
    setIgnoredFilter,
    selectedMasterValue,
    setSelectedMasterValue,
  };
};

const ManageDimensionValueMappings = props => {
  const { dimensionId: dimensionIdFromProps, dimensions, toggleModalOpen } = props;
  const { data: masterDimValues } = useDimensionValues(true);
  const { data: rawDimValues = [], refetch: refetchRawValues } = useRawDimensionValues();

  const selectedDimensionState = React.useState(() =>
    dimensions.find(dim => dim.product_dimension_id === dimensionIdFromProps),
  );
  const pageState = React.useState(0);
  const pageSizeState = React.useState(10);

  const [selectedDimension] = selectedDimensionState;

  const { product_dimension_id: selectedDimensionId = '' } = selectedDimension ?? {};

  const rowsApi = useRawDimValueRowApi(rawDimValues, selectedDimensionId);
  const { changesPending } = rowsApi;

  const filterState = useFilterState();

  const masterDimValuesForDimension = masterDimValues.filter(
    ({ dimension_id: dimensionId }) => dimensionId === selectedDimensionId,
  );

  const { updateRawDimensionValuesMutation } = useEditRawDimensionValuesMutations();

  const onClickSave = async dimValueMappings => {
    gaEmitSaveChangesDimensionValueMappingButtonClick();
    const updates = dimValueMappings.map(mapping => mapping.buildPutBodyRepresentation());
    await updateRawDimensionValuesMutation.mutateAsync({ updates });
    await refetchRawValues();
  };

  return (
    <div className="container">
      <div className="manage-dim-val-mapping">
        <h3 className="settings-header">
          <div className="d-flex align-items-center justify-content-start">
            Dimension Value Mapping
            <button type="button" onClick={toggleModalOpen}>
              <FontAwesomeIcon className="Icon" icon={faQuestionCircle} fontSize={ICON_FONT_SIZE} />
            </button>
          </div>
        </h3>

        <MappingFilteringArea
          filterState={filterState}
          selectedDimensionState={selectedDimensionState}
          dimensions={dimensions}
          masterDimValues={masterDimValuesForDimension}
          pageState={pageState}
          changesPending={changesPending}
        />

        <MappingRawValuesTable
          selectedDimension={selectedDimension}
          masterDimValues={masterDimValuesForDimension}
          rowsApi={rowsApi}
          filterState={filterState}
          onClickSave={onClickSave}
          pageState={pageState}
          pageSizeState={pageSizeState}
        />
      </div>
    </div>
  );
};

const DimValueMappingHelpText = ({ toggleModalOpen }) => (
  <>
    <Modal.Header>
      <Modal.Title className="w-100 text-center">Dimension Value Mapping Help</Modal.Title>
    </Modal.Header>

    <ProfitRoverModalMain>
      <div>
        <p>
          This screen allows you to combine items read from your data or ignore certain items from your data altogether.
        </p>
        <h4 className="mt-4 mb-3">Combining Similar Items from Your Data</h4>
        <h5>Why would I want to combine items?</h5>
        <ul>
          <li>
            You changed the name of an item in your system but we had already pulled data tied to the previous name
            (e.g. “Collector’s Cup” renamed to “Commemorative Cup”).
          </li>
          <li>
            You intentionally have multiple items split out in your system but conceptually they are the same thing and
            you’d like them to be consolidated for analysis (e.g. you track “Boys Small Shirt” and “Girls Small Shirt”
            separately but conceptually view them collectively as “Small Shirt”).
          </li>
          <li>
            You use multiple systems to track your data (e.g. different systems for different locations) and the
            different systems use different names to represent the same item (e.g. system 1 refers to a small drink as
            “Small Drink” whereas system 2 refers to it as “Sm Drink”).
          </li>
          <li>
            You accidentally have multiple entries in your system representing the same item (e.g. “Hot Dog” and
            “HotDog”).
          </li>
        </ul>
        <h5>For each of the items you wish to combine:</h5>
        <ol>
          <li>
            Locate an item that you wish to map to a different item in the “Value from Data” column and click the edit
            icon in the rightmost column for that row.
          </li>
          <li>Using the “Master Value” dropdown, select the item to which you’d like to map this item.</li>
          <li>
            Click the checkmark in the rightmost column for that row once you have completed your selection. You can
            revert your change by clicking the back arrow associated with that row.
          </li>
          <li>Continue to map other items or click the “Save Changes” button below the table to finish.</li>
        </ol>
        <h5>Notes</h5>
        <ul>
          <li>Every time you click “Save Changes” we will reprocess your data and generate updated results.</li>
          <li>
            You can rename your “Master Value” (or create new Master Values) by navigating to the “Manage Dimension
            Values” page listed in the panel to the left; this action can be performed before or after you combine the
            values.
          </li>
        </ul>
        <h4 className="mt-4 mb-3">Ignoring Items from Your Data</h4>
        <h5>Why would I want to ignore items?</h5>
        <ul>
          <li>
            There’s an item you have stopped selling and you do not wish to receive forecasts or recommendations for it
            anymore.
          </li>
          <li>There’s an item whose sales / revenue is so small that it’s not worth listing in your results.</li>
        </ul>
        <h5>For each of the items you wish to ignore:</h5>
        <ol>
          <li>
            Locate the item in the “Value from Data” column and click the edit icon in the rightmost column for that
            row.
          </li>
          <li>Check the “Ignore?” box for that row.</li>
          <li>
            Click the checkmark in the rightmost column for that row once you have completed your selection. You can
            revert your change by clicking the back arrow associated with that row.
          </li>
          <li>Continue to ignore other items or click the “Save Changes” button below the table to finish.</li>
        </ol>
        <h5>Notes</h5>
        <ul>
          <li>
            Every time you click “Save Changes” we will reprocess your data and generate updated results. Ignored items
            will no longer be present in the updated results.
          </li>
          <li>
            You can always come back and reinclude items by unchecking the “Ignore?” box and saving those changes.
          </li>
        </ul>
      </div>
    </ProfitRoverModalMain>
    <ProfitRoverModalFooter>
      <ProfitRoverSecondaryButton variant="secondary" className="cancel-button" onClick={toggleModalOpen}>
        Close
      </ProfitRoverSecondaryButton>
    </ProfitRoverModalFooter>
  </>
);

const DataFetcher = props => {
  const { dimensionId } = props.location.state ?? {};
  const { data: dimensions = [], isLoading } = useDimensions(false);
  const canInitialize = dimensions.length && !isLoading;
  const [showModal, setShowModal] = React.useState(false);

  const toggleModalOpen = () => setShowModal(!showModal);

  if (!canInitialize) {
    return <LoadingPage />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <Sidebar />
      <SettingsPageWrapper>
        <Modal centered size="lg" show={showModal} onHide={toggleModalOpen}>
          <DimValueMappingHelpText toggleModalOpen={toggleModalOpen} />
        </Modal>

        <ManageDimensionValueMappings
          dimensionId={dimensionId}
          dimensions={dimensions}
          toggleModalOpen={toggleModalOpen}
        />
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default DataFetcher;
