/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import AdminRoleCheck from './AdminRoleCheck';
import CheckoutPlanSwitchPanel from './CheckoutPlanSwitchPanel';
import { NotFound } from './common/CommonSubscriptionComponents';
import SettingsPageWrapper from '../SettingsPageWrapper';
import SideBar from '../sidebar';
import LoadingPage from '../common/LoadingPage';
import LoadingIndicator from '../common/LoadingIndicator';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import {
  KaizenBillingInterval,
  KaizenProduct,
  KaizenProductStatus,
  SubscriptionType,
} from '../../generic/subscriptions/SubscriptionConstants';
import { ProfitRoverLinearLogo } from '../../generic/ProfitRoverLogos';
import Header from '../../header/header';
import { useShopifyProductPrices } from '../../registration/customerRegistrationHooks';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { BACKGROUND_GRAY } from '../../../colors';
import { useShopifyPrices } from '../../../data-access/query/shopify';
import { useShopifyRequestPlanIntervalUpdate } from '../../../data-access/mutation/subscriptions';
import { useShopifyBillingInfo } from '../../../data-access/query/subscriptions';
import priceTag from '../../../images/best-value-price-tag.png';
import './manage-plan.css';

const firstLocationFreeText = '1st Location Free';

const AnnualPlanCard = ({ prices, pricesLoading, isFirstFree }) => {
  const { annualPriceDollarAmt, savingsPerYear, savingsPerYearPct } = useShopifyProductPrices(prices);

  if (pricesLoading) {
    return (
      <div className="plan-container annual-plan-container">
        <img src={priceTag} alt="best value price tag" className="price-tag-icon" />
        <div className="plan-name">Annual Plan</div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="plan-container annual-plan-container">
      <img src={priceTag} alt="best value price tag" className="price-tag-icon" />
      <div className="plan-name">Annual Plan</div>
      {isFirstFree && <div className="pb-1">{firstLocationFreeText}</div>}
      <div className="plan-price">${annualPriceDollarAmt} per Location per Year</div>
      <div className="plan-savings-container">
        <div className="savings-per-year-text-container">
          <FontAwesomeIcon icon={faStar} />
          <p>You are saving ${savingsPerYearPct}%!</p>
          <FontAwesomeIcon icon={faStar} />
        </div>
        <div className="savings-per-location-text-container">
          <p className="savings-per-location-text">That&apos;s ${savingsPerYear} per location!</p>
        </div>
      </div>
    </div>
  );
};

const MonthlyPlanCard = ({ prices, pricesLoading, isFirstFree }) => {
  const { pricePerMonthDollarAmt } = useShopifyProductPrices(prices);

  if (pricesLoading) {
    return (
      <div className="plan-container monthly-plan-container">
        <div className="plan-name">Monthly Plan</div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="plan-container monthly-plan-container">
      <div className="plan-name">Monthly Plan</div>
      {isFirstFree && <div className="pb-1">{firstLocationFreeText}</div>}
      <div className="plan-price">${pricePerMonthDollarAmt} per Location per Month</div>
      <div className="plan-savings-container">No commitment, pay month to month</div>
    </div>
  );
};

const ManageShopifyPlan = ({ subscriptionId, subscriptionType }) => {
  const [confirmPlanChangeVisible, setConfirmPlanChangeVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const billingInfoState = useShopifyBillingInfo(subscriptionId);
  const { data: billingInfo = {}, isFetching: billingInfoLoading } = billingInfoState;
  const { billing_interval: billingInterval, price_nickname: priceName } = billingInfo ?? {};

  const { mutateAsync: requestPlanIntervalUpdate } = useShopifyRequestPlanIntervalUpdate();

  const { data: priceMap = {}, isFetching: pricesLoading } = useShopifyPrices(KaizenProduct.KP);
  const prices = priceMap[priceName];

  const isFirstFree = subscriptionType === SubscriptionType.FIRST_FREE;

  const hideConfirmPlanChange = () => setConfirmPlanChangeVisible(false);
  const choosePlan = plan => setConfirmPlanChangeVisible(plan !== billingInterval);
  const chooseMonthlyPlan = () => choosePlan(KaizenBillingInterval.MONTHLY);
  const chooseAnnualPlan = () => choosePlan(KaizenBillingInterval.ANNUAL);

  const isPlanSelected = plan =>
    (plan === billingInterval && !confirmPlanChangeVisible) || (plan !== billingInterval && confirmPlanChangeVisible);

  const onClickContinue = async () => {
    setIsLoading(true);

    try {
      const { confirmation_url: confirmationUrl } = await requestPlanIntervalUpdate({
        subscriptionId,
        billingInterval:
          billingInterval === KaizenBillingInterval.ANNUAL
            ? KaizenBillingInterval.MONTHLY
            : KaizenBillingInterval.ANNUAL,
      });

      if (confirmationUrl) {
        // Navigate to Shopify in the same tab
        window.open(confirmationUrl, '_self');
      } else {
        setIsLoading(false);
      }
    } catch (_) {
      setIsLoading(false);
    }
  };

  if (billingInfoLoading || pricesLoading) {
    return <LoadingPage />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <SideBar />
      <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY }}>
        <div className="manage-plan-container">
          <div>
            <LinkWithDirectionalArrow
              target="/subscription-management/shopify"
              text="Back to Subscription"
              direction="left"
              arrowRelativeToText="left"
              linkClassName="back-to-subscription-link"
            />
            <div className="manage-plan-header">Manage Your Plan</div>
          </div>
          <div className="summary">
            <ProfitRoverCard>
              <ProfitRoverLinearLogo className="profitrover-logo" />
              <div className="plans-container">
                <div className="container" onClick={chooseAnnualPlan} role="button">
                  <AnnualPlanCard prices={prices} pricesLoading={pricesLoading} isFirstFree={isFirstFree} />
                  <input
                    type="radio"
                    name="radio-plan"
                    checked={pricesLoading ? false : isPlanSelected(KaizenBillingInterval.ANNUAL)}
                    readOnly
                  />
                  <span className="checkmark" />
                </div>

                <div className="container" onClick={chooseMonthlyPlan} role="button">
                  <MonthlyPlanCard prices={prices} pricesLoading={pricesLoading} isFirstFree={isFirstFree} />
                  <input
                    type="radio"
                    name="radio-plan"
                    checked={pricesLoading ? false : isPlanSelected(KaizenBillingInterval.MONTHLY)}
                    readOnly
                  />
                  <span className="checkmark" />
                </div>
              </div>
            </ProfitRoverCard>
          </div>
        </div>
        {confirmPlanChangeVisible && (
          <CheckoutPlanSwitchPanel
            onContinue={onClickContinue}
            onCancel={hideConfirmPlanChange}
            switchingTo={billingInterval === KaizenBillingInterval.ANNUAL ? 'Monthly Plan' : 'Annual Plan'}
            isLoading={isLoading}
          />
        )}
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

const ManagePlanHandler = () => {
  const {
    isFetching,
    hasSubscription,
    kaizenProductStatus,
    isShopifySubscription,
    subscriptionId,
    subscriptionType,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);

  if (isFetching) {
    return <LoadingPage />;
  }

  if (hasSubscription && isShopifySubscription && kaizenProductStatus === KaizenProductStatus.ACTIVE) {
    return <ManageShopifyPlan subscriptionId={subscriptionId} subscriptionType={subscriptionType} />;
  }

  return <NotFound />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <ManagePlanHandler {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
