import React from 'react';
import { Prompt } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import SettingsPageWrapper from './SettingsPageWrapper';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import SideBar from './sidebar';
import { KA_API_URL } from '../../config/baseUrl';
import { isValidWebsiteAddress, isValidPhoneNumber } from '../../utils/validators';
import '../auth/auth.css';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import SettingsInput from './SettingsInput';

class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      website: '',
      phone: '',
      currentValues: {},
      settingsChange: false,
      settingsUpdate: {},
      error: false,
    };
  }

  async componentDidMount() {
    localStorage.removeItem('unsaved_page');

    await this.fetchCustomerData();
  }

  fetchCustomerData = async () => {
    let { currentValues } = this.state;

    try {
      const response = await axios.get(`${KA_API_URL}/customer`);
      if (response.status === 200) {
        currentValues = response.data;

        await this.setState({
          name: currentValues.overview.name,
          website: currentValues.overview.website,
          phone: currentValues.overview.phone,
          currentValues,
        });
      }
    } catch (error) {
      await this.setState({
        error: true,
        message: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  handleSettingChange = async e => {
    const input = e.target.name;
    const { value } = e.target;
    const { settingsUpdate, settingsChange } = this.state;
    settingsUpdate[input] = value;
    await this.setState({
      [input]: value,
      settingsUpdate,
    });

    if (!settingsChange) {
      localStorage.setItem('unsaved_page', '/companySettings');
      await this.setState({
        settingsChange: true,
      });
    }
  };

  saveCompanySettings = async e => {
    e.preventDefault();
    await this.setState({
      error: false,
      message: '',
    });
    const { name, website, phone, settingsUpdate } = this.state;
    const settingUpdates = Object.keys(settingsUpdate);

    const websiteValid = isValidWebsiteAddress(website);
    const phoneNumberValid = isValidPhoneNumber(phone);

    if (name === '' || website === '' || phone === '') {
      await this.setState({
        error: true,
        message: 'Fields cannot be blank',
      });
    } else if (settingUpdates.length < 1) {
      await this.setState({
        error: true,
        message: 'No changes to save',
      });
    } else if (!websiteValid) {
      await this.setState({
        error: true,
        message: 'Please enter a valid website',
      });
    } else if (!phoneNumberValid) {
      await this.setState({
        error: true,
        message: 'Please enter a valid phone number',
      });
    } else {
      await this.updateCompanyInfo();
    }
  };

  updateCompanyInfo = async () => {
    const { settingsUpdate } = this.state;

    try {
      const response = await axios.put(`${KA_API_URL}/customer`, settingsUpdate);

      if (response.status === 200) {
        await this.setState({
          error: false,
          settingsChange: false,
          settingsUpdate: {},
        });
        localStorage.removeItem('unsaved_page');
        await this.fetchCustomerData();
      }
    } catch (error) {
      await this.setState({
        error: true,
        message: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  componentDidUpdate = () => {
    const { settingsChange } = this.state;
    if (settingsChange) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    const { error, message, settingsChange, name, website, phone } = this.state;
    let errorMessage = null;
    if (error) {
      errorMessage = (
        <div className="mb-3">
          <p className="settings-error-message">{message}</p>
        </div>
      );
    }

    return (
      <HeaderAwarePage>
        <React.Fragment>
          <Prompt when={settingsChange} message="You have unsaved changes, are you sure you want to leave?" />
        </React.Fragment>
        <Header />
        <SideBar />
        <SettingsPageWrapper>
          <div className="container">
            <h1 className="settings-header">Company</h1>
            <ProfitRoverCard className="settings-card">
              <form className="settings-form">
                <SettingsInput field="name" label="Name" value={name} onChange={this.handleSettingChange} />
                <SettingsInput field="website" label="Website" value={website} onChange={this.handleSettingChange} />
                <SettingsInput
                  field="phone"
                  label="Phone"
                  value={phone}
                  onChange={this.handleSettingChange}
                  type="phone"
                  autoComplete="off"
                />
              </form>
              {errorMessage}
              <div className="w-100 d-flex justify-content-center mt-4">
                <ProfitRoverPrimaryButton onClick={this.saveCompanySettings} disabled={!settingsChange}>
                  Save Changes
                </ProfitRoverPrimaryButton>
              </div>
            </ProfitRoverCard>
          </div>
        </SettingsPageWrapper>
      </HeaderAwarePage>
    );
  }
}

export default connect(null, {})(CompanySettings);
