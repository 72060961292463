import { useMutation } from 'react-query';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';

/**
 * Requests
 */

const registerShopifyMerchant = async (shop, nonce, email, password) =>
  axios.post(
    `${KA_API_ROOT_URL}/shopify/register`,
    { email, password },
    { params: { shopify_shop: shop, shopify_state: nonce } },
  );

/**
 * Hooks
 */

// eslint-disable-next-line import/prefer-default-export
export const useRegisterShopifyMerchant = mutationConfig =>
  useMutation(async ({ shop, nonce, email, password }) => {
    await registerShopifyMerchant(shop, nonce, email, password);
  }, mutationConfig);
