import React from 'react';
import classnames from 'classnames';
import { addDays, format, isSameDay } from 'date-fns';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import LineChart from './charts/LineChart';
import { StaffingToolbar } from './FiltersToolbar';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import {
  INCOME_METRIC,
  METRIC_TO_LABEL,
  INCOME_PER_EMPLOYEE_HOUR_METRIC,
  TIPS_PER_EMPLOYEE_HOUR_METRIC,
  TIPS_METRIC,
  TOTAL_INCOME_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  DAY_DETAILS_METRIC_TO_LABEL,
  TOTAL_HEADCOUNT_METRIC,
  ALL_METRICS,
  NON_TIPS_METRICS,
  ALL_DAY_DETAILS_METRICS,
  NON_TIPS_DAY_DETAILS_METRICS,
  STAFFING_ROLE_KEYS,
  TIPS_PER_EMPLOYEE_METRIC,
  SHIFTS_METRIC,
  HOURS_METRIC,
  FORECAST_DAY_DETAILS_METRICS,
  FORECAST_METRICS,
} from './staffingTabConstants';
import StaffingTabContext from './staffingTabContext';
import { HourBreakdownTable, RecommendedTable, ShiftPlannerTable } from './StaffingTabTables';
import {
  formatNumber,
  useHourlyStatistics,
  useHoursOfOperationByDay,
  usePeriodOptions,
  useRowsByDay,
  useShiftPlans,
  useShifts,
  useStatistics,
} from './staffingTabUtil';
import { formatAsDatasetDate, parseDatasetDate } from './timePeriod';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { formatNumber as simpleFormatNumber } from '../../../util/format';
import {
  gaEmitDateForecastTableClick,
  gaEmitDatePickerClick,
  gaEmitDateRangeDropdownClick,
  gaEmitHourBreakdownMetricClick,
  gaEmitLocationDropdownClick,
  gaEmitMetricDropdownClick,
} from '../../../../google-analytics/staffingTab';
import { FONT_BLACK, LIGHT_BLUE, LIGHT_GREEN } from '../../../../colors';
import './staffing-tab.scss';

const FULL_DATE_FORMAT = 'EEE MMM d, yyyy'; // Mon Jan 1, 2024

const formatMetricValue = (value, metric, currencySymbol) => {
  const rounded = Math.round(value ?? 0);

  if ([INCOME_METRIC, TIPS_METRIC, INCOME_PER_EMPLOYEE_HOUR_METRIC, TIPS_PER_EMPLOYEE_HOUR_METRIC].includes(metric)) {
    return `${currencySymbol}${simpleFormatNumber(rounded)}`;
  }

  return simpleFormatNumber(rounded);
};

const PlanVsRecommendedStatistics = ({ recommendedStatistics, planStatistics }) => {
  const { incomeMetricLabel } = React.useContext(DomainContext);
  const { hasForecastStats, currencySymbol } = React.useContext(DashboardContext);

  const formatMetric = (value, metric) => formatMetricValue(value, metric, currencySymbol);

  return (
    <div className="plan-vs-recommended-stats my-4">
      <div className="row header">
        <div className="col">Metric</div>
        <div className="col">Staffing Plan</div>
        <div className="col">RoverRecs</div>
      </div>
      <div className="row">
        <div className="col label">{incomeMetricLabel}</div>
        <div className="col double">{formatMetric(recommendedStatistics[INCOME_METRIC], INCOME_METRIC)}</div>
      </div>
      {hasForecastStats && (
        <div className="row">
          <div className="col label">{METRIC_TO_LABEL[TIPS_METRIC]}</div>
          <div className="col double">{formatMetric(recommendedStatistics[TIPS_METRIC], TIPS_METRIC)}</div>
        </div>
      )}
      <div className="row">
        <div className="col label">{METRIC_TO_LABEL[SHIFTS_METRIC]}</div>
        <div className="col">{formatMetric(planStatistics[SHIFTS_METRIC], SHIFTS_METRIC)}</div>
        <div className="col">{formatMetric(recommendedStatistics[SHIFTS_METRIC], SHIFTS_METRIC)}</div>
      </div>
      <div className="row">
        <div className="col label">{METRIC_TO_LABEL[INCOME_PER_EMPLOYEE_HOUR_METRIC]}</div>
        <div className="col">
          {formatMetric(planStatistics[INCOME_PER_EMPLOYEE_HOUR_METRIC], INCOME_PER_EMPLOYEE_HOUR_METRIC)}
        </div>
        <div className="col">
          {formatMetric(recommendedStatistics[INCOME_PER_EMPLOYEE_HOUR_METRIC], INCOME_PER_EMPLOYEE_HOUR_METRIC)}
        </div>
      </div>
      {hasForecastStats && (
        <div className="row">
          <div className="col label">{METRIC_TO_LABEL[TIPS_PER_EMPLOYEE_HOUR_METRIC]}</div>
          <div className="col">
            {formatMetric(planStatistics[TIPS_PER_EMPLOYEE_HOUR_METRIC], TIPS_PER_EMPLOYEE_HOUR_METRIC)}
          </div>
          <div className="col">
            {formatMetric(recommendedStatistics[TIPS_PER_EMPLOYEE_HOUR_METRIC], TIPS_PER_EMPLOYEE_HOUR_METRIC)}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col label">{METRIC_TO_LABEL[HOURS_METRIC]}</div>
        <div className="col">{formatMetric(planStatistics[HOURS_METRIC], HOURS_METRIC, currencySymbol)}</div>
        <div className="col">{formatMetric(recommendedStatistics[HOURS_METRIC], HOURS_METRIC, currencySymbol)}</div>
      </div>
    </div>
  );
};

const ClosedTooltip = () => {
  return (
    <ProfitRoverTooltip shouldDisplayTooltip placement="bottom" tooltipText="Closed" delay={{ show: 100, hide: 100 }}>
      <div className="col">
        <div className="value">-</div>
      </div>
    </ProfitRoverTooltip>
  );
};

const SummaryTable = ({ statisticsByDay, planStatisticsByDay }) => {
  const { incomeMetricLabel } = React.useContext(DomainContext);
  const { currencySymbol, hasForecastStats } = React.useContext(DashboardContext);
  const { selectedDate, setSelectedDate, dates } = React.useContext(StaffingTabContext);

  const metricOptions = React.useMemo(() => {
    const getMetricLabel = metric => {
      if (metric === INCOME_METRIC) {
        return incomeMetricLabel;
      }

      return METRIC_TO_LABEL[metric];
    };

    const metrics = hasForecastStats ? ALL_METRICS : NON_TIPS_METRICS;

    return metrics.map(metric => ({
      label: getMetricLabel(metric),
      value: metric,
    }));
  }, [incomeMetricLabel, hasForecastStats]);

  const [selectedMetric, setSelectedMetric] = React.useState(metricOptions[0]);

  const metricsArray = React.useMemo(() => {
    return dates.map(({ date, isClosed }) => {
      if (isClosed) {
        return {
          date: parseDatasetDate(date),
          isClosed,
        };
      }

      const metrics = statisticsByDay[date] || {};
      const planMetrics = planStatisticsByDay[date] || {};

      return {
        date: parseDatasetDate(date),
        recommended: metrics[selectedMetric.value],
        plan: planMetrics[selectedMetric.value],
        isClosed: false,
      };
    });
  }, [statisticsByDay, planStatisticsByDay, selectedMetric, dates]);

  const formatMetric = value => formatMetricValue(value, selectedMetric.value, currencySymbol);

  const onMetricChange = metric => {
    gaEmitMetricDropdownClick();
    setSelectedMetric(metric);
  };

  return (
    <>
      <div className="d-flex flex-column summary-filters">
        <div className="d-flex justify-content-between align-items-center">
          <div>Metric:</div>
          <div style={{ flexGrow: 1, minWidth: 100, maxWidth: 150 }}>
            <RevenueUpliftSelect
              className="label metric-select"
              options={metricOptions}
              onChange={onMetricChange}
              value={selectedMetric}
              placeholder="Select Metric"
            />
          </div>
        </div>
      </div>
      <div className="table-container">
        <div className="table">
          <div className="row header">
            <div className="col date-col">
              <div className="label">Date</div>
            </div>
            {FORECAST_METRICS.includes(selectedMetric.value) ? (
              <>
                <div className="divider" />
                <div className="col">
                  <div className="label">Forecast</div>
                </div>
              </>
            ) : (
              <>
                <div className="divider" />
                <div className="col">
                  <div className="label">Staffing Plan</div>
                </div>
                <div className="divider" />
                <div className="col">
                  <div className="label">RoverRecs</div>
                </div>
              </>
            )}
          </div>
          {metricsArray.map(({ date, recommended, plan, isClosed }) => (
            <>
              {isClosed ? (
                <button
                  className={classnames('row', { active: isSameDay(selectedDate, date) })}
                  key={formatAsDatasetDate(date)}
                  type="button"
                  disabled
                >
                  <div className="col date-col">
                    <div className="value">{format(date, 'EEE MMM d')}</div>
                  </div>
                  {FORECAST_METRICS.includes(selectedMetric.value) ? (
                    <>
                      <div className="divider" />
                      <ClosedTooltip />
                    </>
                  ) : (
                    <>
                      <div className="divider" />
                      <ClosedTooltip />
                      <div className="divider" />
                      <ClosedTooltip />
                    </>
                  )}
                </button>
              ) : (
                <button
                  className={classnames('row', { active: isSameDay(selectedDate, date) })}
                  key={formatAsDatasetDate(date)}
                  onClick={() => {
                    gaEmitDateForecastTableClick();
                    setSelectedDate(date);
                  }}
                  type="button"
                >
                  <div className="col date-col">
                    <div className="value">{format(date, 'EEE MMM d')}</div>
                  </div>
                  {FORECAST_METRICS.includes(selectedMetric.value) ? (
                    <>
                      <div className="divider" />
                      <div className="col">
                        <div className="value">{formatMetric(recommended)}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="divider" />
                      <div className="col">
                        <div className="value">{formatMetric(plan)}</div>
                      </div>
                      <div className="divider" />
                      <div className="col">
                        <div className="value">{formatMetric(recommended)}</div>
                      </div>
                    </>
                  )}
                </button>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

const PeriodSummary = () => {
  const {
    selectedPeriod,
    setSelectedPeriod,
    periodOptions,
    periodStatistics,
    planPeriodStatistics,
    statisticsByDay,
    planStatisticsByDay,
  } = React.useContext(StaffingTabContext);

  const onPeriodChange = period => {
    gaEmitDateRangeDropdownClick();
    setSelectedPeriod(period);
  };

  return (
    <div className="period-summary">
      <h1 className="summary-title">Summary</h1>
      <div className="d-flex flex-column mt-2 summary-filters">
        <div className="d-flex justify-content-between align-items-center">
          <div>Date Range:</div>
          <div style={{ flexGrow: 1, minWidth: 100, maxWidth: 150 }}>
            <RevenueUpliftSelect
              value={selectedPeriod}
              options={periodOptions}
              onChange={onPeriodChange}
              placeholder="Select Range"
            />
          </div>
        </div>
      </div>
      <PlanVsRecommendedStatistics recommendedStatistics={periodStatistics} planStatistics={planPeriodStatistics} />
      <SummaryTable statisticsByDay={statisticsByDay} planStatisticsByDay={planStatisticsByDay} />
    </div>
  );
};

const chartNumberFormatter = (value, selectedMetric, currencySymbol) => {
  if (selectedMetric === TOTAL_HEADCOUNT_METRIC) {
    return formatNumber(value);
  }

  return formatNumber(value, { currencySymbol });
};

const HourBreakdown = ({ isActive }) => {
  const { incomeMetricLabel } = React.useContext(DomainContext);
  const { currencySymbol, hasForecastStats, locationsApi, staffingRolesApi } = React.useContext(DashboardContext);
  const { selectedDate, hoursRange, hourlyStatistics, hourlyPlanStatistics } = React.useContext(StaffingTabContext);
  const { selectedLocation } = locationsApi;
  const {
    currentStaffingRole,
    // undoLastChange,
    // canUndoLastChange,
    // isLoadingStaffingRolesUpdate
  } = staffingRolesApi;

  const [selectedMetric, setSelectedMetric] = React.useState(TOTAL_INCOME_METRIC);

  const plansByMetric = React.useMemo(() => {
    const plans = {};

    ALL_DAY_DETAILS_METRICS.forEach(metric => {
      if (FORECAST_DAY_DETAILS_METRICS.includes(metric)) {
        plans[metric] = [
          {
            label: 'Forecast',
            color: LIGHT_GREEN,
            ...hourlyStatistics[metric],
          },
        ];
      } else {
        plans[metric] = [
          {
            label: 'Staffing Plan',
            color: LIGHT_BLUE,
            ...hourlyPlanStatistics[metric],
          },
          {
            label: 'RoverRecs',
            color: LIGHT_GREEN,
            ...hourlyStatistics[metric],
          },
        ];
      }
    });

    return plans;
  }, [hourlyStatistics, hourlyPlanStatistics]);

  const plansData = React.useMemo(() => plansByMetric[selectedMetric], [plansByMetric, selectedMetric]);

  const getMetricLabel = React.useCallback(
    metric => {
      if (metric === TOTAL_INCOME_METRIC) {
        return `Total ${incomeMetricLabel}`;
      }

      if (metric === TOTAL_INCOME_PER_EMPLOYEE_METRIC) {
        return `Total ${incomeMetricLabel} Per Employee`;
      }

      return DAY_DETAILS_METRIC_TO_LABEL[metric];
    },
    [incomeMetricLabel],
  );

  const [chartData, legendData] = React.useMemo(() => {
    const getTargetMax = () => {
      const targetMax = {
        label: 'Target Max',
        color: FONT_BLACK,
        dashed: true,
      };

      if (selectedMetric === TOTAL_INCOME_PER_EMPLOYEE_METRIC) {
        return [
          {
            ...targetMax,
            data: hoursRange.map((h, i) => ({
              x: i,
              y: currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE],
            })),
          },
        ];
      }

      if (selectedMetric === TIPS_PER_EMPLOYEE_METRIC) {
        return [
          {
            ...targetMax,
            data: hoursRange.map((h, i) => ({
              x: i,
              y: currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE],
            })),
          },
        ];
      }

      return [];
    };

    const getTargetMin = () => {
      const targetMin = {
        label: 'Target Min',
        color: FONT_BLACK,
        dashed: true,
      };

      if (selectedMetric === TOTAL_INCOME_PER_EMPLOYEE_METRIC) {
        return [
          {
            ...targetMin,
            data: hoursRange.map((h, i) => ({
              x: i,
              y: currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE],
            })),
          },
        ];
      }

      if (selectedMetric === TIPS_PER_EMPLOYEE_METRIC) {
        return [
          {
            ...targetMin,
            data: hoursRange.map((h, i) => ({
              x: i,
              y: currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE],
            })),
          },
        ];
      }

      if (selectedMetric === TOTAL_HEADCOUNT_METRIC) {
        return [
          {
            ...targetMin,
            data: hoursRange.map((h, i) => {
              const y =
                i === hoursRange.length - 1
                  ? currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE]
                  : currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY];

              return {
                x: i,
                y,
              };
            }),
          },
        ];
      }

      return [];
    };

    const plans = plansData.map(plan => {
      const { label, color, ...hours } = plan;

      return {
        label,
        color,
        data: hoursRange.map((hour, i) => ({
          x: i,
          y: hours[hour] ?? 0,
        })),
      };
    });

    const targetMax = getTargetMax();
    const targetMin = getTargetMin();
    const chart = [...targetMax, ...plans, ...targetMin];
    const targetLegend =
      targetMax.length > 0 || targetMin.length > 0
        ? [{ label: 'Target Min/Max', color: FONT_BLACK, dashed: true }]
        : [];
    const legend = [...targetLegend, ...plans];

    return [chart, legend];
  }, [plansData, hoursRange, selectedMetric, currentStaffingRole]);

  const metricOptions = hasForecastStats ? ALL_DAY_DETAILS_METRICS : NON_TIPS_DAY_DETAILS_METRICS;

  const onMetricSelected = metric => {
    gaEmitHourBreakdownMetricClick(metric);
    setSelectedMetric(metric);
  };

  return (
    <div className="hour-breakdown">
      <div className="d-flex w-100 justify-content-between align-items-start">
        {/* {canUndoLastChange && <div className="button-container" />} */}
        <h1 className="summary-title text-center" style={{ flex: 1 }}>
          {selectedLocation.label}: {format(selectedDate, 'MMM d, yyyy')}
        </h1>
        {/* Unused because it's not obvious that it sets the roles back to the previous state
        but kept in case we want to add it back in the future */}
        {/* {canUndoLastChange && (
          <div className="button-container">
            <button
              type="button"
              className="undo-button"
              disabled={isLoadingStaffingRolesUpdate}
              onClick={undoLastChange}
            >
              <FontAwesomeIcon icon={faUndo} style={{ fontSize: '0.8rem', marginRight: 5 }} color={FONT_BLACK} />
              <p className="text">Undo</p>
            </button>
          </div>
        )} */}
      </div>
      <div className="metrics-container my-2">
        {metricOptions.map(metric => (
          <button
            className={classnames('metric-button', { active: metric === selectedMetric })}
            key={metric}
            onClick={() => onMetricSelected(metric)}
            type="button"
          >
            {getMetricLabel(metric)}
          </button>
        ))}
      </div>
      <div className="viz-container">
        <LineChart
          data={chartData}
          numberFormatter={value => chartNumberFormatter(value, selectedMetric, currencySymbol)}
        />
      </div>
      <div className="chart-legend">
        {legendData.map(({ label, color, dashed }) => (
          <div key={label} className="item">
            <div className="color" style={{ borderBottom: `3px ${dashed ? 'dashed' : 'solid'} ${color}` }} />
            <div className="label">{label}</div>
          </div>
        ))}
      </div>
      <HourBreakdownTable
        plansData={plansData}
        selectedMetric={selectedMetric}
        selectedMetricLabel={getMetricLabel(selectedMetric)}
        isActive={isActive}
      />
    </div>
  );
};

const ShiftsTable = ({ isActive }) => {
  return (
    <div className="shifts-details">
      <ShiftPlannerTable isActive={isActive} />
      <RecommendedTable isActive={isActive} />
    </div>
  );
};

const DaySummary = ({ isActive }) => {
  const {
    selectedDate,
    setSelectedDate,
    startAndEndDates,
    dayStatistics,
    dayPlanStatisctics,
    dates,
  } = React.useContext(StaffingTabContext);

  const selectPreviousDay = () => {
    gaEmitDatePickerClick();
    const previousDay = addDays(selectedDate, -1);
    setSelectedDate(previousDay);
  };

  const selectNextDay = () => {
    gaEmitDatePickerClick();
    const nextDay = addDays(selectedDate, 1);
    setSelectedDate(nextDay);
  };

  const onDateChange = date => {
    gaEmitDatePickerClick();
    setSelectedDate(date);
  };

  const excludeDates = React.useMemo(() => {
    return dates.filter(({ isClosed }) => isClosed).map(({ date }) => parseDatasetDate(date));
  }, [dates]);

  return (
    <div className="day-summary">
      <div className="day-stats-container">
        <div className="day-stats">
          <h1 className="summary-title">Planner</h1>
          <div className="d-flex w-100 flex-column mt-2 summary-filters">
            <div className="d-flex align-items-center">
              <div className="mr-4">Date:</div>
              <div className="d-flex align-items-center date-picker-container">
                <button
                  type="button"
                  className="arrow-button mr-1"
                  disabled={selectedDate <= startAndEndDates[0]}
                  onClick={selectPreviousDay}
                >
                  <FontAwesomeIcon icon={faAngleLeft} color={FONT_BLACK} />
                </button>
                <div className="date-picker">
                  <DatePicker
                    selected={selectedDate}
                    onChange={onDateChange}
                    minDate={startAndEndDates[0]}
                    maxDate={startAndEndDates[1]}
                    excludeDates={excludeDates}
                    dateFormat={FULL_DATE_FORMAT}
                  />
                </div>
                <button
                  type="button"
                  className="arrow-button ml-1"
                  disabled={selectedDate >= startAndEndDates[1]}
                  onClick={selectNextDay}
                >
                  <FontAwesomeIcon icon={faAngleRight} color={FONT_BLACK} />
                </button>
              </div>
            </div>
          </div>
          <PlanVsRecommendedStatistics recommendedStatistics={dayStatistics} planStatistics={dayPlanStatisctics} />
        </div>
        <HourBreakdown isActive={isActive} />
      </div>
      <ShiftsTable isActive={isActive} />
    </div>
  );
};

const StaffingTab = ({ isActive }) => {
  const { staffingRolesApi, locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation, setSelectedLocation, locationOptions } = locationsApi;
  const { isLoadingStaffingRoles } = staffingRolesApi;

  if (isLoadingStaffingRoles) {
    return (
      <div className="staffing-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  const onLocationChange = location => {
    gaEmitLocationDropdownClick();
    setSelectedLocation(location);
  };

  return (
    <div className="staffing-tab">
      <div className="my-2">
        <StaffingToolbar
          locationOptions={locationOptions}
          selectedLocation={selectedLocation}
          setSelectedLocation={onLocationChange}
        />
      </div>
      <ProfitRoverCard className="staffing-card">
        <div className="staffing-card-content">
          <PeriodSummary />
          <div className="divider" />
          <DaySummary isActive={isActive} />
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const StaffingTabContextController = ({ children }) => {
  const { locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation } = locationsApi;
  const { getHoursOfOperationByDate, hasHoursOfOperationDefined } = useHoursOfOperationByDay(selectedLocation.value);
  const periodsApi = usePeriodOptions(getHoursOfOperationByDate, selectedLocation);
  const { startAndEndDates, selectedDate, dates } = periodsApi;
  const { rowsByDay, rowsByDayAndHour, gratuityRowsByDay } = useRowsByDay(startAndEndDates, selectedLocation.value);
  const shiftsByDay = useShifts(
    rowsByDayAndHour,
    gratuityRowsByDay,
    getHoursOfOperationByDate,
    hasHoursOfOperationDefined,
  );

  const dayRows = React.useMemo(() => {
    return rowsByDay[formatAsDatasetDate(selectedDate)] || [];
  }, [selectedDate, rowsByDay]);

  const dayGratuityRows = React.useMemo(() => {
    return gratuityRowsByDay[formatAsDatasetDate(selectedDate)] || [];
  }, [selectedDate, gratuityRowsByDay]);

  const [dayShifts, hoursRange] = React.useMemo(() => {
    const { shifts: currentShifts, hoursRange: currentHoursRange } =
      shiftsByDay[formatAsDatasetDate(selectedDate)] ?? {};
    return [currentShifts ?? [], currentHoursRange ?? []];
  }, [selectedDate, shiftsByDay]);

  const hourlyStatistics = useHourlyStatistics(dayRows, dayGratuityRows, dayShifts, hoursRange, {
    includeForecast: true,
  });

  const shiftPlansApi = useShiftPlans(selectedDate, shiftsByDay, dayShifts, hoursRange, hourlyStatistics);
  const { shiftPlansByDay } = shiftPlansApi;
  const statistics = useStatistics(
    rowsByDay,
    gratuityRowsByDay,
    shiftsByDay,
    shiftPlansByDay,
    getHoursOfOperationByDate,
    dates,
  );
  const { statisticsByDay, planStatisticsByDay } = statistics;

  const dayStatistics = React.useMemo(() => {
    return statisticsByDay[formatAsDatasetDate(selectedDate)] || {};
  }, [selectedDate, statisticsByDay]);

  const dayPlanStatisctics = React.useMemo(() => {
    return planStatisticsByDay[formatAsDatasetDate(selectedDate)] || {};
  }, [selectedDate, planStatisticsByDay]);

  const contextValue = {
    ...periodsApi,
    ...statistics,
    dayStatistics,
    dayPlanStatisctics,
    hourlyStatistics,
    rowsByDay,
    gratuityRowsByDay,
    dayRows,
    dayGratuityRows,
    hoursRange,
    dayShifts,
    ...shiftPlansApi,
  };

  return <StaffingTabContext.Provider value={contextValue}>{children}</StaffingTabContext.Provider>;
};

const StaffingTabContainer = ({ isActive }) => {
  return (
    <StaffingTabContextController>
      <StaffingTab isActive={isActive} />
    </StaffingTabContextController>
  );
};

export default StaffingTabContainer;
