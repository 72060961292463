import axios from 'axios';
import React from 'react';
import { ContactMethod, PreferredTime } from './HelpConstants';
import {
  ActionButton,
  PageHeader,
  PromptText,
  RadioButtonRow,
  SmallRadioButton,
  TextArea,
  TextInput,
} from '../forms/GenericFormComponents';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { KA_API_URL } from '../../config/baseUrl';
import { isValidEmailAddress, isValidPhoneNumber } from '../../utils/validators';
import './help.scss';

const sendRequest = ({ requestText }) =>
  axios.post(`${KA_API_URL}/email?request=help`, {
    request_text: requestText,
  });

const Help = props => {
  const { location } = props;
  const { message = '' } = location?.state ?? {};

  const [followup, setFollowup] = React.useState(true);
  const followupRequested = followup === true;

  const [contactMethod, setContactMethod] = React.useState(ContactMethod.EMAIL);
  const [emailAddress, setEmailAddress] = React.useState(localStorage.getItem('email'));
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [preferredTime, setPreferredTime] = React.useState();
  const [requestText, setRequestText] = React.useState(message);

  const [userId] = React.useState(localStorage.getItem('id'));
  const [customerId] = React.useState(localStorage.getItem('customer_id'));
  const [emailSent, setEmailSent] = React.useState();

  const clearHelpState = () => {
    setFollowup(true);
    setContactMethod(ContactMethod.EMAIL);
    setEmailAddress(localStorage.getItem('email'));
    setPhoneNumber('');
    setPreferredTime();
    setRequestText('');
  };

  const canSubmit =
    (followupRequested &&
      (contactMethod === ContactMethod.PHONE ? isValidPhoneNumber(phoneNumber) : isValidEmailAddress(emailAddress)) &&
      requestText) ||
    (!followupRequested && requestText);

  const onClickSubmit = async () => {
    let contactInfo;
    if (followupRequested && contactMethod === ContactMethod.EMAIL && emailAddress) {
      contactInfo = emailAddress;
    } else if (followupRequested && contactMethod === ContactMethod.PHONE && phoneNumber) {
      contactInfo = phoneNumber;
    }

    let fullRequestText = `${requestText}\n\nCustomer ID ${customerId}, User ID ${userId}`;

    if (contactInfo) {
      fullRequestText += `\nContact: ${contactInfo}`;
    }

    if (preferredTime) {
      fullRequestText += `\nPreferred contact time: ${preferredTime}`;
    }

    await sendRequest({
      requestText: fullRequestText,
    });

    setEmailSent({ followup });
    clearHelpState();
  };

  return (
    <HeaderAwarePage className="eo-help-container">
      <Header />

      <PageHeader>Request help or send feedback</PageHeader>

      <PromptText>Would you like to be contacted by our team?</PromptText>
      <RadioButtonRow className="eo-help-radio-row">
        <SmallRadioButton selected={followup === true} onClick={() => setFollowup(true)}>
          Yes
        </SmallRadioButton>
        <SmallRadioButton selected={followup === false} onClick={() => setFollowup(false)}>
          No
        </SmallRadioButton>
      </RadioButtonRow>

      {followupRequested && (
        <>
          <PromptText>Preferred contact method:</PromptText>
          <RadioButtonRow className="eo-help-radio-row">
            <SmallRadioButton
              selected={contactMethod === ContactMethod.EMAIL}
              onClick={() => setContactMethod(ContactMethod.EMAIL)}
            >
              Email
            </SmallRadioButton>
            <SmallRadioButton
              selected={contactMethod === ContactMethod.PHONE}
              onClick={() => setContactMethod(ContactMethod.PHONE)}
            >
              Phone
            </SmallRadioButton>
          </RadioButtonRow>
        </>
      )}

      {followupRequested && contactMethod === ContactMethod.EMAIL && (
        <>
          <PromptText>Confirm your email address:</PromptText>
          <TextInput
            className="eo-help-text-input"
            onChange={e => setEmailAddress(e.target.value)}
            placeholder="alice@example.com"
            value={emailAddress}
          />
        </>
      )}

      {followupRequested && contactMethod === ContactMethod.PHONE && (
        <>
          <PromptText>Confirm your phone number:</PromptText>
          <TextInput
            className="eo-help-text-input"
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder="404-555-0123"
            value={phoneNumber}
          />

          <PromptText>Preferred contact time:</PromptText>
          <RadioButtonRow className="eo-help-radio-row">
            <SmallRadioButton
              selected={preferredTime === PreferredTime.NONE}
              onClick={() => setPreferredTime(PreferredTime.NONE)}
            >
              No Preference
            </SmallRadioButton>
            <SmallRadioButton
              selected={preferredTime === PreferredTime.MORNING}
              onClick={() => setPreferredTime(PreferredTime.MORNING)}
            >
              Morning
            </SmallRadioButton>
            <SmallRadioButton
              selected={preferredTime === PreferredTime.AFTERNOON}
              onClick={() => setPreferredTime(PreferredTime.AFTERNOON)}
            >
              Afternoon
            </SmallRadioButton>
            <SmallRadioButton
              selected={preferredTime === PreferredTime.EVENING}
              onClick={() => setPreferredTime(PreferredTime.EVENING)}
            >
              Evening
            </SmallRadioButton>
          </RadioButtonRow>
        </>
      )}

      <PromptText>Please enter your help request or feedback:</PromptText>
      <TextArea className="eo-help-text-area" onChange={e => setRequestText(e.target.value)} value={requestText} />

      <ActionButton disabled={!canSubmit} onClick={onClickSubmit}>
        Submit
      </ActionButton>

      {emailSent && <PromptText>Thank you for your message.</PromptText>}

      {emailSent && emailSent.followup && <PromptText>We will contact you within one business day.</PromptText>}
    </HeaderAwarePage>
  );
};

export default Help;
