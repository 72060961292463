export const Industry = {
  CFB: 'CFB',
  EO: 'EO',
  MEDIA: 'MEDIA',
  MOVIE: 'MOVIE',
  ANY: 'ANY',
};

export const DataType = {
  ADMIT: 'ADMIT',
  ADVTRAN: 'ADVTRAN',
  REVENUE: 'REVENUE',
  TICKET: 'TICKET',
  TRAN: 'TRAN',
  GRATUITY: 'GRATUITY',
};

export const DataSourceType = {
  FORMULA: 'FORMULA',
  FIXED_VALUE: 'FIXED_VALUE',
  FROM_COLUMN: 'FROM_COLUMN',
};

export const DataSourceFields = {
  LOCATION: 'Location',
  TRANSACTION_DATE: 'Transaction Date',
  VISIT_DATE: 'Visit Date',
  ITEM_QUANTITY: 'Item Quantity',
  PRICE: 'Price',
  ITEM_DESCRIPTORS: 'Item Descriptor(s)',
};

// extracts for brevity in the definitions to follow
const {
  LOCATION: location,
  TRANSACTION_DATE: transactionDate,
  VISIT_DATE: visitDate,
  ITEM_QUANTITY: itemQuantity,
  PRICE: price,
  ITEM_DESCRIPTORS: itemDescriptors,
} = DataSourceFields;

export const INDUSTRY_AND_DATA_TYPE_TO_FIELDS = {
  [Industry.EO + DataType.TRAN]: [location, transactionDate, itemQuantity, price, itemDescriptors],
  [Industry.EO + DataType.ADVTRAN]: [location, transactionDate, visitDate, itemQuantity, price, itemDescriptors],
};

const dataSourceTypeErrorMessage = dataSourceType =>
  `Developer error: Encountered unknown dataSourceType: ${dataSourceType}. This should never occur in production code.`;

export const unknownDataSourceTypeError = dataSourceType => new Error(dataSourceTypeErrorMessage(dataSourceType));
