import _ from 'lodash';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { isManualDatasetConfig } from './dataSourceConstants';
import DataSourceRow, { LoadingDataSource } from './DataSourceRow';
import { PageHeader, PageSubHeader } from '../forms/GenericFormComponents';
import { ProfitRoverAddButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { KaizenProduct } from '../generic/subscriptions/SubscriptionConstants';
import Header from '../header/header';
import { useSubscriptionStatusForProduct } from '../routing/subscriptionStatusHooks';
import { useNewDataSourceNaicsData } from '../setup/modern/pointOfSaleSetupHooks';
import { useDatasetConfigs } from '../../data-access/query/datasetConfigs';
import { useDatasets } from '../../data-access/query/datasets';
import './data-sources.scss';

const DataSourceList = ({ datasets, datasetConfigs, isLoadingDatasetConfigs }) => {
  const datasetsByConfigId = _.groupBy(datasets, dataset => dataset.dataset_config_id);

  return (
    <div className="mt-4">
      <PageSubHeader>Your Data Sources</PageSubHeader>

      {isLoadingDatasetConfigs ? (
        <LoadingDataSource />
      ) : (
        datasetConfigs.map(config => (
          <DataSourceRow
            key={config.dataset_config_id}
            datasetConfig={config}
            datasets={datasetsByConfigId[config.dataset_config_id] ?? []}
          />
        ))
      )}
    </div>
  );
};

const AddNewDataSourceButton = () => {
  const history = useHistory();
  const { naicsCode, kaCode } = useNewDataSourceNaicsData();

  const navigateToCreateNewDataSource = () => history.push('/data-sources/add-new');

  return (
    <ProfitRoverAddButton
      className="mt-3"
      disabled={naicsCode == null || kaCode == null}
      onClick={navigateToCreateNewDataSource}
    >
      Add New Data Source
    </ProfitRoverAddButton>
  );
};

const DataSources = () => {
  const { isShopifySubscription, isFetching: isFetchingSubscription } = useSubscriptionStatusForProduct(
    KaizenProduct.KP,
  );
  const { data: datasetConfigs = [], isLoading: isLoadingDatasetConfigs } = useDatasetConfigs();
  const { data: datasets = [], isLoading: isLoadingDatasets } = useDatasets();

  const allManual = datasetConfigs.every(config => isManualDatasetConfig(config, datasets));
  const isLoading = isLoadingDatasetConfigs || isLoadingDatasets || isFetchingSubscription;

  return (
    <HeaderAwarePage className="data-sources pb-5">
      <Header />
      <main className="container">
        <PageHeader>Data Center</PageHeader>
        <DataSourceList
          datasetConfigs={datasetConfigs}
          isLoadingDatasetConfigs={isLoadingDatasetConfigs}
          datasets={datasets}
        />

        {!isLoading && !isShopifySubscription && allManual && <AddNewDataSourceButton />}
        {!isLoading && !allManual && (
          <div className="help-text-container">
            <span className="help-text">
              Need to add another data source? <Link to="/help">Contact Us</Link>
            </span>
          </div>
        )}
      </main>
    </HeaderAwarePage>
  );
};

export default DataSources;
