import { Industry } from '../data-center/datasetConfig/DatasetConfigFormConstants';

export const ObjectiveOptions = {
  [Industry.CFB]: [{ label: 'Optimize Pricing', value: 'PRICEOPT' }],
  [Industry.EO]: [
    // Since there is no Forecast Attendance Dashboard, removing Forecast Attendance / KP-2400
    // { label: 'Forecast Attendance', value: 'ATTENDFORECAST' },
    { label: 'Optimize Pricing', value: 'PRICEOPT' },
  ],
  [Industry.MEDIA]: [{ label: 'Price Elasticity', value: 'PRICEELASTICITY' }],
};

export const Objective = {
  ATTENDFORECAST: 'ATTENDFORECAST',
  MARKSEGMENTATION: 'MARKSEGMENTATION',
  PRICEELASTICITY: 'PRICEELASTICITY',
  PRICEOPT: 'PRICEOPT',
  REVFORECAST: 'REVFORECAST',
  VOLFORECAST: 'VOLFORECAST',
  TIPFORECAST: 'TIPFORECAST',
  ANOMALY: 'ANOMALY',
};

export const WorkflowDescription = {
  ATTENDFORECAST: 'Attendance Forecast',
  MARKSEGMENTATION: 'Segmentation',
  PRICEELASTICITY: 'Price Elasticity',
  PRICEOPT: 'Pricing Recommendations',
  REVFORECAST: 'Revenue Forecast',
  VOLFORECAST: 'Volume Forecast',
  TIPFORECAST: 'Tip Forecast',
  ANOMALY: 'Anomaly Detection',
};

export const PriceChangeFrequency = {
  DAY: 'DAY',
  DOW: 'DOW',
  WEEKPART: 'WEEKPART',
  SEASON: 'SEASON',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
  STATIC: 'STATIC',
  WEEKS_SINCE_RELEASE: 'WEEKS_SINCE_RELEASE',
};

export const Output = {
  FORECAST_STATS: 'FORECAST_STATS',
};

/**
 * The API coerces an empty Array to the correct database representation for PriceChangeFrequency.STATIC
 */
export const API_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY = Object.freeze([]);
export const FORM_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY = Object.freeze([PriceChangeFrequency.STATIC]);

export const PriceChangeFrequencyLabels = {
  [PriceChangeFrequency.STATIC]: 'One Time',
  [PriceChangeFrequency.DAY]: 'Daily',
  [PriceChangeFrequency.DOW]: 'Day of Week',
  [PriceChangeFrequency.MONTH]: 'Monthly',
  [PriceChangeFrequency.QUARTER]: 'Quarterly',
  [PriceChangeFrequency.YEAR]: 'Yearly',
};

// map valid price change frequency combinations
/*
 * TODO this data structure and the function that uses it will need some refactoring for KP-3355; the initial design is
 * already starting to grow some warts due to expanding requirements
 */
export const PriceChangeFrequencyCombos = {
  [PriceChangeFrequency.DAY]: [],
  [PriceChangeFrequency.DOW]: [PriceChangeFrequency.MONTH, PriceChangeFrequency.QUARTER, PriceChangeFrequency.YEAR],
  [PriceChangeFrequency.MONTH]: [PriceChangeFrequency.DOW],
  [PriceChangeFrequency.QUARTER]: [PriceChangeFrequency.DOW],
  [PriceChangeFrequency.YEAR]: [PriceChangeFrequency.DOW, PriceChangeFrequency.MONTH, PriceChangeFrequency.QUARTER],
  [PriceChangeFrequency.STATIC]: [],
};

export const HOUR_DIMENSION_TYPE = 'HOUR';
export const DAYPART_DIMENSION_TYPE = 'DAYPART';
export const USER_DIMENSION_TYPE = 'USER';

export const ItemClass = {
  CONC: 'Food & Beverage',
  EXP: 'Experiences',
  EXP_AP: 'Advanced Purchase Experiences',
  MERCH: 'Merchandise',
  PARK: 'Parking',
  PARK_AP: 'Advanced Purchase Parking',
  SERV: 'Services',
  SERV_AP: 'Advanced Purchase Services',
  TICKET: 'Tickets',
  TICKET_AP: 'Advance Purchase Tickets',
  OTHER: 'Other',
};
