import _ from 'lodash';
import classnames from 'classnames';
import { faChevronLeft, faChevronRight, faFilter, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useMedia, useScroll, useUpdate } from 'react-use';
import { getOrderByCriteria } from './filtersUtil';
import { DashboardContext } from './revenueUpliftContexts';
import { TABS } from './revenueUpliftDashboardTabs';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import RevenueUpliftTimePeriodPicker from './TimePeriodPicker';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { FONT_BLACK, FONT_GRAY } from '../../../../colors';
import { computeTextWidth } from '../../../../utils/textTruncation';
import {
  gaEmitCompareToDropdownClick,
  gaEmitHoursOfOperationDropdownClick,
  gaEmitLocationDropdownClick,
} from '../../../../google-analytics/hoursTab';
import { ReactComponent as XIcon } from '../../../../images/x-icon.svg';

const SettingLabel = ({ label, style }) => (
  <div className="setting-label" style={style}>
    {label}
  </div>
);

export const TimeAndBaselineToolbar = () => {
  const { baselineComparison } = React.useContext(DashboardContext);
  const { newPrices, baseline } = baselineComparison;

  return (
    <div className="d-flex align-items-center flex-wrap" style={{ flexGrow: 1, gap: '12px' }}>
      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="Show Me Impacts for:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftTimePeriodPicker />
        </div>
      </div>

      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="New Prices Based On:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect value={newPrices} options={[newPrices]} placeholder="Select Scenario" isDisabled />
        </div>
        <div className="d-flex align-items-center">
          <SettingLabel label="vs" />
        </div>
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect value={baseline} options={[baseline]} placeholder="Select Scenario" isDisabled />
        </div>
      </div>
    </div>
  );
};

export const StaffingToolbar = ({ locationOptions, selectedLocation, setSelectedLocation }) => {
  return (
    <div className="d-flex align-items-center flex-wrap" style={{ flexGrow: 1, gap: '12px' }}>
      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="Location:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect
            value={selectedLocation}
            options={locationOptions}
            onChange={setSelectedLocation}
            placeholder="Select Location"
          />
        </div>
      </div>
    </div>
  );
};

export const HoursToolbar = ({
  locationOptions,
  selectedLocation,
  setSelectedLocation,
  periodOptions,
  selectedPeriod,
  setSelectedPeriod,
  comparisonOptions,
  selectedComparison,
  setSelectedComparison,
}) => {
  const onLocationChange = location => {
    gaEmitLocationDropdownClick();
    setSelectedLocation(location);
  };

  const onPeriodChange = period => {
    gaEmitHoursOfOperationDropdownClick();
    setSelectedPeriod(period);
  };

  const onComparisonChange = comparison => {
    gaEmitCompareToDropdownClick();
    setSelectedComparison(comparison);
  };

  return (
    <div className="d-flex align-items-center flex-wrap" style={{ flexGrow: 1, gap: '12px' }}>
      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="Location:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect
            value={selectedLocation}
            options={locationOptions}
            onChange={onLocationChange}
            placeholder="Select Location"
          />
        </div>
      </div>
      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="Set Hours of Operation for:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect
            value={selectedPeriod}
            options={periodOptions}
            onChange={onPeriodChange}
            placeholder="Select Period"
          />
        </div>
      </div>
      <div className="d-flex align-items-center selectors-container">
        <SettingLabel label="Compare To:" />
        <div style={{ flexGrow: 1, minWidth: 180, maxWidth: 200 }}>
          <RevenueUpliftSelect
            value={selectedComparison}
            options={comparisonOptions}
            onChange={onComparisonChange}
            placeholder="Select Comparison"
          />
        </div>
      </div>
    </div>
  );
};

const TOOLTIP_TEXT_FONT_SIZE_PX = 14;

/**
 * These are hand-picked values that can and should be tweaked if any styling changes
 * related to the tooltip occur in the future.
 */
const TEXT_LENGTH_THRESHOLD = 500;
const TEXT_LENGTH_BACKOFF_THRESHOLD = 550;

/**
 * Attempts to produce tooltip text that is no wider than 660px on a single line
 */
const useTooltipText = selectedValues => {
  const numSelected = selectedValues.length;
  if (numSelected < 2) {
    return ''; // Performance optimization since, in this case, no tooltip is rendered anyway
  }

  const dimValLabels = selectedValues.map(({ label: dimValLabel }) => dimValLabel);

  let lastLabelAdded = '';
  let labelIndex = 0;
  let tooltipText = '';

  // Add labels one at a time until the text becomes too long or we run out of labels
  do {
    lastLabelAdded = dimValLabels[labelIndex];
    tooltipText += `${lastLabelAdded}, `;
    labelIndex += 1;
  } while (
    computeTextWidth(tooltipText, TOOLTIP_TEXT_FONT_SIZE_PX) < TEXT_LENGTH_THRESHOLD &&
    labelIndex < numSelected
  );
  tooltipText = tooltipText.slice(0, tooltipText.length - 2); // Cut off trailing comma and space

  const reachedWidth = computeTextWidth(tooltipText, TOOLTIP_TEXT_FONT_SIZE_PX);
  if (reachedWidth > TEXT_LENGTH_BACKOFF_THRESHOLD) {
    // If the last label added made the text too long, walk back adding that label to make room for the truncation text
    tooltipText = tooltipText.slice(0, tooltipText.length - lastLabelAdded.length);
    labelIndex -= 1;
  }

  // If there are any labels that haven't been included, summarize them by truncating the end of the string
  // with a simple count
  const numUnusedLabels = numSelected - labelIndex;
  if (numUnusedLabels > 0) {
    tooltipText = (
      <>
        {tooltipText} ... <i>and {numUnusedLabels} Others</i>
      </>
    );
  }

  return tooltipText;
};

/**
 * DEVELOPER WARNING: This Component can only be rendered in a valid state if it represents a dimension
 * that has a mix of values that are filtered in/out. If all values are unselected, the logic in this
 * Component will fail because it was designed before we gave "all values are unselected" the same behavior
 * as "all values are selected".
 *
 * Put another way, this Component implicitly relies on that fact that no <DimFilterBubble> will be rendered
 * for a dimension filter that has "all values unselected" or "all values selected".
 *
 * Pay close attention to this warning when making changes.
 */
const DimFilterBubble = ({ dimId, dimValues, label, onOpen, tooltipText, disabled = false }) => {
  const { setFocusedDimensionId, unselectAllValues } = React.useContext(DashboardContext);

  let selectedValues = dimValues.filter(dimValue => dimValue.isSelected);
  const numSelected = selectedValues.length;

  const orderByCriteria = getOrderByCriteria(dimId);
  selectedValues = _.orderBy(selectedValues, orderByCriteria, ['asc']);

  const shouldDisplayTooltip = useMedia('(min-width: 800px)') && numSelected > 1;
  const tooltipSelectionText = useTooltipText(selectedValues);

  if (numSelected === 1) {
    const [onlySelectedValue] = selectedValues;
    label += ` (${onlySelectedValue.label})`;
  } else if (numSelected > 1) {
    const numSelectedLabel = ` (${numSelected})`;
    label += numSelectedLabel;
  }

  const onClickBubble = () => {
    setFocusedDimensionId(dimId);
    onOpen();
  };

  const onClickReset = e => {
    e.stopPropagation(); // Don't trigger the outer button when this button is clicked
    unselectAllValues(dimId);
    setFocusedDimensionId();
  };

  return (
    <ProfitRoverTooltip
      tooltipId="dim-values-tooltip-id"
      tooltipClass="dim-values-tooltip"
      shouldDisplayTooltip={tooltipText || shouldDisplayTooltip}
      tooltipText={tooltipText || tooltipSelectionText}
      placement="bottom-start"
      delay={250}
    >
      <button
        type="button"
        className={classnames('filter-bubble', {
          disabled,
        })}
        onClick={onClickBubble}
      >
        {label}
        <button type="button" className="clear-button" onClick={onClickReset}>
          <XIcon style={{ marginLeft: 1 }} />
        </button>
      </button>
    </ProfitRoverTooltip>
  );
};

const SCROLL_DISTANCE_PX = 300;

/**
 * Note that dimension ids can be a mix of Strings and integers, so we coerce all of them to strings before checking
 * them against the filteredDimensionsSet (whose contents should all be Strings).
 */
const shouldShowBubble = (dimPreview, filteredDimensionsSet) => filteredDimensionsSet.has(String(dimPreview.id));

const FiltersToolbar = ({ openFiltersMenu, activeTabId, activeTabLabel }) => {
  const { dimensionPreviews, filteredDimensionsSet } = React.useContext(DashboardContext);

  const dimPreviewsToShow = dimensionPreviews.filter(dimPreview => shouldShowBubble(dimPreview, filteredDimensionsSet));
  const sortedDimPreviews = _.sortBy(dimPreviewsToShow, ['label']);

  // For mounting/unmounting the scroll buttons after screen size changes
  const { ref: scrollingRef } = useResizeDetector({ refreshMode: 'throttle', refreshRate: 500 });

  // For triggering re-renders after manually scrolling via the buttons
  const update = useUpdate();

  // Enables arrow buttons to re-render after middle-mouse-button scrolling
  useScroll(scrollingRef);

  const scroll = scrollOffset => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += scrollOffset;

      // Trigger a re-render to update scroll buttons' disabled state if needed
      update();
    }
  };

  const { clientWidth, scrollWidth, scrollLeft } = scrollingRef?.current ?? {};
  const scrollable = clientWidth < scrollWidth;
  const canScrollLeft = scrollLeft > 0;
  const canScrollRight = clientWidth + Math.ceil(scrollLeft) < scrollWidth;

  return (
    <div className="filters-toolbar">
      <ProfitRoverTooltip tooltipText="Expand Filters" placement="right" delay={250}>
        <button type="button" className="open-button mr-1" onClick={openFiltersMenu}>
          <FontAwesomeIcon icon={faFilter} style={{ fontSize: '0.85rem', marginRight: 6 }} color={FONT_BLACK} />
          Filters
        </button>
      </ProfitRoverTooltip>
      {scrollable && (
        <button type="button" className="handle" onClick={() => scroll(-SCROLL_DISTANCE_PX)} disabled={!canScrollLeft}>
          <FontAwesomeIcon icon={faChevronLeft} color={FONT_GRAY} />
        </button>
      )}
      <div ref={scrollingRef} className="bubbles-list px-1">
        {sortedDimPreviews.map(({ id: dimId, label, dimValues, applicableTabs = [] }) => (
          <>
            {applicableTabs.length > 0 && !applicableTabs.includes(activeTabId) ? (
              <DimFilterBubble
                key={dimId}
                dimId={dimId}
                dimValues={dimValues}
                label={label}
                onOpen={openFiltersMenu}
                tooltipText={`Not applicable to ${activeTabLabel}`}
                disabled
              />
            ) : (
              <DimFilterBubble key={dimId} dimId={dimId} dimValues={dimValues} label={label} onOpen={openFiltersMenu} />
            )}
          </>
        ))}
      </div>
      {scrollable && (
        <button type="button" className="handle" onClick={() => scroll(SCROLL_DISTANCE_PX)} disabled={!canScrollRight}>
          <FontAwesomeIcon icon={faChevronRight} color={FONT_GRAY} />
        </button>
      )}
    </div>
  );
};

export const StaffingSettingsToolbar = ({ openStaffingSettings, activeTabId }) => {
  if (activeTabId !== TABS.STAFFING) {
    return null;
  }

  return (
    <div className="filters-toolbar">
      <ProfitRoverTooltip tooltipText="Change Staffing Settings" placement="right" delay={{ show: 250, hide: 0 }}>
        <button type="button" className="open-button mr-1" onClick={openStaffingSettings}>
          <FontAwesomeIcon icon={faCog} style={{ fontSize: '0.85rem', marginRight: 6 }} color={FONT_BLACK} />
          Settings
        </button>
      </ProfitRoverTooltip>
    </div>
  );
};

export const HoursSettingsToolbar = ({ openHoursSettings, activeTabId }) => {
  if (activeTabId !== TABS.HOURS) {
    return null;
  }

  return (
    <div className="filters-toolbar">
      <ProfitRoverTooltip tooltipText="Change Hours Settings" placement="right" delay={{ show: 250, hide: 0 }}>
        <button type="button" className="open-button mr-1" onClick={openHoursSettings}>
          <FontAwesomeIcon icon={faCog} style={{ fontSize: '0.85rem', marginRight: 6 }} color={FONT_BLACK} />
          Settings
        </button>
      </ProfitRoverTooltip>
    </div>
  );
};

export default FiltersToolbar;
