import _ from 'lodash';
import React from 'react';
import IntegrationInstructionsPage from '../setup/modern/IntegrationInstructionsPage';
import { PartnerInstructions } from '../setup/modern/PartnerInstructions';

const PartnerDataSourceInstructions = ({ partner, redirect }) => {
  /**
   * Developer Note: This is used by the redirect page to disambiguate which UI flow should be "continued"
   * after credentials have been redeemed and saved
   */
  localStorage.setItem(`${_.lowerCase(partner)}_redirect_behavior`, redirect);

  return (
    <IntegrationInstructionsPage dataSource={partner}>
      <PartnerInstructions partner={partner} />
    </IntegrationInstructionsPage>
  );
};

export default PartnerDataSourceInstructions;
