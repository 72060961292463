import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import HourEditor from './HourEditor';
import { DashboardContext } from './revenueUpliftContexts';
import { TOTAL_HEADCOUNT_METRIC } from './staffingTabConstants';
import StaffingTabContext from './staffingTabContext';
import { formatNumber, getLastShiftHour, getShiftDuration, getShiftEndTime } from './staffingTabUtil';
import { formatAsDatasetDate } from './timePeriod';
import { ALL_HOURS } from '../../../accountSettings/locationSettings/locationManagementConstants';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { useGridApi } from '../../../util/gridCommonFunctions';
import {
  gaEmitAddShiftButtonClick,
  gaEmitCopyToPlannerButtonClick,
  gaEmitDeleteShiftClick,
} from '../../../../google-analytics/staffingTab';
import { FONT_BLACK } from '../../../../colors';
import './tables.scss';

const borderClass = 'table-border-right';
const hourColumnWidth = 68;

const defaultColDef = {
  sortable: false,
  resizable: false,
  headerClass: borderClass,
  cellClass: borderClass,
  minWidth: hourColumnWidth,
  suppressMovable: true,
};

const TableLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-self-center">
      <CenteredProfitRoverSpinner />
    </div>
  );
};

const valueFormatter = (currencySymbol, selectedMetric) => {
  return params => {
    const { value } = params;
    const number = value ?? 0;

    return selectedMetric === TOTAL_HEADCOUNT_METRIC ? formatNumber(number) : formatNumber(number, { currencySymbol });
  };
};

const hourRenderer = props => {
  const { data = {}, value, context = {} } = props;
  const { start, end } = data;
  const { hoursRange = [] } = context;

  const startIdx = hoursRange.indexOf(start);
  const endIdx = hoursRange.indexOf(end);
  const valueIdx = hoursRange.indexOf(value);

  const isActive = valueIdx >= startIdx && valueIdx <= endIdx;
  const isStart = valueIdx === startIdx;
  const isEnd = valueIdx === endIdx;

  return (
    <div
      className={classnames('hour-cell', {
        'hour-cell-active': isActive,
        'hour-cell-start': isStart,
        'hour-cell-end': isEnd,
      })}
    />
  );
};

const editableHourRenderer = props => {
  const { value } = props;

  return (
    <div className="d-flex align-items-center" style={{ gap: '0.25rem' }}>
      <div>{value}</div>
      <FontAwesomeIcon icon={faPencilAlt} color={FONT_BLACK} size="sm" className="icon" />
    </div>
  );
};

const shiftDurationGetter = params => {
  const { data = {}, context = {} } = params;
  const { start, end } = data;
  const { hoursRange = [] } = context;

  return getShiftDuration(start, end, hoursRange);
};

const hourEndGetter = params => {
  const { data = {}, context = {} } = params;
  const { end } = data;
  const { hoursRange = [] } = context;

  return getShiftEndTime(end, hoursRange);
};

const frameworkComponents = {
  hourRenderer,
  editableHourRenderer,
  HourEditor,
};

const shiftNumberWidth = 60;
const startTimeWidth = 75;
const endTimeWidth = 75;
const durationWidth = 100;

export const HourBreakdownTable = ({ selectedMetric, selectedMetricLabel, plansData, isActive }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { hoursRange, selectedDate } = React.useContext(StaffingTabContext);

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const newColumnDefs = hoursRange.map(hour => ({
        headerName: hour,
        field: hour,
        headerClass: `${borderClass} center-text table-header`,
        cellClass: `${borderClass} center-text`,
        valueFormatter: valueFormatter(currencySymbol, selectedMetric),
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs(newColumnDefs);
    }
  }, [hoursRange, currencySymbol, selectedMetric, gridApi, selectedDate]);

  return (
    <div className="table-container mt-3" ref={containerRef} style={{ width: hourColumnWidth * hoursRange.length }}>
      <div className="metric-container">
        <div className="metric">{selectedMetricLabel}</div>
        {plansData.map(plan => (
          <div className="plan" key={plan.label}>
            {plan.label}
          </div>
        ))}
      </div>
      {isLoading && <TableLoader />}
      <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
        <AgGridReact
          rowData={plansData}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          groupHeaderHeight={24}
          headerHeight={24}
          rowHeight={24}
          singleClickEdit
          suppressModelUpdateAfterUpdateTransaction
          stopEditingWhenCellsLoseFocus
          rowBuffer={70}
          suppressHorizontalScroll
        />
      </div>
    </div>
  );
};

export const RecommendedTable = ({ isActive }) => {
  const { hoursRange, dayShifts, resetDayShifts, selectedDate } = React.useContext(StaffingTabContext);

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const hourColumnDefs = hoursRange.map(hour => ({
        headerName: hour,
        headerClass: `${borderClass} center-text table-header`,
        cellClass: 'hour-cell-container',
        cellRenderer: 'hourRenderer',
        valueGetter: () => hour,
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs([
        {
          headerName: 'Shift #',
          field: 'shift',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          width: shiftNumberWidth,
          minWidth: shiftNumberWidth,
          maxWidth: shiftNumberWidth,
        },
        {
          colId: 'startTime',
          headerName: 'Start Time',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          field: 'start',
          width: startTimeWidth,
          minWidth: startTimeWidth,
          maxWidth: startTimeWidth,
        },
        {
          colId: 'endTime',
          headerName: 'End Time',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          field: 'end',
          valueGetter: hourEndGetter,
          width: endTimeWidth,
          minWidth: endTimeWidth,
          maxWidth: endTimeWidth,
        },
        {
          headerName: 'Duration (Hrs)',
          field: 'duration',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          valueGetter: shiftDurationGetter,
          width: durationWidth,
          minWidth: durationWidth,
          maxWidth: durationWidth,
        },
        ...hourColumnDefs,
      ]);
    }
  }, [hoursRange, gridApi, selectedDate]);

  const onCopyToPlanner = () => {
    gaEmitCopyToPlannerButtonClick();
    resetDayShifts();
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center mt-3 mb-2">
        <h2 className="subtitle mr-2">RoverRecs</h2>
        {!isLoading && (
          <ProfitRoverSecondaryButton small onClick={onCopyToPlanner}>
            Copy to Planner
          </ProfitRoverSecondaryButton>
        )}
      </div>
      <div
        className="table-container mt-1"
        ref={containerRef}
        style={{
          minWidth:
            hourColumnWidth * hoursRange.length + shiftNumberWidth + startTimeWidth + endTimeWidth + durationWidth,
        }}
      >
        {isLoading && <TableLoader />}
        <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
          <AgGridReact
            rowData={dayShifts}
            onGridReady={onGridReady}
            context={{ hoursRange }}
            frameworkComponents={frameworkComponents}
            defaultColDef={defaultColDef}
            groupHeaderHeight={24}
            headerHeight={24}
            rowHeight={24}
            singleClickEdit
            suppressModelUpdateAfterUpdateTransaction
            stopEditingWhenCellsLoseFocus
            rowBuffer={70}
            suppressHorizontalScroll
          />
        </div>
      </div>
    </>
  );
};

const hourValueSetter = ({ newValue, data, colDef, context }) => {
  const { field } = colDef;
  const { shift } = data;
  const { hoursRange = [], dayShiftPlans, setDayShiftPlans } = context;

  if (!ALL_HOURS.includes(newValue)) {
    // Not a valid hour
    return false;
  }

  let actualEndHour = field === 'end' ? getLastShiftHour(newValue, hoursRange) : data.end;
  let actualStartHour = field === 'start' ? newValue : data.start;

  if (!hoursRange.includes(actualEndHour) || !hoursRange.includes(actualStartHour)) {
    // The shift is not within the store open hours
    return false;
  }

  if (field === 'start' && hoursRange.indexOf(actualStartHour) > hoursRange.indexOf(actualEndHour)) {
    actualEndHour = actualStartHour;
  }

  if (field === 'end' && hoursRange.indexOf(actualEndHour) < hoursRange.indexOf(actualStartHour)) {
    actualStartHour = actualEndHour;
  }

  setDayShiftPlans(
    dayShiftPlans.map(currentShift => {
      if (currentShift.shift === shift) {
        return {
          ...currentShift,
          start: actualStartHour,
          end: actualEndHour,
        };
      }
      return currentShift;
    }),
  );

  return true;
};

export const ShiftPlannerTable = ({ isActive }) => {
  const { hoursRange, setDayShiftPlans, dayShiftPlans, removeDayShift, addDayShift, selectedDate } = React.useContext(
    StaffingTabContext,
  );

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const hourColumnDefs = hoursRange.map(hour => ({
        headerName: hour,
        headerClass: `${borderClass} center-text table-header`,
        cellClass: 'hour-cell-container',
        cellRenderer: 'hourRenderer',
        valueGetter: () => hour,
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs([
        {
          headerName: 'Shift #',
          field: 'shift',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          width: shiftNumberWidth,
          minWidth: shiftNumberWidth,
          maxWidth: shiftNumberWidth,
        },
        {
          colId: 'startTime',
          headerName: 'Start Time',
          editable: true,
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text editable`,
          field: 'start',
          cellEditor: 'HourEditor',
          cellEditorParams: { type: 'start' },
          valueSetter: hourValueSetter,
          cellRenderer: 'editableHourRenderer',
          width: startTimeWidth,
          minWidth: startTimeWidth,
          maxWidth: startTimeWidth,
        },
        {
          colId: 'endTime',
          headerName: 'End Time',
          editable: true,
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text editable`,
          field: 'end',
          valueGetter: hourEndGetter,
          cellEditor: 'HourEditor',
          cellEditorParams: { type: 'end' },
          valueSetter: hourValueSetter,
          cellRenderer: 'editableHourRenderer',
          width: endTimeWidth,
          minWidth: endTimeWidth,
          maxWidth: endTimeWidth,
        },
        {
          headerName: 'Duration (Hrs)',
          field: 'duration',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          valueGetter: shiftDurationGetter,
          width: durationWidth,
          minWidth: durationWidth,
          maxWidth: durationWidth,
        },
        ...hourColumnDefs,
      ]);
    }
  }, [hoursRange, gridApi, selectedDate]);

  const onAddDayShift = () => {
    gaEmitAddShiftButtonClick();
    addDayShift();
  };

  const onRemoveDayShift = shift => {
    gaEmitDeleteShiftClick();
    removeDayShift(shift);
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center mb-2">
        <h2 className="subtitle mr-2">Staffing Planner</h2>
        {!isLoading && (
          <ProfitRoverSecondaryButton small onClick={onAddDayShift}>
            Add Shift
          </ProfitRoverSecondaryButton>
        )}
      </div>
      <div
        className="table-container mt-1"
        ref={containerRef}
        style={{
          minWidth:
            hourColumnWidth * hoursRange.length + shiftNumberWidth + startTimeWidth + endTimeWidth + durationWidth,
        }}
      >
        {isLoading && <TableLoader />}
        <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
          {!isLoading && (
            <div className="remove-shifts-container">
              {dayShiftPlans.map(({ shift }) => (
                <button key={shift} type="button" className="delete-button" onClick={() => onRemoveDayShift(shift)}>
                  <FontAwesomeIcon icon={faTrash} color={FONT_BLACK} size="sm" />
                </button>
              ))}
            </div>
          )}
          <AgGridReact
            rowData={dayShiftPlans}
            onGridReady={onGridReady}
            frameworkComponents={frameworkComponents}
            defaultColDef={defaultColDef}
            context={{ hoursRange, dayShiftPlans, setDayShiftPlans }}
            groupHeaderHeight={24}
            headerHeight={24}
            rowHeight={24}
            singleClickEdit
            suppressModelUpdateAfterUpdateTransaction
            stopEditingWhenCellsLoseFocus
            rowBuffer={70}
            suppressHorizontalScroll
          />
        </div>
      </div>
    </>
  );
};
