import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataSourceDisplayName, isAncillaryDataSource } from './dataSourceConstants';
import {
  getIntegrationTimestampLabel,
  DataSourceBase,
  FinishSetupButton,
  ReconnectButton,
  ResyncDataSourceButton,
} from './DataSourceRowCommon';
import DisconnectConfirmationModal from './DisconnectConfirmationModal';
import ReconnectConfirmationModal from './ReconnectConfirmationModal';
import { DataType } from '../data-center/datasetConfig/DatasetConfigFormConstants';
import { ProfitRoverDestructiveButton } from '../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../generic/ProfitRoverTooltip';
import { CREDENTIAL_TYPE } from '../setup/IntegrationConstants';
import { useCreatePartnerRecords } from '../setup/modern/createDatabaseRecords';
import { DATA_SOURCE_TO_DATA_CENTER_LABEL } from '../setup/modern/DataSourceLogos';
import { useRevokePartnerAccessTokens } from '../../data-access/mutation/oauthPartner';
import { usePartnerCredentials } from '../../data-access/query/credentials';
import { gaEmitDisconnectButtonClick } from '../../google-analytics/dataCenter';

const OauthDataSourceRow = ({ datasetConfig, datasets }) => {
  const { partner, name, data_type: dataType } = datasetConfig;

  const history = useHistory();
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false);
  const toggleShowDisconnectModal = () => setShowDisconnectModal(!showDisconnectModal);

  const [showReconnectModal, setShowReconnectModal] = React.useState(false);
  const toggleShowReconnectModal = () => setShowReconnectModal(!showReconnectModal);

  const { data: partnerCredentials = [], isLoading: isLoadingCredentials } = usePartnerCredentials(partner);
  const revokePartnerAccessTokensMutation = useRevokePartnerAccessTokens(partner, {
    onSuccess: toggleShowDisconnectModal,
  });
  const { isLoading: isRevokingTokens } = revokePartnerAccessTokensMutation;

  const { isInitializing, allPartnerRecordsExist, createPartnerRecords } = useCreatePartnerRecords();

  const credentialsNotRevoked = partnerCredentials.filter(cred => cred.revoked_gmt_datetime == null);
  const hasMissingAutomaticRecords = !allPartnerRecordsExist(partner);

  /**
   * Note: for Oauth partners we are expecting 2 credentials (access and renewal) that are not revoked
   */
  const partnerCredentialsMissing = credentialsNotRevoked.length < 2;

  // these credentials are revoked in tandem so just examine the access key
  const partnerCredentialsRevocationTime = partnerCredentials.find(
    credential => credential.credential_type === CREDENTIAL_TYPE.ACCESS_KEY,
  )?.revoked_gmt_datetime;
  const partnerCredentialsAreRevoked = partnerCredentialsRevocationTime != null;

  const label = DATA_SOURCE_TO_DATA_CENTER_LABEL[partner];

  // only 1 dataset per integration config is supported
  const [dataset] = datasets;
  const { fetched_gmt_datetime: datasetFetchedTime } = dataset ?? {};

  const setupIncomplete = partnerCredentialsMissing || hasMissingAutomaticRecords;

  const timestamp = getIntegrationTimestampLabel(datasetFetchedTime, setupIncomplete, partnerCredentialsRevocationTime);

  const navigateToOAuthFlow = () => {
    history.push(`/data-sources/add-new/${_.lowerCase(partner)}`);
  };

  const navigateToReconnectOAuthFlow = () => {
    history.push(`/data-sources/reconnect/${_.lowerCase(partner)}`);
  };

  const createMissingRecords = async () => {
    await createPartnerRecords({ partner });

    if (partnerCredentialsMissing) {
      navigateToOAuthFlow();
    }
  };

  const onDisconnect = () => {
    gaEmitDisconnectButtonClick();
    revokePartnerAccessTokensMutation.mutateAsync();
  };

  const actionButtonIsDisabled = isInitializing || isLoadingCredentials || partnerCredentialsAreRevoked;
  const finishSetupButtonDisabled = isInitializing || isLoadingCredentials;

  let actionButton = (
    <ResyncDataSourceButton
      datasetConfig={datasetConfig}
      disabled={actionButtonIsDisabled}
      setupIncomplete={setupIncomplete}
    />
  );

  // Do not show a finish or reconnect button if this is an ancillary config
  if (!isAncillaryDataSource(datasetConfig)) {
    if (!isInitializing && hasMissingAutomaticRecords) {
      actionButton = <FinishSetupButton disabled={finishSetupButtonDisabled} onClick={createMissingRecords} />;
    } else if (!isLoadingCredentials && partnerCredentialsMissing) {
      if (partnerCredentialsAreRevoked) {
        actionButton = <ReconnectButton disabled={finishSetupButtonDisabled} onClick={toggleShowReconnectModal} />;
      } else {
        actionButton = <FinishSetupButton disabled={finishSetupButtonDisabled} onClick={navigateToOAuthFlow} />;
      }
    }
  }

  const disconnectButton = (
    <ProfitRoverTooltip
      delay={{ show: 200, hide: 100 }}
      tooltipText={`Click to instruct ProfitRover to stop syncing with your ${DataSourceDisplayName[partner]} account`}
    >
      <ProfitRoverDestructiveButton
        type="button"
        className="disconnect-btn ml-4"
        onClick={toggleShowDisconnectModal}
        disabled={isRevokingTokens}
        small
      >
        <i>Disconnect</i>
      </ProfitRoverDestructiveButton>
    </ProfitRoverTooltip>
  );

  return (
    <>
      <DisconnectConfirmationModal
        partner={partner}
        show={showDisconnectModal}
        onClose={toggleShowDisconnectModal}
        onContinue={onDisconnect}
      />
      <ReconnectConfirmationModal
        partner={partner}
        show={showReconnectModal}
        onClose={toggleShowReconnectModal}
        onContinue={navigateToReconnectOAuthFlow}
      />

      <DataSourceBase
        label={label}
        name={name}
        timestamp={timestamp}
        actions={
          <>
            {actionButton}
            {!partnerCredentialsAreRevoked && !setupIncomplete && dataType === DataType.TRAN && disconnectButton}
          </>
        }
      />
    </>
  );
};

export default OauthDataSourceRow;
