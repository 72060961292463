import React from 'react';
import styled from 'styled-components';
import Header from '../header/header';
import SideBar from './sidebar';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 18em;
  margin-right: 6em;
`;
const ChannelsSettings = () => (
  <React.Fragment>
    <Header />
    <SideBar />
    <GridWrapper>
      <p>Channels Page</p>
    </GridWrapper>
  </React.Fragment>
);

export default ChannelsSettings;
