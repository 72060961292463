export const DAY_START_HOUR = '04:00';

// Summary metrics
export const INCOME_METRIC = 'income';
export const TIPS_METRIC = 'tips';
export const SHIFTS_METRIC = 'shifts';
export const HOURS_METRIC = 'hours';
export const INCOME_PER_EMPLOYEE_HOUR_METRIC = 'incomePerEmployeeHour';
export const TIPS_PER_EMPLOYEE_HOUR_METRIC = 'tipsPerEmployeeHour';

export const ALL_METRICS = [
  INCOME_METRIC,
  TIPS_METRIC,
  SHIFTS_METRIC,
  HOURS_METRIC,
  INCOME_PER_EMPLOYEE_HOUR_METRIC,
  TIPS_PER_EMPLOYEE_HOUR_METRIC,
];
export const NON_TIPS_METRICS = [INCOME_METRIC, SHIFTS_METRIC, HOURS_METRIC, INCOME_PER_EMPLOYEE_HOUR_METRIC];
export const FORECAST_METRICS = [INCOME_METRIC, TIPS_METRIC];

export const METRIC_TO_LABEL = {
  [INCOME_METRIC]: 'Income',
  [TIPS_METRIC]: 'Tips',
  [SHIFTS_METRIC]: 'Shifts',
  [HOURS_METRIC]: 'Hours',
  [INCOME_PER_EMPLOYEE_HOUR_METRIC]: 'Rev / Emp Hour',
  [TIPS_PER_EMPLOYEE_HOUR_METRIC]: 'Tips / Emp Hour',
};

// Day details metrics
export const TOTAL_INCOME_METRIC = 'totalIncome';
export const TOTAL_TIPS_METRIC = 'totalTips';
export const TOTAL_INCOME_PER_EMPLOYEE_METRIC = 'incomePerEmployee';
export const TIPS_PER_EMPLOYEE_METRIC = 'tipsPerEmployee';
export const TOTAL_HEADCOUNT_METRIC = 'totalHeadcount';

export const ALL_DAY_DETAILS_METRICS = [
  TOTAL_INCOME_METRIC,
  TOTAL_TIPS_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TIPS_PER_EMPLOYEE_METRIC,
  TOTAL_HEADCOUNT_METRIC,
];
export const NON_TIPS_DAY_DETAILS_METRICS = [
  TOTAL_INCOME_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TOTAL_HEADCOUNT_METRIC,
];
export const FORECAST_DAY_DETAILS_METRICS = [TOTAL_INCOME_METRIC, TOTAL_TIPS_METRIC];

export const DAY_DETAILS_METRIC_TO_LABEL = {
  [TOTAL_INCOME_METRIC]: 'Total Income',
  [TOTAL_TIPS_METRIC]: 'Total Tips',
  [TOTAL_INCOME_PER_EMPLOYEE_METRIC]: 'Total Income Per Employee',
  [TIPS_PER_EMPLOYEE_METRIC]: 'Tips Per Employee',
  [TOTAL_HEADCOUNT_METRIC]: 'Total Headcount',
};

// Staffing roles
export const STAFFING_ROLE_KEYS = {
  STAFFING_ROLE_NAME: 'name',
  MIN_HOURLY_STAFF_DAY: 'min_hourly_staff_day',
  MIN_HOURLY_STAFF_CLOSE: 'min_hourly_staff_close',
  MIN_HOURLY_REVENUE_PER_EMPLOYEE: 'min_hourly_revenue_per_employee',
  MAX_HOURLY_REVENUE_PER_EMPLOYEE: 'max_hourly_revenue_per_employee',
  MIN_HOURLY_TIPS_PER_EMPLOYEE: 'min_hourly_tips_per_employee',
  MAX_HOURLY_TIPS_PER_EMPLOYEE: 'max_hourly_tips_per_employee',
  MIN_SHIFT_LENGTH: 'min_shift_length',
  MAX_SHIFT_LENGTH: 'max_shift_length',
};

export const DEFAULT_STAFFING_ROLE = {
  [STAFFING_ROLE_KEYS.STAFFING_ROLE_NAME]: 'Default',
  [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY]: 1,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE]: 1,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE]: 0,
  [STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE]: 500,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE]: 0,
  [STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE]: 25,
  [STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]: 1,
  [STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH]: 8,
};

export const MIN_HOURLY_STAFF = 1;
export const MIN_HOURLY_REVENUE_PER_EMPLOYEE = 10;
export const MIN_SHIFT_LENGTH = 1;
export const MAX_SHIFT_LENGTH = 24;
