import React from 'react';
import { DashboardContext } from './revenueUpliftContexts';
import { TopPricingRecsTable } from './RevenuePriceRecTable';
import {
  DAY_OF_WEEK_DIMENSION_ID,
  SEASON_DIMENSION_ID,
  WEEKPART_DIMENSION_ID,
  WEEKS_SINCE_RELEASE_DIMENSION_ID,
} from './revenueUpliftConstants';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { gaEmitSeeMoreLinkClick } from '../../../../google-analytics/atAGlanceTab';

const TopPricingRecsSection = ({ showPricingTab, isActive }) => {
  const { onShowNext90Days, unselectAllValues } = React.useContext(DashboardContext);

  const onClickSeeMore = () => {
    gaEmitSeeMoreLinkClick();

    // Switch tabs
    showPricingTab();

    // Set necessary time period and baseline
    onShowNext90Days();

    // Remove filters that would cause seen results to differ from the At A Glance page
    unselectAllValues(DAY_OF_WEEK_DIMENSION_ID);
    unselectAllValues(WEEKPART_DIMENSION_ID);
    unselectAllValues(SEASON_DIMENSION_ID);
    unselectAllValues(WEEKS_SINCE_RELEASE_DIMENSION_ID);
  };

  return (
    <div className="top-recs-section w-100 pb-2">
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="d-flex mt-3 mb-2">
          <h5 className="mb-0">Top Pricing Recommendations</h5>
          <button type="button" className="link-like ml-2" onClick={onClickSeeMore}>
            See More
          </button>
        </div>
        <ProfitRoverCard className="table-card p-3" style={{ flex: 1 }}>
          <TopPricingRecsTable isActive={isActive} />
        </ProfitRoverCard>
      </div>
    </div>
  );
};

export default TopPricingRecsSection;
