import React from 'react';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { KaizenProduct } from '../generic/subscriptions/SubscriptionConstants';
import { useSubscriptionStatusForProduct } from '../routing/subscriptionStatusHooks';
import { isEoPriceoptWorkflow } from '../workflow/workflowFunctions';
import { useWorkflows } from '../../data-access/query/workflows';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import './sidebar.scss';

class NavItem extends React.Component {
  handleClick = () => {
    const { path, onItemClick } = this.props;
    onItemClick(path);
  };

  render() {
    const { active, isSubItem, name, path } = this.props;
    return (
      <div className={classnames('nav-item', { active, subitem: isSubItem })}>
        <Link to={path} onClick={this.handleClick}>
          <p>{name}</p>
        </Link>
      </div>
    );
  }
}

const SideNav = props => {
  const { pathname } = props.location;

  const [activePath, setActivePath] = React.useState(props.location.pathname);

  // Commented in case the "Products" sub-menus are revived
  //
  // const [showProducts, setShowProducts] = React.useState(
  //   pathname === '/overrideCurrentPrices' || pathname === '/configure-price-rules'
  // );

  const [showData, setShowData] = React.useState(
    pathname === '/dimensionsSettings' ||
      pathname === '/dimensionValuesSettings' ||
      pathname === '/dimensionValuesMappings',
  );

  // const cfbEntity = false;

  const onItemClick = path => {
    if (localStorage.getItem('unsaved_page')) {
      setActivePath(localStorage.getItem('unsaved_page'));
    } else {
      setActivePath(path);
    }
  };

  // const handleProduct = () => {
  //   setShowProducts(!showProducts);
  // };

  const handleDataDropdown = () => {
    setShowData(!showData);
  };

  // const redirect = async e => {
  //   e.preventDefault();
  //   const { history } = props;
  //   history.push({
  //     pathname: '/welcome',
  //   });
  // };

  const { showSubscription, isStripeSubscription, isShopifySubscription, ...rest } = props;

  const { data: workflows = [] } = useWorkflows();
  const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);

  const showPreferences = kpWorkflows.length === 1;

  return (
    <React.Fragment>
      <div className="sidebar-container" {...rest}>
        <h6 className="header">Settings</h6>
        <NavItem
          path="/personalSettings"
          name="Personal"
          onItemClick={onItemClick}
          active={activePath === '/personalSettings'}
          key={1}
          isSubItem={false}
        />
        <NavItem
          path="/companySettings"
          name="Company"
          onItemClick={onItemClick}
          active={activePath === '/companySettings'}
          key={2}
          isSubItem={false}
        />
        <NavItem
          path="/location-settings"
          name="Locations & Hours"
          onItemClick={onItemClick}
          active={activePath === '/location-settings'}
          key={3}
          isSubItem={false}
        />

        <div className="nav-item" onClick={handleDataDropdown} onKeyDown={handleDataDropdown} role="presentation">
          Data
        </div>
        {showData && (
          <>
            <NavItem
              path="/dimensionsSettings"
              name="Manage Dimensions"
              onItemClick={onItemClick}
              active={activePath === '/dimensionsSettings'}
              isSubItem
            />
            <NavItem
              path="/dimensionValuesSettings"
              name="Manage Dimension Values"
              onItemClick={onItemClick}
              active={activePath === '/dimensionValuesSettings'}
              isSubItem
            />
            <NavItem
              path="/dimensionValuesMappings"
              name="Dimension Value Mapping"
              onItemClick={onItemClick}
              active={activePath === '/dimensionValuesMappings'}
              isSubItem
            />
          </>
        )}

        {/* Temporarily hidden as part of KP-2430 */}
        {/* <div
            style={{
              fontSize: '1.2em',
              paddingLeft: '0.4em',
              cursor: 'pointer',
              marginBottom: '0.2em',
            }}
            onClick={handleProduct}
            onKeyDown={handleProduct}
            role="presentation"
          >
            Products
          </div>
          {showProducts ? (
            <React.Fragment>
              <NavItem
                path="/configure-price-rules"
                name="Configure Price Rules"
                onItemClick={onItemClick}
                active={activePath === '/configure-price-rules'}
                isSubItem
                key={5}
              />
              {cfbEntity ? (
                <NavItem
                  path="/overrideCurrentPrices"
                  name="Override Current Prices"
                  onItemClick={onItemClick}
                  active={activePath === '/overrideCurrentPrices'}
                  key={6}
                  isSubItem
                />
              ) : (
                <div />
              )}
            </React.Fragment>
          ) : (
            <div />
          )} */}
        <NavItem
          path="/custom-events"
          name="Custom Events"
          onItemClick={onItemClick}
          active={activePath === '/custom-events'}
          key={7}
          isSubItem={false}
        />
        <Can I={Action.MANAGE} a={Subject.ALL}>
          <NavItem
            path="/user-management"
            name="Users & Roles"
            onItemClick={onItemClick}
            active={activePath === '/user-management'}
            key={8}
            isSubItem={false}
          />
        </Can>

        {showSubscription && isStripeSubscription && (
          <Can I={Action.MANAGE} a={Subject.ALL}>
            <NavItem
              path="/subscription-management"
              name="Subscription"
              onItemClick={onItemClick}
              active={activePath === '/subscription-management'}
            />
          </Can>
        )}

        {showSubscription && isShopifySubscription && (
          <Can I={Action.MANAGE} a={Subject.ALL}>
            <NavItem
              path="/subscription-management/shopify"
              name="Subscription"
              onItemClick={onItemClick}
              active={activePath === '/subscription-management/shopify'}
            />
          </Can>
        )}

        {showPreferences && (
          <NavItem
            path="/preferences"
            name="Preferences"
            onItemClick={onItemClick}
            active={activePath === '/preferences'}
            key={8}
            isSubItem={false}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const RouterSideNav = withRouter(SideNav);

const Sidebar = props => {
  const { isFetching, isShopifySubscription, isStripeSubscription, hasSubscription } = useSubscriptionStatusForProduct(
    KaizenProduct.KP,
  );

  const showSubscription = !isFetching && (isStripeSubscription || isShopifySubscription) && hasSubscription;

  return (
    <RouterSideNav
      showSubscription={showSubscription}
      isStripeSubscription={isStripeSubscription}
      isShopifySubscription={isShopifySubscription}
      {...props}
    />
  );
};

export default Sidebar;
