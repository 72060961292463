import React from 'react';
import { useLocation } from 'react-router-dom';
import { REGISTRATION_STEPS } from './registrationContext';
import { StripeBillingScheme } from '../generic/subscriptions/SubscriptionConstants';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import accessTokenInstance from '../../data-access/axios-instances/userRegistrationInstance';

export const useProductPrices = (prices, coupon = null) => {
  if (prices == null || Object.keys(prices).length === 0) {
    return {
      annualPriceDollarAmt: 0,
      annualPriceCentAmt: 0,
      annualPricePerMonthDollarAmt: 0,
      annualPricePerMonthCentAmt: 0,
      pricePerMonth: 0,
      pricePerMonthDollarAmt: 0,
      pricePerMonthCentAmt: 0,
      savingsPerYear: 0,
      savingsPerYearPct: 0,
    };
  }
  let monthlyUnitAmount;
  let annualUnitAmount;
  const billingScheme = prices?.monthly.billing_scheme;
  if (billingScheme === StripeBillingScheme.PER_UNIT) {
    ({
      annual: { unit_amount: annualUnitAmount = 0 },
      monthly: { unit_amount: monthlyUnitAmount = 0 },
    } = prices);
  } else {
    const [, nonFreeMonthlyTier = {}] = prices?.monthly?.tiers ?? [];
    const [, nonFreeAnnualTier = {}] = prices?.annual?.tiers ?? [];
    ({ unit_amount: monthlyUnitAmount = 0 } = nonFreeMonthlyTier);
    ({ unit_amount: annualUnitAmount = 0 } = nonFreeAnnualTier);
  }

  const couponPercentOff = coupon ? coupon.percent_off / 100 : 0;

  const annualPriceWithDiscount = (annualUnitAmount / 100) * (1 - couponPercentOff);
  const annualPrice = (annualUnitAmount / 100).toFixed(2);
  const annualPriceSplit = annualPrice.toString().split('.');
  const annualPriceDollarAmt = annualPriceSplit[0];
  const annualPriceCentAmt = annualPriceSplit[1] ? annualPriceSplit[1] : '00';

  const annualPricePerMonth = annualPrice ? (annualPrice / 12).toFixed(2) : 0;
  const annualPricePerMonthSplit = annualPricePerMonth.toString().split('.');
  const annualPricePerMonthDollarAmt = annualPricePerMonthSplit[0];
  const annualPricePerMonthCentAmt = annualPricePerMonthSplit[1] ? annualPricePerMonthSplit[1] : '00';

  const pricePerMonthWithDiscount = coupon ? (monthlyUnitAmount / 100) * (1 - coupon.percent_off / 100) : 0;
  const pricePerMonth = (monthlyUnitAmount / 100).toFixed(2);
  const pricePerMonthSplit = pricePerMonth.toString().split('.');
  const pricePerMonthDollarAmt = pricePerMonthSplit[0];
  const pricePerMonthCentAmt = pricePerMonthSplit[1] ? pricePerMonthSplit[1] : '00';

  const monthlyPlanPricePerYear = pricePerMonth * 12;
  const savingsPerYear = monthlyPlanPricePerYear - annualPrice;
  const savingsPerYearPct = Math.round((savingsPerYear / monthlyPlanPricePerYear) * 100);

  const monthlyPlanPricePerYearWithDiscount = pricePerMonthWithDiscount * 12;
  const savingsPerYearWithDiscount = monthlyPlanPricePerYearWithDiscount - annualPriceWithDiscount;
  const savingsPerYearWithDiscountPct = Math.round(
    (savingsPerYearWithDiscount / monthlyPlanPricePerYearWithDiscount) * 100,
  );

  return {
    annualPrice,
    annualPriceWithDiscount,
    annualPriceDollarAmt,
    annualPriceCentAmt,
    annualPricePerMonthDollarAmt,
    annualPricePerMonthCentAmt,
    pricePerMonth,
    pricePerMonthWithDiscount,
    pricePerMonthDollarAmt,
    pricePerMonthCentAmt,
    savingsPerYear,
    savingsPerYearWithDiscount,
    savingsPerYearPct,
    savingsPerYearWithDiscountPct,
  };
};

export const useShopifyProductPrices = prices => {
  if (prices == null || Object.keys(prices).length === 0) {
    return {
      annualPriceDollarAmt: 0,
      annualPriceCentAmt: 0,
      annualPricePerMonthDollarAmt: 0,
      annualPricePerMonthCentAmt: 0,
      pricePerMonth: 0,
      pricePerMonthDollarAmt: 0,
      pricePerMonthCentAmt: 0,
      savingsPerYear: 0,
      savingsPerYearPct: 0,
    };
  }
  const {
    annual: { unit_amount: annualUnitAmount = 0 },
    month: { unit_amount: monthlyUnitAmount = 0 },
  } = prices;

  const annualPrice = parseFloat(annualUnitAmount).toFixed(2);
  const annualPriceSplit = annualPrice.toString().split('.');
  const annualPriceDollarAmt = annualPriceSplit[0];
  const annualPriceCentAmt = annualPriceSplit[1] ? annualPriceSplit[1] : '00';

  const annualPricePerMonth = annualPrice ? (annualPrice / 12).toFixed(2) : 0;
  const annualPricePerMonthSplit = annualPricePerMonth.toString().split('.');
  const annualPricePerMonthDollarAmt = annualPricePerMonthSplit[0];
  const annualPricePerMonthCentAmt = annualPricePerMonthSplit[1] ? annualPricePerMonthSplit[1] : '00';

  const pricePerMonth = parseFloat(monthlyUnitAmount).toFixed(2);
  const pricePerMonthSplit = pricePerMonth.toString().split('.');
  const pricePerMonthDollarAmt = pricePerMonthSplit[0];
  const pricePerMonthCentAmt = pricePerMonthSplit[1] ? pricePerMonthSplit[1] : '00';

  const monthlyPlanPricePerYear = pricePerMonth * 12;
  const savingsPerYear = monthlyPlanPricePerYear - annualPrice;
  const savingsPerYearPct = Math.round((savingsPerYear / monthlyPlanPricePerYear) * 100);

  return {
    annualPrice,
    annualPriceDollarAmt,
    annualPriceCentAmt,
    annualPricePerMonthDollarAmt,
    annualPricePerMonthCentAmt,
    pricePerMonth,
    pricePerMonthDollarAmt,
    pricePerMonthCentAmt,
    savingsPerYear,
    savingsPerYearPct,
  };
};

export const useIsLaunchPartyFlow = () => {
  const { pathname } = useLocation();

  return pathname.endsWith('launchparty');
};

export const usePromoPriceName = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/promo')) {
    return pathname.slice(1); // omit the leading '/'
  }

  return '';
};

const logSource = (email, source, medium, campaign) =>
  accessTokenInstance.post(`${KA_API_ROOT_URL}/stripe/source`, { email, source, medium, campaign });

/**
 *
 */
export const useLogInboundCustomerSource = (email, source, medium, campaign, isFetchingAccessToken) => {
  const [logComplete, setLogComplete] = React.useState(false);

  React.useEffect(() => {
    const isSourceParam = [email, source, medium, campaign].some(e => e != null);

    if (!logComplete && !isFetchingAccessToken && isSourceParam) {
      const sendLog = async () => {
        await logSource(email, source, medium, campaign);
        setLogComplete(true);
      };

      sendLog();
    }
  }, [isFetchingAccessToken, logComplete, email, source, medium, campaign]);
};

/**
 * If we came from the ProfitRover corporate website it may have an email address param,
 * in which case we can skip to step number 2
 */
export const useCorporateMarketingParams = isFetchingAccessToken => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const emailParam = params.get('email');
  const sourceParam = params.get('source');
  const mediumParam = params.get('medium');
  const campaignParam = params.get('campaign');

  useLogInboundCustomerSource(emailParam, sourceParam, mediumParam, campaignParam, isFetchingAccessToken);

  const step = emailParam != null ? REGISTRATION_STEPS.REQUEST_CODE : undefined;

  return { emailParam, gotoStep: step };
};
