import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { STEPS } from './commonStepControl';
import { useGuidedSetupContext } from './guidedSetupContext';
import IndustrySelect, { mapCodeToOption } from './IndustrySelect';
import { ProgressBar, TrackedSteps } from './ProgressBar';
import { PageHeader } from '../../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import { useUpdateCompanyInfo, useUpdateUserInfo } from '../../../data-access/mutation/users';
import { useCustomerInfo, useUserInfo } from '../../../data-access/query/users';
import { isValidWebsiteAddress } from '../../../utils/validators';
import './tell-about-yourself.scss';

const nonEmptyStringValidator = value => ((value ?? '') === '' ? FORM_ERROR : undefined);

const TellAboutYourself = () => {
  const {
    guidedSetup: { naics_industry: initialIndustry, scheduled_free_call: scheduledFreeCall },
    stepState: { setStep },
    updateGuidedSetup,
  } = useGuidedSetupContext();

  const { data: userInfo = {} } = useUserInfo();
  const { data: customerInfo = {} } = useCustomerInfo();

  const updateUserInfo = useUpdateUserInfo();
  const updateCompanyInfo = useUpdateCompanyInfo();

  const initialFirstName = userInfo?.first_name ?? null;
  const initialLastName = userInfo?.last_name ?? null;

  const initialCompanyName = customerInfo?.overview?.name ?? null;
  const initialWebsite = customerInfo?.overview?.website ?? null;
  const initialPhoneNumber = customerInfo?.overview?.phone ?? null;

  const initialIndustryOption = React.useMemo(
    () => (initialIndustry != null ? mapCodeToOption(initialIndustry) : null),
    [initialIndustry],
  );

  const onSubmit = async (formState, form) => {
    const {
      'first-name': firstName,
      'last-name': lastName,
      'company-name': companyName,
      'phone-number': phoneNumber,
      website,
    } = formState;

    const isDirtyField = field => form.getFieldState(field).dirty;

    const userInfoUpdates = {};
    if (isDirtyField('first-name')) {
      userInfoUpdates.firstName = firstName;
    }
    if (isDirtyField('last-name')) {
      userInfoUpdates.lastName = lastName;
    }

    const companyInfoUpdates = {};
    if (isDirtyField('company-name')) {
      companyInfoUpdates.companyName = companyName ?? null;
    }
    if (isDirtyField('website')) {
      companyInfoUpdates.website = website ?? null;
    }
    if (isDirtyField('phone-number')) {
      companyInfoUpdates.phone = phoneNumber ?? null;
    }

    // Only make requests if there are changes
    const promises = [];
    if (Object.keys(userInfoUpdates).length > 0) {
      promises.push(updateUserInfo.mutateAsync(userInfoUpdates));
    }

    if (Object.keys(companyInfoUpdates).length > 0) {
      promises.push(updateCompanyInfo.mutateAsync(companyInfoUpdates));
    }

    if (isDirtyField('industry')) {
      promises.push(updateGuidedSetup({ naics_industry: formState.industry.value }));
    }

    await Promise.allSettled(promises);

    setStep(scheduledFreeCall ? STEPS.THREE : STEPS.TWO);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={props => {
        const { handleSubmit, submitting, invalid } = props;

        return (
          <HeaderAwarePage>
            <Header />

            <ProgressBar activeStep={TrackedSteps.ABOUT_YOU} />
            <form className="industry-setup container py-5" onSubmit={handleSubmit}>
              <PageHeader className="mb-4">
                <h3>Tell us a little about yourself</h3>
              </PageHeader>

              <div className="simple-questions">
                <div className="prompt">What is your name?</div>
                <div className="side-by-side">
                  <div className="labelled-field">
                    <label>
                      First Name*
                      <Field
                        className="text-field"
                        initialValue={initialFirstName}
                        name="first-name"
                        component="input"
                        placeholder="First"
                        validate={nonEmptyStringValidator}
                        autoFocus
                      />
                    </label>
                  </div>
                  <div className="labelled-field">
                    <label>
                      Last Name*
                      <Field
                        className="text-field"
                        initialValue={initialLastName}
                        name="last-name"
                        component="input"
                        placeholder="Last"
                        validate={nonEmptyStringValidator}
                      />
                    </label>
                  </div>
                </div>

                <div className="prompt">What is the name of your company?*</div>
                <Field
                  className="text-field"
                  initialValue={initialCompanyName}
                  name="company-name"
                  component="input"
                  placeholder="Company Name"
                  validate={nonEmptyStringValidator}
                />
              </div>

              <div className="prompt">What industry are you in?*</div>
              <div className="dropdown-area">
                <label htmlFor="industry" className="sr-only">
                  Your Industry
                </label>
                <Field
                  name="industry"
                  initialValue={initialIndustryOption}
                  allowNull
                  validate={value => (value == null ? FORM_ERROR : undefined)}
                  isEqual={_.isEqual}
                  render={({ input: { onChange, value } }) => {
                    return <IndustrySelect inputId="industry" value={value} onChange={onChange} />;
                  }}
                />
              </div>

              <div className="prompt">What is your company website?</div>
              <label htmlFor="website" className="sr-only">
                Your Website
              </label>
              <Field
                className="text-field"
                name="website"
                initialValue={initialWebsite}
                validate={value => {
                  if (value && !isValidWebsiteAddress(value)) {
                    return FORM_ERROR;
                  }

                  return undefined;
                }}
                render={renderProps => {
                  const { invalid: inputInvalid } = renderProps.meta;

                  return (
                    <input
                      id="website"
                      className={classnames('text-field', { invalid: inputInvalid })}
                      placeholder="Optional"
                      {...renderProps.input}
                    />
                  );
                }}
              />

              <div className="prompt">Phone number</div>
              <label htmlFor="phone-number" className="sr-only">
                Your Phone Number
              </label>
              <Field
                name="phone-number"
                initialValue={initialPhoneNumber}
                validate={value => {
                  if (value && !isValidPhoneNumber(value)) {
                    return FORM_ERROR;
                  }

                  return undefined;
                }}
                render={renderProps => {
                  const { invalid: inputInvalid } = renderProps.meta;

                  return (
                    <PhoneInput
                      id="phone-number"
                      className={classnames({ invalid: inputInvalid })}
                      initialValueFormat="national"
                      defaultCountry="US"
                      placeholder="Optional"
                      {...renderProps.input}
                    />
                  );
                }}
              />

              <div className="py-3">
                <ProfitRoverPrimaryButton type="submit" disabled={invalid || submitting}>
                  Next
                </ProfitRoverPrimaryButton>
              </div>
            </form>
          </HeaderAwarePage>
        );
      }}
    />
  );
};

export default TellAboutYourself;
