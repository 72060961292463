import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import SettingsInput from './SettingsInput';
import SettingsPageWrapper from './SettingsPageWrapper';
import SideBar from './sidebar';
import { PasswordPolicy, Rule } from '../auth/password-policy';
import { baseProfitRoverRules } from '../auth/password-rules';
import { PasswordRuleList } from '../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import {
  gaEmitChangePasswordButtonClick,
  gaEmitUserInfoSaveChangesButtonClick,
} from '../../google-analytics/personalSettings';
import Header from '../header/header';
import axios from '../../utils/axios-client';
import { KA_API_URL } from '../../config/baseUrl';
import '../auth/auth.css';

class PersonalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      currentValues: {},
      userInfoErrorMessage: '',
      passwordErrorMessage: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      settingsChange: false,
      settingsUpdate: {},
      passwordChange: false,
      passwordIsAllowed: false,
      userInfoError: false,
      passwordError: false,
    };

    const username = localStorage.getItem('email');

    const ruleUsername = password => {
      // User should enter at least one character to check for the validation
      return password && password.length > 0 && password !== username;
    };

    this.passwordPolicy = new PasswordPolicy(baseProfitRoverRules);
    this.passwordPolicy.addRule(new Rule('Must not match your username', ruleUsername, 'username'));
  }

  async componentDidMount() {
    let id = localStorage.getItem('id');
    id = parseInt(id, 10);
    localStorage.removeItem('unsaved_page');
    await this.fetchUserData(id);
  }

  fetchUserData = async id => {
    let { currentValues } = this.state;

    try {
      const response = await axios.get(`${KA_API_URL}/users/${id}`);

      if (response.status === 200) {
        currentValues = response.data;
        await this.setState({
          userId: id,
          emailAddress: currentValues.email_address,
          firstName: currentValues.first_name,
          lastName: currentValues.last_name,
          currentValues,
        });
        localStorage.removeItem('unsaved_page');
      }
    } catch (error) {
      await this.setState({
        userInfoError: true,
        userInfoErrorMessage: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  handleSettingChange = e => {
    const input = e.target.name;
    const { value } = e.target;
    const { settingsUpdate, settingsChange } = this.state;
    settingsUpdate[input] = value;
    this.setState({
      [input]: value,
      settingsUpdate,
    });
    if (!settingsChange) {
      this.setState({
        settingsChange: true,
      });
      localStorage.setItem('unsaved_page', '/personalSettings');
    }
  };

  handlePasswordChange = e => {
    e.preventDefault();
    const { name: input, value } = e.target;

    if (input === 'newPassword') {
      if (this.passwordPolicy.checkPassword(value)) {
        this.setState({
          passwordIsAllowed: true,
        });
      } else {
        this.setState({
          passwordIsAllowed: false,
        });
      }
    }

    const { passwordChange } = this.state;

    this.setState({
      [input]: value,
    });
    if (!passwordChange) {
      this.setState({
        passwordChange: true,
      });
      localStorage.setItem('unsaved_page', '/personalSettings');
    }
  };

  savePersonalSettings = async e => {
    gaEmitUserInfoSaveChangesButtonClick();
    e.preventDefault();
    await this.setState({
      userInfoError: false,
    });
    const { firstName, lastName, emailAddress, currentValues, settingsUpdate } = this.state;
    const settingUpdates = Object.keys(settingsUpdate);
    // eslint-disable-next-line max-len
    const tester = /^[-!#$%&'*+\\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const valid = tester.test(emailAddress);

    if (firstName === '' || lastName === '' || emailAddress === '') {
      await this.setState({
        userInfoError: true,
        userInfoErrorMessage: 'Fields cannot be blank',
      });
    } else if (settingUpdates.length < 1) {
      await this.setState({
        userInfoError: true,
        userInfoErrorMessage: 'No changes to save',
      });
    } else if (!valid) {
      await this.setState({
        userInfoError: true,
        userInfoErrorMessage: 'Please use a valid email',
      });
    } else if (currentValues.email_address !== emailAddress) {
      const response = await axios.get(`${KA_API_URL}/users?email=${emailAddress}`);

      if (response.data.length > 0) {
        await this.setState({
          userInfoError: true,
          userInfoErrorMessage: 'Email already exists',
        });
      } else {
        await this.updateUserInfo();
      }
    } else {
      await this.updateUserInfo();
    }
  };

  saveNewPassword = async e => {
    gaEmitChangePasswordButtonClick();
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;

    if (newPassword.length < 8) {
      this.setState({
        passwordError: true,
        passwordErrorMessage: 'Password should be at least 8 characters',
      });
    } else if (newPassword !== confirmPassword) {
      this.setState({
        passwordError: true,
        passwordErrorMessage: 'Passwords do not match',
      });
    } else {
      this.updateUserPassword();
    }
  };

  updateUserPassword = async () => {
    const { userId, currentPassword, newPassword } = this.state;
    try {
      const response = await axios.put(`${KA_API_URL}/users/${userId}/password`, {
        current_password: currentPassword,
        new_password: newPassword,
      });

      if (response.status === 200) {
        this.passwordPolicy.resetRules(); // to reset such that rules.isSatisfied is false

        this.setState({
          passwordError: false,
          passwordChange: false,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          passwordIsAllowed: false,
        });
        localStorage.removeItem('unsaved_page');
      }
    } catch (error) {
      this.setState({
        passwordError: true,
        passwordErrorMessage:
          error.response.status === 400
            ? 'Incorrect password'
            : error.response == null
            ? error.message
            : error.response.data.message,
      });
    }
  };

  updateUserInfo = async () => {
    const { userId, settingsUpdate, firstName, lastName } = this.state;

    try {
      const response = await axios.put(`${KA_API_URL}/users/${userId}`, {
        email_address: settingsUpdate.emailAddress,
        first_name: settingsUpdate.firstName,
        last_name: settingsUpdate.lastName,
      });

      if (response.status === 200) {
        await this.setState({
          userInfoError: false,
          settingsChange: false,
          settingsUpdate: {},
        });

        if (settingsUpdate.emailAddress) {
          localStorage.setItem('email', settingsUpdate.emailAddress);
        }
        if (settingsUpdate.firstName || settingsUpdate.lastName) {
          const firstNameToSet = settingsUpdate.firstName ? settingsUpdate.firstName : firstName;
          const lastNameToSet = settingsUpdate.lastName ? settingsUpdate.lastName : lastName;
          const fullName = `${firstNameToSet} ${lastNameToSet}`;
          localStorage.setItem('name', firstNameToSet);
          localStorage.setItem('full_name', fullName);
        }

        localStorage.removeItem('unsaved_page');

        await this.fetchUserData(userId);
      }
    } catch (error) {
      await this.setState({
        userInfoError: true,
        userInfoErrorMessage: error.response == null ? error.message : error.response.data.message,
      });
    }
  };

  componentDidUpdate = () => {
    const { settingsChange, passwordChange } = this.state;
    if (settingsChange || passwordChange) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    const {
      userInfoError,
      passwordError,
      settingsChange,
      passwordChange,
      passwordIsAllowed,
      firstName,
      lastName,
      emailAddress,
      currentPassword,
      newPassword,
      confirmPassword,
      userInfoErrorMessage,
      passwordErrorMessage,
    } = this.state;
    let userInfoErrorMessageFragment = null;
    if (userInfoError) {
      userInfoErrorMessageFragment = (
        <div className="mb-3">
          <p className="settings-error-message">{userInfoErrorMessage}</p>
        </div>
      );
    }

    let passwordErrorMessageFragment = null;
    if (passwordError) {
      passwordErrorMessageFragment = (
        <div className="mb-3">
          <p className="settings-error-message">{passwordErrorMessage}</p>
        </div>
      );
    }

    let newPasswordFieldsClassName = null;
    const newPasswordFieldsFilled = newPassword.length > 0 || confirmPassword.length > 0;
    if (newPasswordFieldsFilled) {
      const doesPasswordTextMatch = confirmPassword === newPassword;
      newPasswordFieldsClassName = doesPasswordTextMatch ? 'match' : 'mismatch';
    }

    const canChangePassword = passwordIsAllowed && currentPassword.length > 0 && confirmPassword === newPassword;
    return (
      <HeaderAwarePage>
        <React.Fragment>
          <Prompt
            when={settingsChange || passwordChange}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        <Header />
        <SideBar />
        <SettingsPageWrapper>
          <div className="container">
            <h1 className="settings-header">Personal</h1>
            <ProfitRoverCard className="settings-card">
              <h4 className="settings-subheader">User Info</h4>
              <form className="settings-form">
                <SettingsInput
                  field="firstName"
                  label="First Name"
                  value={firstName}
                  onChange={this.handleSettingChange}
                />
                <SettingsInput
                  field="lastName"
                  label="Last Name"
                  value={lastName}
                  onChange={this.handleSettingChange}
                />
                <SettingsInput
                  field="emailAddress"
                  label="Email"
                  value={emailAddress}
                  onChange={this.handleSettingChange}
                  type="email"
                  required
                />
              </form>
              {userInfoErrorMessageFragment}
              <div className="w-100 d-flex justify-content-center mt-4">
                <ProfitRoverPrimaryButton onClick={this.savePersonalSettings} disabled={!settingsChange}>
                  Save Changes
                </ProfitRoverPrimaryButton>
              </div>
            </ProfitRoverCard>
            <ProfitRoverCard className="mt-3 settings-card">
              <h4 className="settings-subheader">Update Password</h4>
              <form className="settings-form">
                <SettingsInput
                  field="currentPassword"
                  label="Current Password"
                  value={currentPassword}
                  onChange={this.handlePasswordChange}
                  type="password"
                  required
                  autoComplete="off"
                />
                <SettingsInput
                  field="newPassword"
                  label="New Password"
                  value={newPassword}
                  onChange={this.handlePasswordChange}
                  type="password"
                  required
                  autoComplete="off"
                  className={newPasswordFieldsClassName}
                />
                <SettingsInput
                  field="confirmPassword"
                  label="Confirm New Password"
                  value={confirmPassword}
                  onChange={this.handlePasswordChange}
                  type="password"
                  required
                  autoComplete="off"
                  className={newPasswordFieldsClassName}
                />
              </form>
              {passwordErrorMessageFragment}
              <div className="w-100 d-flex justify-content-center mt-4">
                <ProfitRoverPrimaryButton onClick={this.saveNewPassword} disabled={!canChangePassword}>
                  Change Password
                </ProfitRoverPrimaryButton>
              </div>

              <div className="row pb-1" style={{ marginTop: '1rem', fontSize: '20px' }}>
                <div className="col-4" />
                Password policy:
              </div>
              <div className="row pb-1">
                <div className="col-4" />
                <PasswordRuleList rules={this.passwordPolicy.rules} />
              </div>
            </ProfitRoverCard>
          </div>
        </SettingsPageWrapper>
      </HeaderAwarePage>
    );
  }
}

export default connect(null, {})(PersonalSettings);
