/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigateToPartnerOAuthInitiate } from './PartnerHooks';
import { DataSource, DataSourceDisplayName } from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import './partner-instructions.scss';

const PARTNER_STEP_LIST = {
  [DataSource.GOTAB]: [
    `You will be redirected to a GoTab authorization page 
  (note that you may have to verify your GoTab account).`,
    `On the GoTab authorization page, select one or more locations (if required).`,
    `Press the Authorize button to grant access.`,
  ],
  [DataSource.SQUARE]: ['Login with your Square Credentials.', `Allow access to the permissions we request.`],
};

export const PartnerInstructions = ({ partner }) => {
  const initiateOauth = useNavigateToPartnerOAuthInitiate(partner);

  return (
    <div className="partner-instructions">
      <ol>
        <li>
          Use the Connect to {DataSourceDisplayName[partner]} button below to begin the authorization process for
          ProfitRover.
        </li>
        {PARTNER_STEP_LIST[partner].map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ol>

      <div className="post-integration-instructions">
        <i>You will be automatically redirected back to ProfitRover.</i>
      </div>

      <hr />

      <ProfitRoverPrimaryButton onClick={initiateOauth}>
        <div className="d-flex align-items-center">
          Connect to {DataSourceDisplayName[partner]}
          <FontAwesomeIcon icon={faCaretRight} pull="right" style={{ fontSize: 22, position: 'relative', top: 1 }} />
        </div>
      </ProfitRoverPrimaryButton>
    </div>
  );
};
