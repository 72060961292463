import React from 'react';
import { Redirect } from 'react-router-dom';
import { Can } from '../../../casl/abilityContext';
import { Action, Subject } from '../../../casl/caslConstants';

const AdminRoleCheck = ({ children, redirect }) => (
  <Can I={Action.MANAGE} a={Subject.ALL} passThrough>
    {allowed => (allowed ? <>{children}</> : <Redirect to={redirect ?? '/'} />)}
  </Can>
);

export default AdminRoleCheck;
