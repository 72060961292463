import DatasetFieldBuilder from '../data-center/datasetConfig/DatasetFieldBuilder';
import { HOUR_DIMENSION_TYPE } from '../workflow/workflowConstants';
import { createDimensions } from '../../data-access/mutation/dimensions';

/**
 * These are the enum values used in the database for each field type
 */
export const ConfigFieldName = {
  LOCATION: 'LOC',
  TRANSACTION_DATE: 'TRAN',
  ITEM_QUANTITY: 'QTY',
  PRICE_PER_UNIT: 'PPU',
  TOTAL_TRANSACTION_PRICE: 'TTP',
  REVENUE: 'REV',
  GRATUITY: 'TIP',
  PRODUCT_DIMENSION: 'DIM',
  DATE_FIELD: 'DATE',
  INFO_FIELD: 'INFO',
};

export const buildLocationField = formStateForField => {
  const { datasetFieldId, checkbox: isForOneLocation, fixedValue, fromColumn } = formStateForField;

  const datasetFieldBuilder = new DatasetFieldBuilder()
    .setDatasetFieldId(datasetFieldId)
    .setDatasetFieldName(ConfigFieldName.LOCATION);

  if (!isForOneLocation) {
    datasetFieldBuilder.setFromColumn(fromColumn.value);
  } else {
    const { location_identifier: locationIdentifier, location_description: locationDescription } = fixedValue.value;
    const locationValue = locationIdentifier ?? locationDescription;
    datasetFieldBuilder.setFixedValue(locationValue);
  }

  return datasetFieldBuilder.build();
};

export const buildTransactionDateField = formStateForField => {
  const {
    datasetFieldId,
    checkbox: isInDataset,
    checkbox_time: checkboxTime,
    fixedValue,
    fromColumn,
    timezone,
  } = formStateForField;

  const datasetFieldBuilder = new DatasetFieldBuilder()
    .setDatasetFieldId(datasetFieldId)
    .setDatasetFieldName(ConfigFieldName.TRANSACTION_DATE);

  if (isInDataset) {
    datasetFieldBuilder.setFromColumn(fromColumn.value);
  } else {
    datasetFieldBuilder.setFixedValue(fixedValue);
  }

  if (checkboxTime) {
    datasetFieldBuilder.setTimezone(timezone?.value);
  }

  return datasetFieldBuilder.build();
};

export const buildItemQuantityField = formStateForField => {
  const { datasetFieldId, checkbox: isInDataset, fixedValue, fromColumn } = formStateForField;

  const datasetFieldBuilder = new DatasetFieldBuilder()
    .setDatasetFieldId(datasetFieldId)
    .setDatasetFieldName(ConfigFieldName.ITEM_QUANTITY);

  if (isInDataset) {
    datasetFieldBuilder.setFromColumn(fromColumn.value);
  } else {
    datasetFieldBuilder.setFixedValue(fixedValue);
  }

  return datasetFieldBuilder.build();
};

export const buildPriceField = formStateForField => {
  const { datasetFieldId, checkbox: isInDataset, fixedValue, fromColumn, priceType } = formStateForField;

  const datasetFieldBuilder = new DatasetFieldBuilder()
    .setDatasetFieldId(datasetFieldId)
    .setDatasetFieldName(priceType);

  if (isInDataset) {
    datasetFieldBuilder.setFromColumn(fromColumn.value);
  } else {
    datasetFieldBuilder.setFixedValue(fixedValue);
  }

  return datasetFieldBuilder.build();
};

const createNewDimension = async (name, dimensionType) => {
  const { data } = await createDimensions({
    dimensions: [
      {
        name,
        dimension_type: dimensionType,
      },
    ],
  });
  const [createdDimension] = data;

  const insertId = createdDimension.product_dimension_id;

  return insertId;
};

export const buildUserDimFields = async formStateForFields => {
  const dimFieldPromises = formStateForFields.map(async itemDescriptorFormState => {
    const { datasetFieldId, checkbox: isInDataset, fixedValue, fromColumn, descriptorName } = itemDescriptorFormState;

    const datasetFieldBuilder = new DatasetFieldBuilder()
      .setDatasetFieldId(datasetFieldId)
      .setDatasetFieldName(ConfigFieldName.PRODUCT_DIMENSION);

    if (isInDataset) {
      datasetFieldBuilder.setFromColumn(fromColumn.value);
    } else {
      datasetFieldBuilder.setFixedValue(fixedValue);
    }

    let productDimensionId = descriptorName?.value?.product_dimension_id;

    const dimensionAlreadyExists = productDimensionId != null;
    if (!dimensionAlreadyExists) {
      const dimensionName = descriptorName?.value;

      productDimensionId = await createNewDimension(dimensionName, 'USER');
    }

    datasetFieldBuilder.setProductDimensionId(productDimensionId);

    return datasetFieldBuilder.build();
  });

  const dimFields = (await Promise.allSettled(dimFieldPromises))
    .filter(result => result.status === 'fulfilled')
    .map(result => result.value);

  return dimFields;
};

export const buildHourDimField = async existingHourDimId => {
  let hourDimId = existingHourDimId;
  if (existingHourDimId == null) {
    hourDimId = await createNewDimension('Hour', HOUR_DIMENSION_TYPE);
  }

  const hourDimField = new DatasetFieldBuilder()
    .setDatasetFieldName(ConfigFieldName.PRODUCT_DIMENSION)
    .setProductDimensionId(hourDimId)
    .build();

  return hourDimField;
};
