import _ from 'lodash';
import classnames from 'classnames';
import { addDays, format, formatISO, isSameDay, getYear, startOfToday } from 'date-fns';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCalendarDay,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useCollapse from 'react-collapsed';
import {
  DEMAND_METRIC,
  GROSS_SALES_PLUS_TIPS_METRIC,
  INCOME_METRIC,
  METRIC_OPTIONS,
  TIPS_METRIC,
} from './atAGlanceTabConstants';
import AtAGlanceTabContext from './atAGlanceTabContext';
import { GaugeLevel, GaugeLevelToIcon, formatNumber, useHourlyGridData } from './atAGlanceTabUtil';
import DayDetailsSection, { dateTimeStrToJavaScriptKey } from './DayDetailsSection';
import HistoricalPeriodsSection from './HistoricalPeriodsSection';
import { LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { getDimColumnKey, useAtAGlanceData } from './revenueUpliftDashboardComputations';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import TopPricingRecsSection from './TopPricingRecsSection';
import { ALL_HOURS } from '../../../accountSettings/locationSettings/locationManagementConstants';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { compactFormatNumber } from '../../../util/format';
import { FONT_BLACK } from '../../../../colors';
import { useCustomEventsWithFullRanges } from '../../../../data-access/query/customEvents';
import { useHolidaysForAllLocations } from '../../../../data-access/query/kdl/holidays';
import {
  gaEmitExpandAngleArrowClick,
  gaEmitMetricDropdownClick,
  gaEmitOneDayBackControlClick,
  gaEmitOneDayForwardControlClick,
  gaEmitOneWeekBackControlClick,
  gaEmitOneWeekForwardControlClick,
} from '../../../../google-analytics/atAGlanceTab';
import './at-a-glance-tab.scss';

const NumberWithGauge = ({ value, gaugeLevel }) => {
  const icon = GaugeLevelToIcon[gaugeLevel ?? GaugeLevel.NONE];

  return (
    <div className="number-with-gauge">
      <strong className="value">{value}</strong>
      <div className="gauge">{icon}</div>
    </div>
  );
};

const DateControl = ({ icon, tooltipText, onClick, disabled }) => {
  return (
    <ProfitRoverTooltip
      shouldDisplayTooltip
      placement="bottom"
      tooltipText={tooltipText}
      tooltipClass={classnames('control-tooltip', { 'control-disabled': disabled })}
      delay={{ show: 200, hide: 100 }}
    >
      <button type="button" className="date-control" onClick={onClick} disabled={disabled}>
        {icon}
      </button>
    </ProfitRoverTooltip>
  );
};

// Returns an Array like ['Thurs', 'Jan 5']
const getTimeLabels = date => [format(date, 'EEE'), format(date, 'MMM d')];

const getOpenHoursUnion = (locations, dayOfWeek, openHoursPerDayPerLocation) => {
  const hoursOpen = [];

  locations.forEach(({ location_id: locationId }) => {
    const locationHours = openHoursPerDayPerLocation[locationId];
    if (!locationHours) {
      // No hours of operation defined
      hoursOpen.push(...ALL_HOURS);
    } else {
      const dayHours = locationHours[dayOfWeek] ?? [];
      hoursOpen.push(...dayHours);
    }
  });

  return _.uniq(hoursOpen);
};

const ForecastDay = ({
  date,
  isSelected,
  metric,
  metricGaugeLevel,
  hourlyGridData,
  collapseProps,
  onClick,
  openHoursPerDayPerLocation,
}) => {
  const { holidaysKeyedByDate, sortedLocations } = React.useContext(AtAGlanceTabContext);
  const [dayOfWeekLabel, dateLabel] = getTimeLabels(date);

  const { data: customEventsData = [] } = useCustomEventsWithFullRanges();

  const allEvents = React.useMemo(() => {
    const holidays = (holidaysKeyedByDate[date] ?? []).map(h => h.description);
    const selectedLocationIds = sortedLocations.map(l => l.location_id);

    const events = customEventsData
      .filter(e => selectedLocationIds.includes(e.location_id) && isSameDay(e.eventDate, date))
      .map(e => e.description);

    return _.uniq([...holidays, ...events]);
  }, [customEventsData, date, sortedLocations, holidaysKeyedByDate]);

  const openHoursUnion = React.useMemo(() => {
    const dayOfWeek = format(date, 'EEEE');
    return getOpenHoursUnion(sortedLocations, dayOfWeek, openHoursPerDayPerLocation);
  }, [sortedLocations, date, openHoursPerDayPerLocation]);

  const hourRange = hourlyGridData.range ?? [];
  const shouldShowHourData = hourRange.length > 0;
  const isClosed = openHoursUnion.length === 0;

  const textElementRef = React.useRef(null);
  const [isOverflown, setIsOverflown] = React.useState(false);

  React.useEffect(() => {
    const compareSize = () => {
      const element = textElementRef.current;

      if (!element) {
        return;
      }

      const compare = element
        ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
        : false;

      setIsOverflown(compare);
    };

    compareSize();
  }, [textElementRef]);

  return (
    <div className="d-flex flex-column justify-content-end">
      {allEvents.length === 1 && (
        <div className="holiday-label mb-1">
          <FontAwesomeIcon icon={faCalendarDay} color={FONT_BLACK} />
          <ProfitRoverTooltip
            shouldDisplayTooltip={isOverflown}
            placement="bottom"
            tooltipText={allEvents[0]}
            delay={{ show: 200, hide: 0 }}
          >
            <span ref={textElementRef} className="holiday-label-text ml-1">
              {allEvents[0]}
            </span>
          </ProfitRoverTooltip>
        </div>
      )}
      {allEvents.length > 1 && (
        <ProfitRoverTooltip
          shouldDisplayTooltip
          placement="bottom"
          tooltipText={allEvents.join(', ')}
          tooltipClass="holiday-tooltip"
          delay={{ show: 200, hide: 100 }}
        >
          <div className="holiday-label mb-1">
            <FontAwesomeIcon icon={faCalendarDay} color={FONT_BLACK} />
            <span className="holiday-label-text ml-1">{allEvents.length} Holidays / Events</span>
          </div>
        </ProfitRoverTooltip>
      )}
      <button type="button" className={classnames('forecast-day-btn', { active: isSelected })} onClick={onClick}>
        <div className="upper d-flex align-items-end justify-content-between">
          <strong>{dayOfWeekLabel}</strong>
          <div className="date-label">{dateLabel}</div>
        </div>
        <div className="lower">
          {isClosed ? (
            <div className="closed">Closed</div>
          ) : (
            <NumberWithGauge value={metric} gaugeLevel={metricGaugeLevel} />
          )}
        </div>
      </button>
      {shouldShowHourData && (
        <div className={classnames('hour-col', { active: isSelected })} {...collapseProps}>
          {hourRange.map(hour => {
            const day = formatISO(date, { representation: 'date' });
            const value = hourlyGridData.getValueByDayAndHour(day, hour) ?? '-';
            const opacity = hourlyGridData.getOpacityByDayAndHour(day, hour) ?? 0;

            return (
              <div
                key={hour}
                className={classnames('hour-value', { closed: !openHoursUnion.includes(hour) })}
                style={{ background: `rgba(14, 145, 12, ${opacity * 0.8})` }}
              >
                {openHoursUnion.includes(hour) ? value : '-'}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const DEFAULT_GAUGES = {
  revenue: GaugeLevel.NONE,
  demand: GaugeLevel.NONE,
};
const DEFAULT_STATS_GAUGES = {
  tipsGauge: GaugeLevel.NONE,
  revenuePlusTipsGauge: GaugeLevel.NONE,
};

const MIN_WIDTH_TO_SHOW_ALL_DAYS = 1460;

const AtAGlanceTabContextController = ({ children }) => {
  const { isPriceOptimizationProfit, locations } = React.useContext(DomainContext);
  const { hasForecastStats, selectionState } = React.useContext(DashboardContext);

  const availableMetricOptions = React.useMemo(() => {
    let options = METRIC_OPTIONS;
    if (isPriceOptimizationProfit) {
      options = options.map(option => ({ ...option, label: option.value === INCOME_METRIC ? 'Profit' : option.label }));
    }
    return options;
  }, [isPriceOptimizationProfit]);

  const [selectedMetric, setSelectedMetric] = React.useState(availableMetricOptions[0]);

  const metricOptions = React.useMemo(() => {
    if (!hasForecastStats) {
      return availableMetricOptions.filter(
        option => option.value !== TIPS_METRIC && option.value !== GROSS_SALES_PLUS_TIPS_METRIC,
      );
    }
    return availableMetricOptions;
  }, [hasForecastStats, availableMetricOptions]);

  const sortedLocations = React.useMemo(() => {
    const locationsFilterState = selectionState[LOCATIONS_DIMENSION_ID];

    const isUnselected = ({ isSelected }) => !isSelected;
    if (_.every(locationsFilterState, isUnselected)) {
      // If all locations are filtered out, show all of them in the dropdown
      return _.sortBy(locations, 'location_description');
    }

    // Otherwise, only show locations in the dropdown that are not filtered out
    const selectedLocations = locations.filter(location => {
      const filterStateForLoc = locationsFilterState.find(
        ({ dimensionValueId }) => location.location_id === dimensionValueId,
      );

      return filterStateForLoc != null && filterStateForLoc.isSelected;
    });

    return _.sortBy(selectedLocations, 'location_description');
  }, [locations, selectionState]);

  const yearOfRightNow = React.useMemo(() => getYear(new Date()), []);
  const { data: holidayData = [], isLoading: isLoadingHolidayData } = useHolidaysForAllLocations(locations, {
    startYear: yearOfRightNow,
    endYear: yearOfRightNow + 1,
  });

  const holidaysKeyedByDate = React.useMemo(() => {
    const fillInDate = startOfToday();

    const selectedLocationsCodes = sortedLocations.map(l => {
      return { countryCode: l.country_code, stateCode: l.state };
    });

    const holidays = holidayData
      .filter(h => {
        return selectedLocationsCodes.some(l => {
          return l.countryCode === h.country_code && (!h.sub_region_code || l.stateCode === h.sub_region_code);
        });
      })
      .map(holiday => {
        return {
          description: holiday.holiday_name,
          date: dateTimeStrToJavaScriptKey(holiday.holiday_date, fillInDate),
          countryCode: holiday.country_code,
          stateCode: holiday.sub_region_code,
        };
      });

    return _.groupBy(holidays, 'date');
  }, [holidayData, sortedLocations]);

  const atAGlanceTabContextValue = {
    selectedMetric,
    setSelectedMetric,
    metricOptions,
    sortedLocations,
    holidaysKeyedByDate,
    isLoadingHolidayData,
  };

  return <AtAGlanceTabContext.Provider value={atAGlanceTabContextValue}>{children}</AtAGlanceTabContext.Provider>;
};

const AtAGlanceTab = ({ showExperimentTab, isActive }) => {
  const {
    currencySymbol,
    historySummaryStatsRows,
    paceHistoryRows,
    sevenDayForecastApi,
    openHoursPerDayPerLocation,
  } = React.useContext(DashboardContext);
  const { selectedMetric, setSelectedMetric, metricOptions } = React.useContext(AtAGlanceTabContext);
  const { sevenDayForecastRows, sevenDayForecastStatsRows } = sevenDayForecastApi;

  const onSelectedMetricChange = metric => {
    gaEmitMetricDropdownClick(metric.value);
    setSelectedMetric(metric);
  };

  const { getCollapseProps, getToggleProps, isExpanded: showDetails } = useCollapse({ duration: 200 });
  const collapseProps = getCollapseProps();

  React.useEffect(() => {
    if (showDetails) {
      gaEmitExpandAngleArrowClick();
    }
  }, [showDetails]);

  const {
    revenueByDay,
    demandByDay,
    tipsByDay,
    gaugesByDay,
    statsGaugesByDay,
    totalForecastedRevenue,
    totalForecastedDemand,
    totalForecastedTips,
  } = useAtAGlanceData(sevenDayForecastRows, paceHistoryRows, sevenDayForecastStatsRows, historySummaryStatsRows);

  const {
    forecastStartDate,
    selectedDate,
    setSelectedDate,
    minDayReached,
    maxDayReached,
    onJumpToStart,
    onBackOneDay,
    onForwardOneDay,
    onBackRow,
    onForwardRow,
  } = sevenDayForecastApi;

  const onOneWeekBack = () => {
    gaEmitOneWeekBackControlClick();
    onBackRow();
  };
  const onOneDayBack = () => {
    gaEmitOneDayBackControlClick();
    onBackOneDay();
  };
  const onOneDayForward = () => {
    gaEmitOneDayForwardControlClick();
    onForwardOneDay();
  };
  const onOneWeekForward = () => {
    gaEmitOneWeekForwardControlClick();
    onForwardRow();
  };

  const hourlyGridData = useHourlyGridData(getDimColumnKey);

  const formattedTotalRev = formatNumber(totalForecastedRevenue, {
    currencySymbol,
    hideCents: true,
  });
  const formattedTotalDem = formatNumber(totalForecastedDemand, { hideCents: true });
  const formattedTotalTips = formatNumber(totalForecastedTips, { currencySymbol, hideCents: true });
  const formattedRevPlusTips = formatNumber(totalForecastedRevenue + totalForecastedTips, {
    currencySymbol,
    hideCents: true,
  });
  const [, startDateLabel] = getTimeLabels(forecastStartDate);
  const [, endDateLabel] = getTimeLabels(addDays(forecastStartDate, 6));

  const getDisplayedMetric = () => {
    switch (selectedMetric.value) {
      case INCOME_METRIC:
        return formattedTotalRev;
      case DEMAND_METRIC:
        return formattedTotalDem;
      case TIPS_METRIC:
        return formattedTotalTips;
      case GROSS_SALES_PLUS_TIPS_METRIC:
        return formattedRevPlusTips;
      default:
        return formattedTotalRev;
    }
  };

  const daysRange = React.useMemo(() => {
    return [0, 1, 2, 3, 4, 5, 6].map(i => {
      const date = addDays(forecastStartDate, i);
      const dateKey = formatISO(date, { representation: 'date' });

      const getMetric = () => {
        const revenue = revenueByDay[dateKey] ?? 0;
        const demand = demandByDay[dateKey] ?? 0;
        const tips = tipsByDay[dateKey] ?? 0;

        if (selectedMetric.value === INCOME_METRIC) {
          return compactFormatNumber(revenue, {
            formatAsCurrency: true,
            currencySymbol,
          });
        }
        if (selectedMetric.value === DEMAND_METRIC) {
          return compactFormatNumber(demand, { hideCents: true });
        }
        if (selectedMetric.value === TIPS_METRIC) {
          return compactFormatNumber(tips, {
            formatAsCurrency: true,
            currencySymbol,
          });
        }
        if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC) {
          return compactFormatNumber(revenue + tips, {
            formatAsCurrency: true,
            currencySymbol,
          });
        }

        return '-';
      };

      const getGauge = () => {
        const { revenueGauge, demandGauge } = gaugesByDay[dateKey] ?? DEFAULT_GAUGES;
        const { tipsGauge, revenuePlusTipsGauge } = statsGaugesByDay[dateKey] ?? DEFAULT_STATS_GAUGES;

        if (selectedMetric.value === INCOME_METRIC) {
          return revenueGauge;
        }
        if (selectedMetric.value === DEMAND_METRIC) {
          return demandGauge;
        }
        if (selectedMetric.value === TIPS_METRIC) {
          return tipsGauge;
        }
        if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC) {
          return revenuePlusTipsGauge;
        }

        return GaugeLevel.NONE;
      };

      return {
        date,
        dateKey,
        metric: getMetric(),
        gauge: getGauge(),
      };
    });
  }, [
    forecastStartDate,
    currencySymbol,
    demandByDay,
    gaugesByDay,
    statsGaugesByDay,
    revenueByDay,
    tipsByDay,
    selectedMetric,
  ]);

  const hourRange = hourlyGridData.range ?? [];
  const shouldShowHourData = hourRange.length > 0;

  return (
    <div className="at-a-glance">
      <div className="filters-container px-3">
        <p className="metric-label">Metric:</p>
        <div style={{ minWidth: 180 }} className="ml-2 mr-4">
          <RevenueUpliftSelect options={metricOptions} onChange={onSelectedMetricChange} value={selectedMetric} />
        </div>
      </div>
      <div className="at-a-glance-content container-fluid" style={{ maxWidth: MIN_WIDTH_TO_SHOW_ALL_DAYS }}>
        <h5 className="mb-2">7-Day Business Forecast (At Recent Prices)</h5>
        <ProfitRoverCard className="top-section">
          <div className="d-flex">
            <div className="d-flex mb-3 flex-wrap" style={{ rowGap: 15 }}>
              <div className="total-stat">
                <p className="label">7-Day Total:</p>
                <div className="d-flex flex-column align-items-center ml-4">
                  <div className="day-range">
                    {startDateLabel} - {endDateLabel}
                  </div>
                  <div className="number">{getDisplayedMetric()}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('d-flex align-items-center mb-1 date-control-list', {
              'hour-label-margin': shouldShowHourData,
            })}
          >
            <DateControl icon="Today" tooltipText="Go Back to Today" onClick={onJumpToStart} disabled={minDayReached} />
            <DateControl
              icon={<FontAwesomeIcon icon={faAngleDoubleLeft} color={FONT_BLACK} />}
              tooltipText="Go Back 7 Days"
              onClick={onOneWeekBack}
              disabled={minDayReached}
            />
            <DateControl
              icon={<FontAwesomeIcon icon={faAngleLeft} color={FONT_BLACK} />}
              tooltipText="Go Back 1 Day"
              onClick={onOneDayBack}
              disabled={minDayReached}
            />
            <DateControl
              icon={<FontAwesomeIcon icon={faAngleRight} color={FONT_BLACK} />}
              tooltipText="Go Forward 1 Day"
              onClick={onOneDayForward}
              disabled={maxDayReached}
            />
            <DateControl
              icon={<FontAwesomeIcon icon={faAngleDoubleRight} color={FONT_BLACK} />}
              tooltipText="Go Forward 7 Days"
              onClick={onOneWeekForward}
              disabled={maxDayReached}
            />
          </div>
          <div className="d-flex flex-row align-items-end">
            {shouldShowHourData && (
              <div className="d-flex flex-column hour-labels" {...collapseProps}>
                {hourRange.map(hour => (
                  <div key={hour} className="hour-label">
                    {hour}
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex week-list">
              {daysRange.map(({ date, dateKey, metric, gauge }) => (
                <ForecastDay
                  key={dateKey}
                  date={date}
                  isSelected={isSameDay(date, selectedDate)}
                  metric={metric}
                  metricGaugeLevel={gauge}
                  hourlyGridData={hourlyGridData}
                  collapseProps={collapseProps}
                  onClick={() => setSelectedDate(date)}
                  openHoursPerDayPerLocation={openHoursPerDayPerLocation}
                />
              ))}
            </div>
          </div>
          <DayDetailsSection
            sevenDayForecastRows={sevenDayForecastRows}
            weeklyForecastStatsRows={sevenDayForecastStatsRows}
            selectedDate={selectedDate}
            collapseProps={collapseProps}
          />
          <div className="d-flex justify-content-center mt-3">
            <button
              type="button"
              className={classnames('expand-details-btn', { 'show-details': showDetails })}
              {...getToggleProps()}
            >
              <FontAwesomeIcon icon={faAngleDown} color={FONT_BLACK} />
            </button>
          </div>
        </ProfitRoverCard>
        <HistoricalPeriodsSection />
        <TopPricingRecsSection showPricingTab={showExperimentTab} isActive={isActive} />
      </div>
    </div>
  );
};

const AtAGlanceTabContainer = ({ showPricingTab, isActive }) => {
  return (
    <AtAGlanceTabContextController>
      <AtAGlanceTab showExperimentTab={showPricingTab} isActive={isActive} />
    </AtAGlanceTabContextController>
  );
};

export default AtAGlanceTabContainer;
