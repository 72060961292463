import _ from 'lodash';
import React from 'react';
import { DataSource, OAUTH_DATASOURCES, isAncillaryDataSource } from './dataSourceConstants';
import DatabaseDataSourceRow from './DatabaseDataSourceRow';
import { DataSourceBase } from './DataSourceRowCommon';
import ManualDataSourceRow from './ManualDataSourceRow';
import OauthDataSourceRow from './OauthDataSourceRow';
import ShopifyDataSourceRow from './ShopifyDataSourceRow';
import LoadingIndicator from '../accountSettings/common/LoadingIndicator';

export const LoadingDataSource = () => (
  <DataSourceBase label={<LoadingIndicator />} timestamp={<LoadingIndicator />} actions={null} />
);

const IntegrationDataSourceRow = ({ datasetConfig, datasets }) => {
  const { partner, dataset_config_id: datasetConfigId } = datasetConfig;
  const [dataset] = datasets;
  const { dataset_id: datasetId } = dataset ?? {};

  if (isAncillaryDataSource(datasetConfig)) {
    localStorage.setItem(`${_.lowerCase(partner)}_redirect_dataset_config_ancillary_id`, datasetConfigId);
    localStorage.setItem(`${_.lowerCase(partner)}_redirect_dataset_ancillary_id`, datasetId);
  } else {
    localStorage.setItem(`${_.lowerCase(partner)}_redirect_dataset_config_id`, datasetConfigId);
    localStorage.setItem(`${_.lowerCase(partner)}_redirect_dataset_id`, datasetId);
  }

  if (OAUTH_DATASOURCES.includes(partner)) {
    return <OauthDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  if (partner === DataSource.SHOPIFY) {
    return <ShopifyDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  return null;
};

const DataSourceRow = props => {
  const { datasetConfig, datasets } = props;
  const { partner } = datasetConfig;
  const isDBSource = datasets.filter(dataset => dataset.location_url_type === 'DB').length > 0;

  // For integrations
  if (partner != null) {
    return <IntegrationDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  // For database sources (Snowflake, etc)
  if (isDBSource) {
    return <DatabaseDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  // For CSV/XLSX files
  return <ManualDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
};

export default DataSourceRow;
