import { DataType } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import { DataSource } from '../../dataSources/dataSourceConstants';
import { ConfigFieldName } from '../../dataSources/fieldBuilders';
import { HOUR_DIMENSION_TYPE, USER_DIMENSION_TYPE } from '../../workflow/workflowConstants';

export const PARTNER_FIELDS = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'unitPrice' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created' },
    ],
    [DataType.GRATUITY]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created' },
      { dataset_field_name: ConfigFieldName.REVENUE, from_column: 'tippedSubtotal' },
      { dataset_field_name: ConfigFieldName.GRATUITY, from_column: 'tipAmount' },
    ],
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'tran_date' },
    ],
    [DataType.GRATUITY]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'tran_date' },
      { dataset_field_name: ConfigFieldName.REVENUE, from_column: 'total_amt' },
      { dataset_field_name: ConfigFieldName.GRATUITY, from_column: 'gratuity' },
    ],
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.LOCATION, from_column: 'location_id' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created_at' },
      { dataset_field_name: ConfigFieldName.DATE_FIELD, from_column: 'processed_at' },
      { dataset_field_name: ConfigFieldName.DATE_FIELD, from_column: 'updated_at' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'item_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'shopify_product_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'financial_status' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'order_number' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'order_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'sku' },
    ],
  },
};

export const PRODUCT_DIMENSION = {
  name: 'Product',
  dimension_type: USER_DIMENSION_TYPE,
};

export const HOUR_DIMENSION = {
  name: 'Hour',
  dimension_type: HOUR_DIMENSION_TYPE,
};

export const PRODUCT_TYPE_DIMENSION = {
  name: 'Product Type',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_CATEGORY_DIMENSION = {
  name: 'Category',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_VARIATION_DIMENSION = {
  name: 'Variation',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_OPTIONS_DIMENSION = {
  name: 'Options',
  dimension_type: USER_DIMENSION_TYPE,
};

// KP-4048 - add this so that multiple datasources can coexist and share dimensions
export const PARTNER_DIM_ALIASES = {
  [PRODUCT_VARIATION_DIMENSION.name]: PRODUCT_OPTIONS_DIMENSION,
  [PRODUCT_OPTIONS_DIMENSION.name]: PRODUCT_VARIATION_DIMENSION,
};

export const PARTNER_DIMS = {
  [DataSource.GOTAB]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, PRODUCT_OPTIONS_DIMENSION, HOUR_DIMENSION],
  [DataSource.SQUARE]: [
    PRODUCT_DIMENSION,
    PRODUCT_TYPE_DIMENSION,
    PRODUCT_CATEGORY_DIMENSION,
    PRODUCT_VARIATION_DIMENSION,
    HOUR_DIMENSION,
  ],
  [DataSource.SHOPIFY]: [PRODUCT_DIMENSION, PRODUCT_VARIATION_DIMENSION, HOUR_DIMENSION],
};

export const PARTNER_DIMS_DATATYPE = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, PRODUCT_OPTIONS_DIMENSION, HOUR_DIMENSION],
    [DataType.GRATUITY]: [HOUR_DIMENSION],
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: [
      PRODUCT_DIMENSION,
      PRODUCT_TYPE_DIMENSION,
      PRODUCT_CATEGORY_DIMENSION,
      PRODUCT_VARIATION_DIMENSION,
      HOUR_DIMENSION,
    ],
    [DataType.GRATUITY]: [HOUR_DIMENSION],
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, PRODUCT_VARIATION_DIMENSION, HOUR_DIMENSION],
  },
};

export const shouldCreateTipForecast = partner => PARTNER_DIMS_DATATYPE[partner][DataType.GRATUITY] !== undefined;

export const PARTNER_DIM_FROM_COLUMN = {
  [DataSource.GOTAB]: {
    [PRODUCT_DIMENSION.name]: 'product_name',
    [PRODUCT_CATEGORY_DIMENSION.name]: 'product_category_name',
    [PRODUCT_OPTIONS_DIMENSION.name]: 'product_option',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.SQUARE]: {
    [PRODUCT_DIMENSION.name]: 'product',
    [PRODUCT_TYPE_DIMENSION.name]: 'item_type',
    [PRODUCT_CATEGORY_DIMENSION.name]: 'category',
    [PRODUCT_VARIATION_DIMENSION.name]: 'variation',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.SHOPIFY]: {
    [PRODUCT_DIMENSION.name]: 'product',
    [PRODUCT_VARIATION_DIMENSION.name]: 'variant',
    [HOUR_DIMENSION.name]: null,
  },
};

export const PARTNER_CONFIG_NAME = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: 'Tabs/Orders/Items',
    [DataType.GRATUITY]: 'Tab Gratuity',
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: 'Order Items',
    [DataType.GRATUITY]: 'Order Gratuity',
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: 'Order Items',
  },
};
