import React from 'react';
import HeaderAwarePage from '../HeaderAwarePage';
import './generic-error-boundary.scss';

/**
 * Example Usage:
 *
 * <GenericErrorBoundaryPage showHeader={false}>
 *   <MessageBubble>
 *     <Title>Error</Title>
 *     <Message>Please try again later.</Message>
 *     <div className="buttons">
 *       <ProfitRoverPrimaryButton onClick={resetErrorBoundary}>Try Again</ProfitRoverPrimaryButton>
 *     </div>
 *   </MessageBubble>
 * </GenericErrorBoundaryPage>
 */

export const MessageBubble = ({ children, ...props }) => (
  <div className="message-bubble" {...props}>
    {children}
  </div>
);

export const Title = ({ children, ...props }) => (
  <h4 className="error-boundary-title" {...props}>
    {children ?? 'An error has occurred'}
  </h4>
);

export const Message = ({ children, ...props }) => (
  <p className="error-boundary-message" {...props}>
    {children ?? 'We encountered an unexpected error. Please contact our support team.'}
  </p>
);

const GenericErrorBoundaryPage = ({ children, ...props }) => {
  return (
    <HeaderAwarePage {...props} scrollable={false}>
      <div
        className="generic-error-boundary-page d-flex align-items-center justify-content-center"
        style={{ height: '100%' }}
      >
        {children}
      </div>
    </HeaderAwarePage>
  );
};

export default GenericErrorBoundaryPage;
