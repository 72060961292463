import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodash from 'lodash';
import PriceFilters from './PriceFilters';
import PriceRuleTable from './PriceRuleTable';
import PriceModal from './PriceModal';
import SideBar from '../sidebar';
import { ProfitRoverDestructiveButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import Header from '../../header/header';
import { arrayParamSerialization } from '../../util/format';
import { KA_API_URL } from '../../../config/baseUrl';
import './PriceRules.css';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 5em;
  margin-left: 18em;
  margin-right: 6em;
`;

class PriceRules extends Component {
  state = {
    priceRules: [],
    finalPriceRules: [],
    dataAvailable: true,
    allLocations: [],
    allProducts: [],
    allPriceRules: [],
    selectedLocations: null,
    selectedProducts: null,
    selectedPriceRules: null,
    error: false,
    modalError: false,
    errorMessage: '',
    updatePriceRulesMap: new Map(),
    priceRuleTypes: [],
    priceRuleTypesMap: new Map(),
    singleRowEditSet: new Set(),
    singleRowUpdateMap: new Map(),
    multiRowEditMap: new Map(),
    openEditModal: false,
    multiPriceType: '',
    multiPriceValue: '',
    isAllSelected: false,
    multiRowDeleteSet: new Set(),
    defaultSortArray: ['location_description', 'product_description'],
    currentSortProp: '',
    sortObject: {
      location_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      product_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      price_rule_description: {
        is_sorted: false,
        direction: 'ASC',
      },
      value: {
        is_sorted: false,
        direction: 'ASC',
      },
    },
  };

  async componentDidMount() {
    await this.renderPriceTableAndFilters();
  }

  calculateSortData = async id => {
    const { currentSortProp, sortObject, priceRules } = this.state;
    if (currentSortProp !== '' && currentSortProp !== id) {
      sortObject[currentSortProp].is_sorted = false;
    }
    if (sortObject[id].is_sorted === false) {
      sortObject[id].is_sorted = true;
      sortObject[id].direction = 'ASC';
    } else if (sortObject[id].direction === 'ASC') {
      sortObject[id].direction = 'DESC';
    } else {
      sortObject[id].direction = 'ASC';
    }

    await this.setState({
      sortObject,
      currentSortProp: id,
    });

    const sortedPriceRules = await this.getSortedArray(priceRules);

    await this.setState({
      priceRules: sortedPriceRules,
    });
  };

  getSortedArray = async data => {
    const { currentSortProp, sortObject, defaultSortArray } = this.state;

    const sortBy = [];

    if (currentSortProp !== '') {
      const { direction } = sortObject[currentSortProp];
      sortBy.push({
        prop: currentSortProp,
        direction: direction === 'ASC' ? 1 : -1,
      });
    }

    const clonedSortArray = lodash.cloneDeep(defaultSortArray);

    let modifiedSortArray = [];
    if (currentSortProp !== '') {
      modifiedSortArray = clonedSortArray.filter(d => d !== currentSortProp);
    } else {
      modifiedSortArray = clonedSortArray;
    }
    modifiedSortArray.forEach(d => {
      sortBy.push({
        prop: d,
        direction: 1,
      });
    });

    const sortedResult = data.sort((a, b) => {
      let i = 0;
      let result = 0;
      while (i < sortBy.length && result === 0) {
        let aModified = '';
        let bModified = '';
        if (sortBy[i].prop === 'price_rule_description') {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : '';
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : '';
        } else if (sortBy[i].prop === 'value') {
          aModified = a[sortBy[i].prop] !== null ? a[sortBy[i].prop] : 0;
          bModified = b[sortBy[i].prop] !== null ? b[sortBy[i].prop] : 0;
        } else {
          aModified = a[sortBy[i].prop];
          bModified = b[sortBy[i].prop];
        }

        result = sortBy[i].direction * (aModified < bModified ? -1 : aModified > bModified ? 1 : 0);
        i += 1;
      }
      return result;
    });

    return sortedResult;
  };

  renderPriceTableAndFilters = async () => {
    try {
      const priceRulesResponse = await axios.get(`${KA_API_URL}/pricerule`);
      const priceRuleTypesResponse = await axios.get(`${KA_API_URL}/pricerule/types`);
      const priceRuleTypes = priceRuleTypesResponse.data;

      const productClassesResponse = await axios.get(`${KA_API_URL}/products/productClass`);
      const productClasses = [];
      productClassesResponse.data.map(productClass => {
        if (productClass.product_class !== 'ATTEND') {
          productClasses.push(productClass.product_class);
        }
        return true;
      });

      const param = arrayParamSerialization({
        productClasses,
      });

      const locationsResponse = await axios.get(`${KA_API_URL}/locations`);

      const productMappingsResponse = await axios.get(`${KA_API_URL}/productmapping`, {
        params: {
          product_classes: param.productClasses,
        },
      });

      const locationsMap = new Map();
      const priceRuleTypesMap = new Map();
      const locations = locationsResponse.data;

      priceRuleTypes.map(priceRule => {
        priceRuleTypesMap.set(priceRule.price_rule, priceRule.description);
        return true;
      });
      locations.map(location => {
        locationsMap.set(location.location_id, location.location_description);
        return true;
      });

      const priceRules = [];
      const tempPriceRules = priceRulesResponse.data;
      const productMappings = productMappingsResponse.data;
      const priceRulesMap = new Map();
      const productIdSet = new Set();
      tempPriceRules.map(priceRule => {
        priceRulesMap.set(`${priceRule.location_id}${priceRule.product_id}`, priceRule);
        return true;
      });

      productMappings.map(mapping => {
        if (!productIdSet.has(mapping.product_id)) {
          productIdSet.add(mapping.product_id);
          priceRules.push({
            location_id: mapping.location_id,
            location_description: locationsMap.get(mapping.location_id),
            product_id: mapping.product_id,
            product_description: mapping.description,
            price_rule: priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`)
              ? priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`).price_rule
              : null,
            price_rule_description: priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`)
              ? priceRuleTypesMap.get(priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`).price_rule)
              : null,
            price_rule_id: priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`)
              ? priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`).price_rule_id
              : null,
            value: priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`)
              ? priceRulesMap.get(`${mapping.location_id}${mapping.product_id}`).value
              : null,
          });
        }
        return true;
      });

      const sortedPriceRules = await this.getSortedArray(priceRules);
      const finalPriceRules = lodash.cloneDeep(sortedPriceRules);

      await this.setState({
        priceRules: sortedPriceRules,
        finalPriceRules,
        priceRuleTypesMap,
        priceRuleTypes,
        dataAvailable: priceRules.length > 0,
      });

      this.calculateFilters(priceRules);
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'Problem fetching price rules. Please try later',
      });
    }
  };

  calculateFilters = async rules => {
    const locationsSet = new Set();
    const productsSet = new Set();
    const priceRulesSet = new Set();
    const locations = [];
    const products = [];
    const priceRules = [];

    rules.forEach(priceRule => {
      if (!locationsSet.has(priceRule.location_id)) {
        locations.push({ value: priceRule.location_id, label: priceRule.location_description });
        locationsSet.add(priceRule.location_id);
      }

      if (!productsSet.has(priceRule.product_description)) {
        products.push({ value: priceRule.product_description, label: priceRule.product_description });
        productsSet.add(priceRule.product_description);
      }

      if (priceRule.price_rule !== null && !priceRulesSet.has(priceRule.price_rule)) {
        priceRules.push({ value: priceRule.price_rule, label: priceRule.price_rule_description });
        priceRulesSet.add(priceRule.price_rule);
      }
    });

    locations.sort((a, b) => (a.label > b.label ? 1 : -1));
    products.sort((a, b) => (a.label > b.label ? 1 : -1));
    priceRules.sort((a, b) => (a.label > b.label ? 1 : -1));

    await this.setState({
      allLocations: locations,
      allProducts: products,
      allPriceRules: priceRules,
    });
  };

  handleFilterChange = async (selectedOption, propName) => {
    await this.setState({
      [propName]: selectedOption,
    });
  };

  applyFilters = async e => {
    e.preventDefault();
    const { finalPriceRules, selectedLocations, selectedProducts, selectedPriceRules } = this.state;

    let priceRules = lodash.cloneDeep(finalPriceRules);

    if (selectedLocations !== null && selectedLocations.length !== 0) {
      const locationsSet = new Set();
      selectedLocations.map(item => locationsSet.add(item.value));

      priceRules = priceRules.filter(item => {
        return locationsSet.has(item.location_id);
      });
    }

    if (selectedProducts !== null && selectedProducts.length !== 0) {
      const productsSet = new Set();
      selectedProducts.map(item => productsSet.add(item.value));

      priceRules = priceRules.filter(item => {
        return productsSet.has(item.product_description);
      });
    }

    if (selectedPriceRules !== null && selectedPriceRules.length !== 0) {
      const priceRulesSet = new Set();
      selectedPriceRules.map(item => priceRulesSet.add(item.value));
      priceRules = priceRules.filter(item => {
        return priceRulesSet.has(item.price_rule);
      });
    }

    const sortedPriceRules = await this.getSortedArray(priceRules);

    await this.setState({
      priceRules: sortedPriceRules,
      dataAvailable: sortedPriceRules.length > 0,
    });
  };

  openSingleRow = async (e, data) => {
    e.preventDefault();
    const { singleRowEditSet } = this.state;
    singleRowEditSet.add(`${data.location_id}${data.product_id}`);

    await this.setState({
      singleRowEditSet,
    });
  };

  handleSingleEditPriceRuleChange = async (e, data) => {
    const { singleRowUpdateMap } = this.state;

    let editObject = {};
    if (singleRowUpdateMap.get(`${data.original.location_id}${data.original.product_id}`)) {
      editObject = singleRowUpdateMap.get(`${data.original.location_id}${data.original.product_id}`);
    }

    if (e.target.name === 'editPriceType') {
      editObject.priceType = e.target.value;
    } else {
      editObject.priceValue = e.target.value;
    }
    singleRowUpdateMap.set(`${data.original.location_id}${data.original.product_id}`, editObject);
    await this.setState({
      singleRowUpdateMap,
    });
  };

  updateSingleRow = async (e, data) => {
    e.preventDefault();
    const { singleRowUpdateMap, singleRowEditSet } = this.state;
    if (singleRowUpdateMap.get(`${data.location_id}${data.product_id}`)) {
      const editObject = singleRowUpdateMap.get(`${data.location_id}${data.product_id}`);
      const priceTypeKey = 'priceType';
      const priceValueKey = 'priceValue';
      let type;
      let value;

      if (priceTypeKey in editObject) {
        type = editObject.priceType;
      } else {
        type = data.price_rule;
      }

      if (priceValueKey in editObject) {
        value = Math.abs(Number(editObject.priceValue));
      } else {
        const { value: editValue } = data;
        value = editValue;
      }

      if (value === '' || type === '' || value == null || type == null) {
        await this.setState({
          error: true,
          errorMessage: 'Please fill all the fields',
        });
        return;
      }

      const { updatePriceRulesMap } = this.state;
      updatePriceRulesMap.set(`${data.location_id}${data.product_id}`, {
        location_id: data.location_id,
        product_id: data.product_id,
        type,
        value,
      });

      await this.setState({
        updatePriceRulesMap,
        singleRowEditSet,
        error: false,
        errorMessage: '',
      });
    } else if (data.price_rule === null) {
      await this.setState({
        error: true,
        errorMessage: 'Please fill all the fields',
      });
      return;
    }

    singleRowEditSet.delete(`${data.location_id}${data.product_id}`);
    await this.setState({
      singleRowEditSet,
    });
  };

  handleCheck = async data => {
    const { multiRowEditMap, multiRowDeleteSet } = this.state;
    if (multiRowEditMap.get(`${data.location_id}${data.product_id}`)) {
      multiRowEditMap.delete(`${data.location_id}${data.product_id}`);
      multiRowDeleteSet.delete(data.price_rule_id);
    } else {
      multiRowEditMap.set(`${data.location_id}${data.product_id}`, data);
      if (data.price_rule_id !== null) {
        multiRowDeleteSet.add(data.price_rule_id);
      }
    }

    await this.setState({
      multiRowEditMap,
      multiRowDeleteSet,
    });
  };

  toggleModal = async () => {
    const { openEditModal: modal } = this.state;
    await this.setState({
      openEditModal: !modal,
      multiPriceType: '',
      multiPriceValue: '',
      modalError: false,
    });
  };

  handleMultiEditPriceRuleChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateMultipleRows = async () => {
    const { multiPriceType, multiPriceValue, updatePriceRulesMap, multiRowEditMap, singleRowUpdateMap } = this.state;

    if (multiPriceType === '' || multiPriceValue === '') {
      await this.setState({
        modalError: true,
        errorMessage: 'Please fill all the fields',
      });
      return;
    }

    const multiPriceArray = Array.from(multiRowEditMap.keys());
    multiPriceArray.forEach(id => {
      const locationId = multiRowEditMap.get(id).location_id;
      const productId = multiRowEditMap.get(id).product_id;

      singleRowUpdateMap.set(`${locationId}${productId}`, {
        priceType: multiPriceType,
        priceValue: Math.abs(Number(multiPriceValue)),
      });

      updatePriceRulesMap.set(`${locationId}${productId}`, {
        location_id: locationId,
        product_id: productId,
        type: multiPriceType,
        value: Math.abs(Number(multiPriceValue)),
      });
    });

    await this.setState({
      updatePriceRulesMap,
      singleRowUpdateMap,
      multiRowEditMap: new Map(),
      multiRowDeleteSet: new Set(),
      multiPriceType: '',
      multiPriceValue: '',
      modalError: false,
      errorMessage: '',
      openEditModal: false,
      isAllSelected: false,
    });
  };

  applyAll = async () => {
    const { priceRules, isAllSelected: selected, multiRowEditMap, multiRowDeleteSet } = this.state;

    if (selected) {
      priceRules.forEach(data => {
        multiRowEditMap.delete(`${data.location_id}${data.product_id}`, data);
        multiRowDeleteSet.delete(data.price_rule_id);
      });
    } else {
      priceRules.forEach(data => {
        multiRowEditMap.set(`${data.location_id}${data.product_id}`, data);
        if (data.price_rule_id !== null) {
          multiRowDeleteSet.add(data.price_rule_id);
        }
      });
    }

    await this.setState({
      isAllSelected: !selected,
      multiRowEditMap,
      multiRowDeleteSet,
    });
  };

  saveChanges = async () => {
    try {
      const { updatePriceRulesMap } = this.state;
      const priceRulesArray = Array.from(updatePriceRulesMap.values());
      const finalPriceRules = [];

      priceRulesArray.forEach(rule => {
        finalPriceRules.push({
          value: rule.value,
          price_rule: rule.type,
          product_id: rule.product_id,
        });
      });

      await axios.post(`${KA_API_URL}/pricerule`, finalPriceRules);

      await this.renderPriceTableAndFilters();
      await this.setState({
        error: false,
        errorMessage: '',
        multiRowEditMap: new Map(),
        multiRowDeleteSet: new Set(),
        singleRowEditSet: new Set(),
        singleRowUpdateMap: new Map(),
        updatePriceRulesMap: new Map(),
        isAllSelected: false,
      });
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'There was a problem saving the rules. Please try later',
      });
    }
  };

  deleteSingle = async rowData => {
    if (rowData.price_rule_id !== null) {
      try {
        const priceRuleId = [rowData.price_rule_id];

        await axios.delete(`${KA_API_URL}/pricerule`, {
          data: priceRuleId,
        });

        await this.renderPriceTableAndFilters();
        await this.setState({
          error: false,
          errorMessage: '',
          multiRowEditMap: new Map(),
          multiRowDeleteSet: new Set(),
          singleRowEditSet: new Set(),
          singleRowUpdateMap: new Map(),
          updatePriceRulesMap: new Map(),
          isAllSelected: false,
        });
      } catch (err) {
        await this.setState({
          error: true,
          errorMessage: 'There was a problem clearing the rule. Please try later',
        });
      }
    }
  };

  deleteMultiple = async () => {
    try {
      const { multiRowDeleteSet } = this.state;
      const priceRuleIds = Array.from(multiRowDeleteSet);

      await axios.delete(`${KA_API_URL}/pricerule`, {
        data: priceRuleIds,
      });

      await this.renderPriceTableAndFilters();
      await this.setState({
        error: false,
        errorMessage: '',
        multiRowEditMap: new Map(),
        multiRowDeleteSet: new Set(),
        singleRowEditSet: new Set(),
        singleRowUpdateMap: new Map(),
        updatePriceRulesMap: new Map(),
        isAllSelected: false,
      });
    } catch (err) {
      await this.setState({
        error: true,
        errorMessage: 'There was a problem clearing the rules. Please try later',
      });
    }
  };

  render() {
    const {
      selectedLocations,
      selectedProducts,
      selectedPriceRules,
      allLocations,
      allProducts,
      allPriceRules,
      priceRules,
      singleRowEditSet,
      singleRowUpdateMap,
      priceRuleTypesMap,
      error,
      errorMessage,
      updatePriceRulesMap,
      dataAvailable,
      priceRuleTypes,
      multiRowEditMap,
      openEditModal,
      multiPriceValue,
      modalError,
      isAllSelected,
      multiRowDeleteSet,
      sortObject,
    } = this.state;

    let errorDiv = null;
    if (error) {
      errorDiv = (
        <div
          style={{
            fontSize: '12px',
            color: 'red',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          <h5>{errorMessage}</h5>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Header />
        <SideBar />
        <GridWrapper>
          <div className="price-rules">
            <h2>Configure Price Rules</h2>
            <PriceFilters
              locationValue={selectedLocations}
              locationOptions={allLocations}
              productValue={selectedProducts}
              productOptions={allProducts}
              priceRuleValue={selectedPriceRules}
              priceRuleOptions={allPriceRules}
              handleFilterChange={this.handleFilterChange}
              applyFilters={this.applyFilters}
            />
            <br />
            {errorDiv}
            <PriceRuleTable
              data={priceRules}
              dataAvailable={dataAvailable}
              openSingleRow={this.openSingleRow}
              singleRowEditSet={singleRowEditSet}
              handleSingleEditPriceRuleChange={this.handleSingleEditPriceRuleChange}
              singleRowUpdateMap={singleRowUpdateMap}
              updateSingleRow={this.updateSingleRow}
              priceRuleTypesMap={priceRuleTypesMap}
              priceRuleTypes={priceRuleTypes}
              multiRowEditMap={multiRowEditMap}
              handleCheck={this.handleCheck}
              applyAll={this.applyAll}
              deleteSingle={this.deleteSingle}
              isAllSelected={isAllSelected}
              sortClick={this.calculateSortData}
              sortObject={sortObject}
            />

            <PriceModal
              openEditModal={openEditModal}
              toggleModal={this.toggleModal}
              multiRowEditMap={multiRowEditMap}
              priceRuleTypes={priceRuleTypes}
              handleMultiEditPriceRuleChange={this.handleMultiEditPriceRuleChange}
              multiPriceValue={multiPriceValue}
              updateMultipleRows={this.updateMultipleRows}
              error={modalError}
              errorMessage={errorMessage}
            />
            <br />
            <div className="price-filters-button-footer">
              <ProfitRoverPrimaryButton disabled={updatePriceRulesMap.size === 0} onClick={this.saveChanges}>
                Save Changes
              </ProfitRoverPrimaryButton>
              <ProfitRoverPrimaryButton disabled={multiRowEditMap.size === 0} onClick={this.toggleModal}>
                Edit Selected
              </ProfitRoverPrimaryButton>
              <ProfitRoverDestructiveButton disabled={multiRowDeleteSet.size === 0} onClick={this.deleteMultiple}>
                Clear Selected
              </ProfitRoverDestructiveButton>
            </div>
          </div>
        </GridWrapper>
      </React.Fragment>
    );
  }
}

export default PriceRules;
