import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PasswordPolicy } from '../auth/password-policy';
import { baseProfitRoverRules } from '../auth/password-rules';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { ProfitRoverModalCard } from '../generic/ProfitRoverCard';
import { ProfitRoverLinearLogo } from '../generic/ProfitRoverLogos';
import MailToSupportLink from '../generic/MailToSupportLink';
import Header from '../header/header';
import { CheckmarkPasswordRuleList, emailIsValid } from '../registration/registrationUtil';
import { ShopifyLogo } from '../setup/modern/DataSourceLogos';
import { useRegisterShopifyMerchant } from '../../data-access/mutation/shopify';
import { signIn } from '../../redux/auth/actions';
import './shopify-redirect.scss';

const useRegistrationQueryParams = () => {
  const { search } = useLocation();

  const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const shop = searchParams.get('shopify_shop');
  const nonce = searchParams.get('shopify_state');

  return { shop, nonce };
};

const ShopifyRedirect = () => {
  const history = useHistory();
  const { shop, nonce } = useRegistrationQueryParams();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState();

  const emailIsAcceptable = emailIsValid(email);

  const togglePassword = React.useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const passwordPolicy = React.useMemo(() => new PasswordPolicy(baseProfitRoverRules), []);
  const passwordIsAllowed = React.useMemo(() => passwordPolicy.checkPassword(password), [password, passwordPolicy]);

  const dispatch = useDispatch();

  const registerMutation = useRegisterShopifyMerchant({
    onSuccess: async () => {
      try {
        // Authenticate
        await dispatch(signIn(email, password));

        // Navigate to Guided Setup
        history.push({ pathname: '/login-redirect' });
      } catch {
        setError('Unable to login at this time. Please try again later.');
      }
    },
  });

  if (!shop || !nonce) {
    // Redirect to regular login page since the registration mutation will not succeed
    history.push('/login');
  }

  const { isLoading: isRegistering, isError } = registerMutation;
  const submitButtonIsEnabled = emailIsAcceptable && passwordIsAllowed && !isRegistering;

  return (
    <HeaderAwarePage className="background-container new-shopify-registration" scrollable={false}>
      <Header />

      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
        <ProfitRoverModalCard className="px-4 py-3 d-flex flex-column align-items-center justify-content-center">
          <h5 className="mt-1 mb-1">Complete App Installation</h5>
          <div className="py-3 d-flex flex-column align-items-center justify-content-center">
            <div>
              <ProfitRoverLinearLogo className="profitrover-logo" style={{ width: 180 }} />
            </div>
            <ShopifyLogo style={{ width: 120 }} />
          </div>

          <p className="mt-1 mb-1 text-center" style={{ maxWidth: 300 }}>
            To proceed, please enter your email address and desired password.
          </p>

          <div className="d-flex flex-column w-100 mt-2 mb-3">
            <input
              type="email"
              className="mb-1"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
              <FontAwesomeIcon
                className="password-visibility-icon"
                onClick={togglePassword}
                icon={!showPassword ? faEye : faEyeSlash}
              />
            </div>
          </div>

          <CheckmarkPasswordRuleList rules={passwordPolicy.rules} />

          <ProfitRoverPrimaryButton
            disabled={!submitButtonIsEnabled}
            onClick={async () => registerMutation.mutateAsync({ shop, nonce, email, password })}
          >
            Create Account
          </ProfitRoverPrimaryButton>

          {(error || isError) && (
            <div className="error-text" style={{ maxWidth: 300 }}>
              An error occurred while setting up your account. Please enter your information again or
              <MailToSupportLink
                subject="Shopify Registration Assistance Required"
                body="I am having trouble creating an account. 
                There is an error when I press the Create Account button."
              >
                &nbsp;email our support team
              </MailToSupportLink>
              .
            </div>
          )}
        </ProfitRoverModalCard>
      </div>
    </HeaderAwarePage>
  );
};

export default ShopifyRedirect;
