/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment-timezone';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import AdminRoleCheck from './AdminRoleCheck';
import PaymentMethodChangeModal from './ManagePaymentMethodModal';
import { NotFound } from './common/CommonSubscriptionComponents';
import LoadingPage from '../common/LoadingPage';
import LoadingIndicator from '../common/LoadingIndicator';
import SettingsPageWrapper from '../SettingsPageWrapper';
import SideBar from '../sidebar';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import { ProfitRoverLinearLogo } from '../../generic/ProfitRoverLogos';
import LinkWithDirectionalArrow from '../../generic/LinkWithDirectionalArrow';
import {
  KaizenProduct,
  KaizenProductStatus,
  StripeBillingScheme,
} from '../../generic/subscriptions/SubscriptionConstants';
import Header from '../../header/header';
import { ReactComponent as VisaLogo } from '../../images/payment-icons/Visa.svg';
import { ReactComponent as MastercardLogo } from '../../images/payment-icons/Mastercard.svg';
import { ReactComponent as AmexLogo } from '../../images/payment-icons/AMEX.svg';
import { ReactComponent as DiscoverLogo } from '../../images/payment-icons/Discover.svg';
import { useSubscriptionStatusForProduct } from '../../routing/subscriptionStatusHooks';
import { formatCurrency } from '../../util/format';
import { BACKGROUND_GRAY } from '../../../colors';
import { BILLING_INFO_QUERY_KEY_BASE, useBillingInfo } from '../../../data-access/query/subscriptions';
import './manage-payment-method.css';

const BillingDate = ({ isFetching, billingInfoError, currentBillingCycleEnd }) => {
  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (billingInfoError) {
    return 'Scheduled for (Unknown)';
  }

  if (moment.invalid(currentBillingCycleEnd)) {
    return '';
  }

  const dateString = moment.unix(currentBillingCycleEnd).format('MMM Do, YYYY');

  return `Scheduled for ${dateString}`;
};

const PaymentMethodInfo = ({ billingInfoState, toggleModalOpen }) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;

  let paymentMethodDisplay;
  let cardBrandLogo;
  if (isFetching) {
    paymentMethodDisplay = <LoadingIndicator />;
  } else if (billingInfoError) {
    paymentMethodDisplay = 'Unknown Payment Method';
  } else {
    const cardBrand = billingInfo?.default_payment_method.brand?.toUpperCase();
    switch (cardBrand) {
      case 'VISA':
        cardBrandLogo = <VisaLogo />;
        break;
      case 'AMEX':
        cardBrandLogo = <AmexLogo />;
        break;
      case 'MASTERCARD':
        cardBrandLogo = <MastercardLogo />;
        break;
      case 'DISCOVER':
        cardBrandLogo = <DiscoverLogo />;
        break;
      default:
        cardBrandLogo = 'Unknown';
        break;
    }
    const last4Digits = billingInfo?.default_payment_method?.last4;
    const expMonth = billingInfo?.default_payment_method?.exp_month;
    const expYear = billingInfo?.default_payment_method?.exp_year.toString().slice(-2);
    const nameOnCard = billingInfo?.default_payment_method?.name ?? 'Unknown name';

    paymentMethodDisplay = (
      <div className="manage-payment-method-card-text" style={{ fontWeight: 450, color: '#797979' }}>
        {cardBrand}...{last4Digits}
        <p>
          Expires {expMonth}/{expYear} | {nameOnCard}
        </p>
      </div>
    );
  }

  return (
    <div className="right-side payment-method-info">
      <div className="manage-payment-method-card-header">Your Payment Method</div>
      <div className="manage-payment-method-card-inner-box">
        <div className="card-brand-logo">{cardBrandLogo}</div>
        <div className="payment-method-info">{paymentMethodDisplay}</div>
      </div>
      <div className="change-payment-method-link">
        <button type="button" onClick={toggleModalOpen}>
          Change
        </button>
      </div>
    </div>
  );
};

const SubscriptionInfo = ({ billingInfoState, isCanceled }) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;

  const currentBillingCycleEnd = billingInfo?.plan_info?.current_billing_cycle_end;
  const coupon = billingInfo?.plan_info?.coupon;

  let subscriptionInfoDisplay;
  let amountDue;
  let billingIntervalDesc;
  if (isFetching) {
    subscriptionInfoDisplay = <LoadingIndicator />;
    amountDue = <LoadingIndicator />;
  } else if (billingInfoError) {
    subscriptionInfoDisplay = 'Unknown Subscription';
    amountDue = '$X.XX    ';
  } else {
    let unitAmount;
    const { billing_scheme: billingScheme } = billingInfo?.plan_info ?? {};

    if (billingScheme === StripeBillingScheme.TIERED) {
      const { tiers } = billingInfo.plan_info;
      const [, nonFreeTier = {}] = tiers;
      unitAmount = nonFreeTier.unit_amount;
    } else {
      unitAmount = billingInfo.plan_info.unit_amount;
    }

    const unitCost = formatCurrency(unitAmount / 100);
    const couponPercentOff = coupon?.percent_off ?? 0;
    const priceWithDiscount = formatCurrency((unitAmount / 100) * (1 - couponPercentOff / 100));

    const isMonthlyPlan = billingInfo.plan_info.billing_interval === 'month';
    billingIntervalDesc = isMonthlyPlan ? 'Monthly' : 'Annual';
    const interval = isMonthlyPlan ? 'Month' : 'Year';
    const text = ` per Location per ${interval}`;

    subscriptionInfoDisplay = (
      <div className="manage-payment-method-card-text">
        <p>
          {coupon ? (
            <>
              <span className="manage-payment-method-discount-price">{unitCost}</span>
              <span>{priceWithDiscount}</span>
            </>
          ) : (
            <span>{unitCost}</span>
          )}
          <span>{text}</span>
        </p>
      </div>
    );

    amountDue = billingInfo?.next_billing_info?.total_amount_due ?? 0;
    amountDue = amountDue != null ? formatCurrency(amountDue / 100, true) : '$X.XX';
    amountDue = `${amountDue}`;
  }

  return (
    <div className="left-side subscription-info">
      <div className="manage-payment-method-card-header">Your Subscription</div>
      <div style={{ marginTop: '10px' }}>
        <ProfitRoverLinearLogo className="product-logo" />
      </div>
      {!isCanceled ? (
        <>
          <div className="manage-payment-method-card-subheader">{billingIntervalDesc ?? 'Unknown'} Plan</div>
          <div>{subscriptionInfoDisplay}</div>
          <div className="manage-payment-method-card-subheader">Next Billing</div>
          <div className="manage-payment-method-card-text" style={{ whiteSpace: 'pre-wrap' }}>
            <span>{amountDue}</span>
            <span style={{ fontSize: 12, marginRight: 12 }}> + tax</span>
            <BillingDate
              isFetching={isFetching}
              billingInfoError={billingInfoError}
              currentBillingCycleEnd={currentBillingCycleEnd}
            />
          </div>
        </>
      ) : (
        <h5 className="no-active-plan warning">No Active Plan</h5>
      )}
    </div>
  );
};

const PaymentMethodManagement = ({ subscriptionId, isCanceled }) => {
  const billingInfoState = useBillingInfo(subscriptionId);
  const [showModal, setShowModal] = React.useState(false);

  const toggleModalOpen = () => setShowModal(!showModal);

  const history = useHistory();
  const queryClient = useQueryClient();

  const blurStyles = { filter: showModal ? 'blur(8px)' : undefined };

  return (
    <HeaderAwarePage>
      <Header />
      <SideBar style={blurStyles} />
      <SettingsPageWrapper style={{ backgroundColor: BACKGROUND_GRAY, ...blurStyles }}>
        <PaymentMethodChangeModal
          show={showModal}
          subscriptionId={subscriptionId}
          onHide={toggleModalOpen}
          onSuccess={async () => {
            await queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]);
            history.push('/subscription-management');
          }}
        />

        <div className="payment-method-mgmt-container">
          <div>
            <LinkWithDirectionalArrow
              target="/subscription-management"
              text="Back to Subscription"
              direction="left"
              arrowRelativeToText="left"
              linkClassName="back-to-subscription-link"
            />
            <div className="subscription-invoices-header">Manage Payment Method</div>
          </div>
          <div className="summary">
            <ProfitRoverCard className="manage-payment-method-card" style={{ height: 'min-content' }}>
              <div className="card-layout">
                <PaymentMethodInfo billingInfoState={billingInfoState} toggleModalOpen={toggleModalOpen} />
              </div>
            </ProfitRoverCard>
            <ProfitRoverCard className="manage-payment-method-card">
              <div className="card-layout">
                <SubscriptionInfo billingInfoState={billingInfoState} isCanceled={isCanceled} />
              </div>
            </ProfitRoverCard>
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

const ManagePaymentMethodHandler = () => {
  const {
    isFetching,
    subscriptionsByKaizenProduct,
    isStripeSubscription,
    hasSubscription,
  } = useSubscriptionStatusForProduct(KaizenProduct.KP);
  if (isFetching) {
    return <LoadingPage />;
  }

  if (hasSubscription && isStripeSubscription) {
    const {
      subscription_id: kaizenPriceSubscriptionId,
      kaizen_product_status: subscriptionStatus,
    } = subscriptionsByKaizenProduct[KaizenProduct.KP];
    const isCanceled = subscriptionStatus === KaizenProductStatus.CANCELED;

    return <PaymentMethodManagement subscriptionId={kaizenPriceSubscriptionId} isCanceled={isCanceled} />;
  }

  return <NotFound />;
};

const RoleCheck = props => (
  <AdminRoleCheck>
    <ManagePaymentMethodHandler {...props} />
  </AdminRoleCheck>
);

export default RoleCheck;
