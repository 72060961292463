import React from 'react';
import LocationHoursOfOperation from './LocationHoursOfOperation';
import { useLocationsHoursOfOperation } from './locationHoursOfOperationUtil';
import LocationsTable from './LocationManagementTable';
import { useLocationTableState } from './locationManagementTableFunctions';
import SettingsPageWrapper from '../SettingsPageWrapper';
import SideBar from '../sidebar';
import LoadingPage from '../common/LoadingPage';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import Header from '../../header/header';
import './location-management.scss';

const LocationSettings = () => {
  const locationTableState = useLocationTableState();
  const { initialRowData } = locationTableState;
  const locationsHoursOfOperationState = useLocationsHoursOfOperation(initialRowData);

  if (!locationTableState.isDataReady) {
    return <LoadingPage />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <SideBar />
      <SettingsPageWrapper>
        <div className="container">
          <h1 className="settings-header">Locations</h1>
          <LocationsTable
            locationTableState={locationTableState}
            locationsHoursOfOperationState={locationsHoursOfOperationState}
          />
          <LocationHoursOfOperation locationsHoursOfOperationState={locationsHoursOfOperationState} />
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default LocationSettings;
