import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Field, Form, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Link, useHistory } from 'react-router-dom';
import { STEPS } from './commonStepControl';
import { useCreatePartnerRecords, useCreateDatasetConfigForManual } from './createDatabaseRecords';
import { DATA_SOURCE_TO_TILE_IMAGE } from './DataSourceLogos';
import { useGuidedSetupContext } from './guidedSetupContext';
import { useNewDataSourceNaicsData } from './pointOfSaleSetupHooks';
import PreviousStep from './PreviousStep';
import { ProgressBar, TrackedSteps } from './ProgressBar';
import LoadingIndicator from '../../accountSettings/common/LoadingIndicator';
import { DataType, Industry } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import {
  DataSource,
  DataSourceDisplayName,
  DataSourceToConfigName,
  isFileBasedSource,
  OAUTH_DATASOURCES,
  PAYMENT_CHANNEL_DATASOURCES,
} from '../../dataSources/dataSourceConstants';
import { dataSourceNameToSlug, dataSourceSupportsDedicatedFileUpload } from '../../dataSources/DataSourceInstructions';
import { PageHeader } from '../../forms/GenericFormComponents';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../generic/ProfitRoverTooltip';
import Header from '../../header/header';
import ProfitRoverSpinner, { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { API_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY, ItemClass } from '../../workflow/workflowConstants';
import { FONT_GRAY, SUCCESS_GREEN } from '../../../colors';
import { useUpdateDatasetConfig } from '../../../data-access/mutation/datasetConfigs';
import { useCreatePartnerSchedule } from '../../../data-access/mutation/schedules';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { useNaicsCodesItemClass } from '../../../data-access/query/naics';
import './point-of-sale-setup.scss';

const ItemClassTableHeader = ({ itemClasses }) => {
  const columnHeaders = itemClasses.map(itemClass => <th key={itemClass}>{ItemClass[itemClass] ?? 'Unknown'}</th>);

  return columnHeaders;
};

const ItemClassCheckboxGroup = ({ fields, options }) => {
  return options.map(option => {
    const toggle = event => {
      if (event.target.checked) {
        fields.push(option);
      } else {
        fields.forEach((field, i) => {
          if (fields.value[i] === option) {
            fields.remove(i);
          }
        });
      }
    };

    return (
      <td key={option}>
        <input type="checkbox" name={option} onChange={toggle} checked={fields.value.includes(option)} />
      </td>
    );
  });
};

const useIsAlreadyConfigured = (dataSource, enabled) => {
  const { data: datasetConfigs = [] } = useDatasetConfigs({ enabled });

  if (!enabled || dataSource === DataSource.MANUAL) {
    return false;
  }

  const configsByPartner = _.keyBy(
    datasetConfigs.filter(({ partner }) => partner != null),
    'partner',
  );
  const configWithPartnerExists = configsByPartner[dataSource] != null;
  const specialNameExists = datasetConfigs
    .map(config => config.name)
    .some(name => name === DataSourceToConfigName[dataSource]);

  return configWithPartnerExists || specialNameExists;
};

const DataSourceTile = ({ dataSource, label, preselectedSource, preventDuplicates }) => {
  const isAlreadyConfigured = useIsAlreadyConfigured(dataSource, preventDuplicates);

  const fieldRenderProps = useField('point-of-sale', { type: 'radio', value: dataSource });

  const preselectionPresent = preselectedSource != null;
  const thisSourceIsSelected = preselectedSource === dataSource;
  const sourceIsDisabledDueToPreselection = preselectionPresent && !thisSourceIsSelected;
  if (sourceIsDisabledDueToPreselection) {
    return null;
  }

  const { input } = fieldRenderProps;

  const props = _.pick(input, ['onChange', 'onBlur', 'onFocus', 'checked', 'value']);
  const { checked, name } = props;

  let logo = DATA_SOURCE_TO_TILE_IMAGE[dataSource];

  if (!logo) {
    // No logo exists for manual source so we must create one
    logo = (
      <div className="manual-source">
        <FontAwesomeIcon
          icon={faPlusCircle}
          color={FONT_GRAY}
          style={{ marginTop: 2, marginRight: '0.4rem', fontSize: '2rem' }}
        />
        Other
      </div>
    );
  }

  const disabled = sourceIsDisabledDueToPreselection || isAlreadyConfigured || props.disabled;

  const tile = (
    <label htmlFor={name} className={classnames('data-source-tile', { checked, disabled })}>
      {label}
      <input type="radio" {...props} disabled={disabled} />
      {logo}
      {isAlreadyConfigured && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={SUCCESS_GREEN}
          style={{ position: 'absolute', right: 3, top: '-1.3rem' }}
        />
      )}
    </label>
  );

  if (!preventDuplicates) {
    return tile;
  }

  return (
    <ProfitRoverTooltip
      shouldDisplayTooltip={isAlreadyConfigured}
      placement="auto"
      tooltipText="You have already added this data source."
      delay={200}
    >
      {tile}
    </ProfitRoverTooltip>
  );
};

const ItemClassTableRow = ({ itemClasses }) => {
  const isAtLeastOneChecked = allValues => {
    if (allValues && allValues.length !== 0) {
      return undefined;
    }

    return FORM_ERROR;
  };

  return (
    <tr>
      {/* render the item class checkbox selection cells */}
      {itemClasses.length > 0 ? (
        <FieldArray
          name="item-classes"
          component={ItemClassCheckboxGroup}
          options={itemClasses}
          validate={isAtLeastOneChecked}
        />
      ) : (
        <td>
          <i>No options available</i>
        </td>
      )}
    </tr>
  );
};

const nonEmptyStringValidator = value => ((value ?? '') === '' ? FORM_ERROR : undefined);

export const PointOfSaleForm = ({
  naicsCode,
  onSubmit,
  onCancel,
  preselectedSource,
  savedSource,
  savedSourceName,
  savedItemClasses,
  tiles,
}) => {
  const { data: itemClassMapping = [], isLoading } = useNaicsCodesItemClass(naicsCode);
  const { item_classes: itemClasses = [] } = itemClassMapping.length !== 0 ? itemClassMapping[0] : {};

  const initialFormValues = React.useMemo(
    () => ({
      'point-of-sale': savedSource ?? preselectedSource ?? null,
      'point-of-sale-name': savedSourceName ?? null,
      'item-classes': savedItemClasses ?? null,
    }),
    [preselectedSource, savedItemClasses, savedSource, savedSourceName],
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialFormValues}
      render={props => {
        const { handleSubmit, submitting, invalid, values } = props;

        const dataSource = values['point-of-sale'];
        const dataSourceName = values['point-of-sale-name'];

        const isOtherSource = dataSource === DataSource.MANUAL;

        const itemClassSectionEnabled = dataSource != null && (isOtherSource ? dataSourceName?.length > 0 : true);

        return (
          <form className="point-of-sale-form" onSubmit={handleSubmit}>
            <div role="radiogroup" className="data-source-selections">
              {tiles}
            </div>

            {isOtherSource && (
              <>
                <h6>Please give this data source a name</h6>
                <Field
                  className="other-pos-name mb-4"
                  component="input"
                  name="point-of-sale-name"
                  placeholder="Start Typing..."
                  validate={nonEmptyStringValidator}
                />
              </>
            )}

            {!isLoading ? (
              <div className={classnames('item-classes-section', { disabled: !itemClassSectionEnabled })}>
                <h6>What kind of offerings do you track within this POS?</h6>
                <ProfitRoverCard className="item-classes-pos-table-container">
                  <table className="item-classes-pos-table">
                    <thead>
                      <tr>
                        <ItemClassTableHeader itemClasses={itemClasses} />
                      </tr>
                    </thead>

                    <tbody>
                      <ItemClassTableRow itemClasses={itemClasses} />
                    </tbody>
                  </table>
                </ProfitRoverCard>
                <div className="missing-class-instructions">
                  <span className="dont-see-one-text">Don&apos;t see one of your offerings?</span>
                  <span className="contact-us-text">
                    <Link to="/help">Contact Us</Link>
                  </span>
                </div>
                <div className="actions">
                  <ProfitRoverPrimaryButton type="submit" disabled={invalid || submitting} style={{ minWidth: 70 }}>
                    {submitting ? <CenteredProfitRoverSpinner /> : 'Next'}
                  </ProfitRoverPrimaryButton>
                  {_.isFunction(onCancel) && (
                    <ProfitRoverCancelButton onClick={onCancel}>Cancel</ProfitRoverCancelButton>
                  )}
                </div>
              </div>
            ) : (
              <div className="py-5 d-flex justify-content-center w-100">
                <LoadingIndicator />
              </div>
            )}
          </form>
        );
      }}
    />
  );
};

const Heading = () => <PageHeader className="pb-3">Add New Data Source</PageHeader>;

const INSTRUCTION_LINE_1 = `Please indicate your primary Point of Sale (POS) System.`;
const ADD_NEW_DATA_SOURCE_INSTRUCTION_LINE_1 = `Please indicate your Point of Sale (POS) System.`;
const GUIDED_SETUP_INFO = `Have multiple POS systems you use? No worries, we can set those up later.`;

const determineSourceNameToSave = (source, textFieldValue) => {
  let sourceName;

  const shouldReceiveAutomaticName = source in DataSourceToConfigName;
  if (shouldReceiveAutomaticName) {
    sourceName = DataSourceToConfigName[source];
  } else if (source === DataSource.MANUAL) {
    sourceName = textFieldValue;
  } else if (OAUTH_DATASOURCES.includes(source) || PAYMENT_CHANNEL_DATASOURCES.includes(source)) {
    sourceName = `${DataSourceDisplayName[source]} Integration`;
  } else {
    sourceName = source;
  }

  return sourceName;
};

export const PointOfSaleGuidedSetup = () => {
  const {
    guidedSetup: {
      scheduled_free_call: scheduledFreeCall,
      naics_industry: { naics_code: naicsCode, ka_code: kaCode } = {},
      data_source: { source: savedSource, name: savedSourceName, item_classes: savedItemClasses } = {},
      app_store_install_source: preselectedSource,
    },
    stepState: { completeCommonFlow, setStep },
    updateGuidedSetup,
  } = useGuidedSetupContext();

  const { isInitializing, createPartnerRecords } = useCreatePartnerRecords();
  const createPartnerSchedule = useCreatePartnerSchedule();
  const updateDatasetConfig = useUpdateDatasetConfig();
  const history = useHistory();

  const onSubmit = async formState => {
    const source = formState['point-of-sale'];
    const itemClasses = formState['item-classes'];

    const sourceName = determineSourceNameToSave(source, formState['point-of-sale-name']);

    let sourceUpdates;
    if (PAYMENT_CHANNEL_DATASOURCES.includes(source)) {
      const { datasetId, datasetConfigId, workflowId } = await createPartnerRecords({
        partner: source,
        itemClasses,
        naicsCode,
        kaCode,
      });

      // Complete dataset config to trigger PROCESSing
      await updateDatasetConfig.mutateAsync({ datasetConfigId, datasetConfig: { is_editable: false } });

      // Create PROCESS schedule for pulling source data periodically
      const { schedule_ids: scheduleIds } = await createPartnerSchedule.mutateAsync({
        datasetId,
        industry: Industry.EO,
        dataType: DataType.TRAN,
        description: `${DataSourceDisplayName[source]} integration`,
      });
      const [scheduleId] = scheduleIds; // Only one schedule should have been created

      sourceUpdates = {
        dataset_id: datasetId,
        dataset_config_id: datasetConfigId,
        workflow_id: workflowId,
        schedule_id: scheduleId,
        is_complete: true,
      };
    }

    const update = {
      data_source: { source, name: sourceName, item_classes: itemClasses },
      // default price frequency to static
      price_change_frequencies: API_REPRESENTATION_FOR_STATIC_PRICE_CHANGE_FREQUENCY,
      // for non-(payment channel) partners this will be undefined and not include the object
      ...sourceUpdates,
    };

    await updateGuidedSetup(update);
    completeCommonFlow();

    if (PAYMENT_CHANNEL_DATASOURCES.includes(source)) {
      history.push('/welcome', { showCompletedGuidedSetupModal: true });
    }
  };

  const goBack = () => {
    const shouldBypassScheduleCallPage = scheduledFreeCall;
    setStep(shouldBypassScheduleCallPage ? STEPS.ONE : STEPS.TWO);
  };

  const shouldReinitializeName = savedSource === DataSource.MANUAL;

  let tiles =
    preselectedSource != null
      ? [preselectedSource]
      : [DataSource.MANUAL, DataSource.GOTAB, DataSource.SQUARE, DataSource.TOAST, DataSource.CLOVER, DataSource.ETSY];
  tiles = tiles.map(source => <DataSourceTile key={source} dataSource={source} preventDuplicates />);

  return (
    <HeaderAwarePage className="point-of-sale-setup">
      <ProgressBar activeStep={TrackedSteps.DATA_SOURCE} />
      <div className="d-flex align-items-center justify-content-center">
        <div className="wide container py-5">
          <Header />

          <PreviousStep onClick={goBack} />

          <Heading />

          {preselectedSource == null && (
            <>
              <div className="instructions-line-2">{GUIDED_SETUP_INFO}</div>
              <div className="instructions-line-1">{INSTRUCTION_LINE_1}</div>
            </>
          )}

          {isInitializing ? (
            <ProfitRoverSpinner />
          ) : (
            <PointOfSaleForm
              naicsCode={naicsCode}
              onSubmit={onSubmit}
              savedSource={savedSource}
              savedSourceName={shouldReinitializeName ? savedSourceName : null}
              savedItemClasses={savedItemClasses}
              preselectedSource={preselectedSource}
              tiles={tiles}
            />
          )}
        </div>
      </div>
    </HeaderAwarePage>
  );
};

export const CreateNewDataSource = props => {
  const { history } = props;

  const { naicsCode, kaCode } = useNewDataSourceNaicsData();

  const createDatasetConfigForManual = useCreateDatasetConfigForManual();
  const { isInitializing, createPartnerRecords } = useCreatePartnerRecords();

  const onSubmit = async formState => {
    const source = formState['point-of-sale'];
    const itemClasses = formState['item-classes'];

    let sourceName = formState['point-of-sale-name'];
    const shouldReceiveAutomaticName = source in DataSourceToConfigName;
    if (shouldReceiveAutomaticName) {
      sourceName = DataSourceToConfigName[source];
    }

    const willUploadFile = isFileBasedSource(source);
    if (willUploadFile) {
      const { datasetConfigId } = await createDatasetConfigForManual({
        configName: sourceName,
        itemClasses,
        naicsCode,
        kaCode,
      });

      let fileUploadUrl;
      if (dataSourceSupportsDedicatedFileUpload(source)) {
        const slug = dataSourceNameToSlug(sourceName);
        fileUploadUrl = `/data-sources/add-new/manual/file-upload/${slug}`;
      } else {
        fileUploadUrl = '/data-sources/add-new/manual/file-upload';
      }

      return history.push(fileUploadUrl, { datasetConfigId });
    }

    if (OAUTH_DATASOURCES.includes(source)) {
      const { datasetConfigId, datasetId, datasetConfigAncillaryId, datasetAncillaryId } = await createPartnerRecords({
        partner: source,
        itemClasses,
        naicsCode,
        kaCode,
      });

      localStorage.setItem(`${_.lowerCase(source)}_redirect_dataset_config_id`, datasetConfigId);
      localStorage.setItem(`${_.lowerCase(source)}_redirect_dataset_id`, datasetId);
      localStorage.setItem(`${_.lowerCase(source)}_redirect_dataset_config_ancillary_id`, datasetConfigAncillaryId);
      localStorage.setItem(`${_.lowerCase(source)}_redirect_dataset_ancillary_id`, datasetAncillaryId);

      return history.push(`/data-sources/add-new/${_.lowerCase(source)}`);
    }

    // Fallback (generally should not happen)
    return history.push('/data-sources');
  };
  const goBackToDataCenter = () => history.push('/data-sources');

  // Shopify is unsupported as an "Add New Data Source" because users can only integrate from the Shopify Store
  // Consider adding Shopify here and redirecting to an instruction page that tells the user to go to the Store
  const tiles = [
    DataSource.MANUAL,
    DataSource.GOTAB,
    DataSource.SQUARE,
    DataSource.CLOVER,
    DataSource.TOAST,
    DataSource.ETSY,
  ].map(source => <DataSourceTile key={source} dataSource={source} preventDuplicates />);

  return (
    <HeaderAwarePage className="point-of-sale-setup">
      <div className="wide container py-5">
        <Header />

        <Heading />

        <div className="instructions-line-1">{ADD_NEW_DATA_SOURCE_INSTRUCTION_LINE_1}</div>
        {isInitializing ? (
          <ProfitRoverSpinner />
        ) : (
          <PointOfSaleForm
            naicsCode={naicsCode}
            onSubmit={onSubmit}
            onCancel={goBackToDataCenter}
            preventDuplicates
            tiles={tiles}
          />
        )}
      </div>
    </HeaderAwarePage>
  );
};
