import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useHistory } from 'react-router-dom';
import AtAGlanceTab from './AtAGlanceTab';
import FiltersMenu, { HoursSettings, StaffingSettings } from './FiltersMenu';
import FiltersToolbar, { HoursSettingsToolbar, StaffingSettingsToolbar } from './FiltersToolbar';
import HoursTab from './HoursTab';
import PricingTab from './PricingTab';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import {
  DashboardContextContainer,
  DomainContextController,
  RevenueUpliftDashboardPage,
} from './RevenueUpliftDashboard';
import { PARAM_TO_TAB, TABS, TAB_TO_LABEL } from './revenueUpliftDashboardTabs';
import { UnexpectedError, isKnownError } from './revenueDashboardErrors';
import StaffingTab from './StaffingTab';
import RetryOrGoToFallback from '../../../generic/errorBoundary/RetryOrGoToFallback';
import GenericLoadingPage from '../../../generic/GenericLoadingPage';
import HeaderAwarePage from '../../../generic/HeaderAwarePage';
import ProfitRoverTabContent from '../../../generic/ProfitRoverTabContent';
import GuidedSetupCompleteModal from '../../../welcome/GuidedSetupCompleteModal';
import { Objective, HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import WorkflowProgress from '../../../batchProgress/WorkflowProgress';
import { Can } from '../../../../casl/abilityContext';
import { Action, Subject } from '../../../../casl/caslConstants';
import {
  gaEmitOpenFiltersMenuButtonClick,
  gaEmitUpdateWorkflowGearClick,
} from '../../../../google-analytics/revenueUpliftDashboardV2';
import './revenue-uplift-dashboard-v2.scss';

const WorkflowConfigurationIcon = () => {
  const { workflow } = React.useContext(DomainContext);
  const history = useHistory();

  const openUpdateWorkflow = e => {
    gaEmitUpdateWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflow', { workflow });
  };

  const openUpdateWorkflowDimensions = e => {
    gaEmitUpdateWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflowDimension', { workflow });
  };

  if (!workflow) {
    return null;
  }

  if (workflow.objective === Objective.PRICEELASTICITY) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon className="Icon" name="editClose" icon={faEllipsisH} onClick={openUpdateWorkflow} />
      </Can>
    );
  }
  if (workflow.objective === Objective.PRICEOPT) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon className="Icon" name="editDimension" icon={faCog} onClick={openUpdateWorkflowDimensions} />
      </Can>
    );
  }

  return null;
};

const RevenueUpliftDashboardLayout = ({ setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled }) => {
  const location = useLocation();

  const { dimensions, batchInProgress, workflowId } = React.useContext(DomainContext);
  const { staffingRolesApi, hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const { isLoadingStaffingRoles } = staffingRolesApi;
  const { isLoadingHoursSettings } = hoursOfOperationSettingsApi;
  const [filtersMenuIsOpen, setFiltersMenu] = React.useState(false);
  const [staffingSettingsIsOpen, setStaffingSettings] = React.useState(false);
  const [hoursSettingsIsOpen, setHoursSettings] = React.useState(false);
  const [selectedTabKey, setSelectedTabKey] = React.useState(TABS.AT_A_GLANCE);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tab = urlParams.get('tab');
    const newTab = PARAM_TO_TAB[tab];

    if (newTab) {
      if (newTab === TABS.PRICING) {
        setPricingScenariosEnabled(true);
      }
      if (newTab === TABS.STAFFING) {
        setStaffingEnabled(true);
      }
      if (newTab === TABS.HOURS) {
        setHoursEnabled(true);
      }

      setSelectedTabKey(newTab);
    }
  }, [location.search, setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled]);

  const setFiltersMenuIsOpen = isOpen => {
    if (isOpen) {
      setStaffingSettings(false);
      setHoursSettings(false);
    }
    setFiltersMenu(isOpen);
  };
  const setStaffingSettingsIsOpen = isOpen => {
    if (isOpen) {
      setFiltersMenu(false);
      setHoursSettings(false);
    }
    setStaffingSettings(isOpen);
  };
  const setHoursSettingsIsOpen = isOpen => {
    if (isOpen) {
      setFiltersMenu(false);
      setStaffingSettings(false);
    }
    setHoursSettings(isOpen);
  };

  const openFiltersMenu = () => {
    gaEmitOpenFiltersMenuButtonClick();
    setFiltersMenuIsOpen(true);
  };
  const openStaffingSettings = () => setStaffingSettingsIsOpen(true);
  const openHoursSettings = () => setHoursSettingsIsOpen(true);

  React.useEffect(() => {
    if (selectedTabKey === TABS.PRICING) {
      setPricingScenariosEnabled(true);
    }
    if (selectedTabKey === TABS.STAFFING) {
      setStaffingEnabled(true);
    }
    if (selectedTabKey === TABS.HOURS) {
      setHoursEnabled(true);
    }
  }, [selectedTabKey, setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled]);

  const hourDimId = dimensions?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

  const [isActiveAtAGlance, isActivePricing, isActiveStaffing, isActiveHours] = React.useMemo(
    () => [
      selectedTabKey === TABS.AT_A_GLANCE,
      selectedTabKey === TABS.PRICING,
      selectedTabKey === TABS.STAFFING,
      selectedTabKey === TABS.HOURS,
    ],
    [selectedTabKey],
  );

  return (
    <HeaderAwarePage scrollable={false}>
      <Tab.Container activeKey={selectedTabKey} onSelect={k => setSelectedTabKey(k)}>
        <ProfitRoverTabContent className="profitrover-dashboard">
          <Nav className="toolbar flex-nowrap">
            <Nav.Item>
              <Nav.Link eventKey={TABS.AT_A_GLANCE}>{TAB_TO_LABEL[TABS.AT_A_GLANCE]}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={TABS.PRICING}>{TAB_TO_LABEL[TABS.PRICING]}</Nav.Link>
            </Nav.Item>
            {hourDimId && (
              <Nav.Item>
                <Nav.Link eventKey={TABS.STAFFING}>{TAB_TO_LABEL[TABS.STAFFING]}</Nav.Link>
              </Nav.Item>
            )}
            {hourDimId && (
              <Nav.Item>
                <Nav.Link eventKey={TABS.HOURS}>{TAB_TO_LABEL[TABS.HOURS]}</Nav.Link>
              </Nav.Item>
            )}
            <div className="spacer" />
            <div className="filters-container">
              {!isLoadingStaffingRoles && (
                <StaffingSettingsToolbar openStaffingSettings={openStaffingSettings} activeTabId={selectedTabKey} />
              )}
              {!isLoadingHoursSettings && (
                <HoursSettingsToolbar openHoursSettings={openHoursSettings} activeTabId={selectedTabKey} />
              )}
              <FiltersToolbar
                openFiltersMenu={openFiltersMenu}
                activeTabId={selectedTabKey}
                activeTabLabel={TAB_TO_LABEL[selectedTabKey]}
              />
            </div>

            <WorkflowProgress batch={batchInProgress} workflowId={workflowId} />
            <div className="d-flex align-items-center ml-2">
              <WorkflowConfigurationIcon />
            </div>
          </Nav>

          <Tab.Pane eventKey={TABS.AT_A_GLANCE} unmountOnExit className="at-a-glance-pane">
            <AtAGlanceTab showPricingTab={() => setSelectedTabKey(TABS.PRICING)} isActive={isActiveAtAGlance} />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.PRICING} unmountOnExit className="pricing-pane px-3">
            <PricingTab isActive={isActivePricing} openFiltersMenu={openFiltersMenu} />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.STAFFING} unmountOnExit className="staffing-pane px-3">
            <StaffingTab isActive={isActiveStaffing} openFiltersMenu={openFiltersMenu} />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.HOURS} unmountOnExit className="hours-pane px-3">
            <HoursTab isActive={isActiveHours} openFiltersMenu={openFiltersMenu} />
          </Tab.Pane>

          <FiltersMenu
            isOpen={filtersMenuIsOpen}
            setIsOpen={setFiltersMenuIsOpen}
            activeTabId={selectedTabKey}
            activeTabLabel={TAB_TO_LABEL[selectedTabKey]}
          />
          <StaffingSettings isOpen={staffingSettingsIsOpen} setIsOpen={setStaffingSettingsIsOpen} />
          <HoursSettings isOpen={hoursSettingsIsOpen} setIsOpen={setHoursSettingsIsOpen} />
        </ProfitRoverTabContent>
      </Tab.Container>
    </HeaderAwarePage>
  );
};

const UnexpectedPageCrashError = new UnexpectedError();

const RevenueUpliftDashboardV2 = props => {
  const { location } = props;
  const { showCompletedGuidedSetupModal = false } = location?.state ?? {};

  const [showSetupCompletionModal, setShowCompletionModal] = React.useState(showCompletedGuidedSetupModal);
  const history = useHistory();

  return (
    <RevenueUpliftDashboardPage {...props}>
      {({ workflowId, setWorkflowTitle }) => (
        <>
          {showSetupCompletionModal && (
            <GuidedSetupCompleteModal
              onClose={() => {
                setShowCompletionModal(false);
                // Prevents confetti from replaying
                history.replace({ pathname: location.pathname, state: { showCompletedGuidedSetupModal: false } });
              }}
            />
          )}
          <ErrorBoundary
            fallbackRender={({ error, ...renderProps }) => (
              <RetryOrGoToFallback {...renderProps} error={isKnownError(error) ? error : UnexpectedPageCrashError} />
            )}
          >
            <DomainContextController
              workflowId={workflowId}
              Loading={() => <GenericLoadingPage />}
              setWorkflowTitle={setWorkflowTitle}
            >
              <DashboardContextContainer workflowId={workflowId}>
                {({ setPricingScenariosEnabled, setStaffingEnabled, setHoursEnabled }) => (
                  <RevenueUpliftDashboardLayout
                    setPricingScenariosEnabled={setPricingScenariosEnabled}
                    setStaffingEnabled={setStaffingEnabled}
                    setHoursEnabled={setHoursEnabled}
                    {...props}
                  />
                )}
              </DashboardContextContainer>
            </DomainContextController>
          </ErrorBoundary>
        </>
      )}
    </RevenueUpliftDashboardPage>
  );
};

export default RevenueUpliftDashboardV2;
