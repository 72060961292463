import { captureGAEvent } from './googleAnalytics';

const CATEGORY = 'At a Glance Tab';

const ACTIONS = {
  METRIC_GROSS_SALES_CLICK: 'Gross Sales dropdown click',
  METRIC_DEMAND_CLICK: 'Sales Qty dropdown click',
  METRIC_TIPS_CLICK: 'Tips dropdown click',
  METRIC_GROSS_SALES_PLUS_TIPS_CLICK: 'Gross Sales + Tips dropdown click',

  PERIOD_WEEK_CLICK: 'Last week dropdown click',
  PERIOD_MONTH_CLICK: 'Last month dropdown click',
  PERIOD_QUARTER_CLICK: 'Last quarter dropdown click',
  PERIOD_YESTERDAY_CLICK: 'Yesterday dropdown click',

  EXPAND_ANGLE_DOWN_CLICK: 'Expand angle down click',
  LOCATION_DROPDOWN_CLICK: 'Location dropdown click',
  VIEW_TOP_BY_DROPDOWN_CLICK: 'Breakdown View Top By dropdown click',

  ONE_WEEK_BACK_CLICK: 'One week back click',
  ONE_DAY_BACK_CLICK: 'One day back click',
  ONE_DAY_FORWARD_CLICK: 'One day forward click',
  ONE_WEEK_FORWARD_CLICK: 'One week forward click',

  SEE_MORE_LINK_CLICK: 'See more link click',
};

const LABELS = {
  METRIC_DROPDOWN: 'Metric dropdown',
  PERFORMANCE_REVIEW_DROPDOWN: 'Performance Review period dropdown',
  SEVEN_DAY_BUSINESS_FORECAST: '7-Day Business Forecast (At Recent Prices)',
  TOP_PRICING_RECOMMENDATIONS: 'Top Pricing Recommendations',
};

const emitEvent = gaEvent => {
  captureGAEvent({ category: CATEGORY, ...gaEvent });
};

export const gaEmitMetricDropdownClick = metricValue => {
  const action = ACTIONS[`METRIC_${metricValue}_CLICK`];
  emitEvent({ action, label: LABELS.METRIC_DROPDOWN });
};

export const gaEmitPeriodDropdownClick = periodValue => {
  const period = periodValue.toUpperCase();
  const action = ACTIONS[`PERIOD_${period}_CLICK`];
  emitEvent({ action, label: LABELS.PERFORMANCE_REVIEW_DROPDOWN });
};

export const gaEmitExpandAngleArrowClick = () => {
  emitEvent({ action: ACTIONS.EXPAND_ANGLE_DOWN_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitLocationDropdownClick = () => {
  emitEvent({ action: ACTIONS.LOCATION_DROPDOWN_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitViewTopByDropdownClick = () => {
  emitEvent({ action: ACTIONS.VIEW_TOP_BY_DROPDOWN_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitOneWeekBackControlClick = () => {
  emitEvent({ action: ACTIONS.ONE_WEEK_BACK_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitOneDayBackControlClick = () => {
  emitEvent({ action: ACTIONS.ONE_DAY_BACK_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitOneDayForwardControlClick = () => {
  emitEvent({ action: ACTIONS.ONE_DAY_FORWARD_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitOneWeekForwardControlClick = () => {
  emitEvent({ action: ACTIONS.ONE_WEEK_FORWARD_CLICK, label: LABELS.SEVEN_DAY_BUSINESS_FORECAST });
};

export const gaEmitSeeMoreLinkClick = () => {
  emitEvent({ action: ACTIONS.SEE_MORE_LINK_CLICK, label: LABELS.TOP_PRICING_RECOMMENDATIONS });
};
