import React from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { useDimensions, useCreatableDimensionTypes } from './dimensionSettingsHooks';
import SettingsPageWrapper from './SettingsPageWrapper';
import SideBar from './sidebar';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { tryCreateDimension } from '../util/mapProductDimensions';
import { FONT_WHITE, MID_GREEN } from '../../colors';
import { gaEmitAddNewDimensionCheckmarkClick } from '../../google-analytics/dataSettings';
import './dimensionSettings.css';

export default function DimensionsSettings() {
  const [errorMessage, setErrorMessage] = React.useState();

  return (
    <HeaderAwarePage>
      <Header />
      <SideBar />
      <SettingsPageWrapper>
        <div className="container">
          <h3 className="settings-header">Manage Dimensions</h3>
          <div className="dim-settings-table-area w-100">
            <DimensionsTable setErrorMessage={setErrorMessage} />
          </div>
          {errorMessage && <div style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</div>}
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
}

const centerTextInCellCSS = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const getTableColumns = (
  addingNewDimension,
  setAddingNewDimension,
  newDimensionName,
  setNewDimensionName,
  newDimensionType,
  setNewDimensionType,
  dimensionTypes,
  saveNewDimensionHandler,
  onCancel,
) => {
  const dimensionNameIsValid = newDimensionName != null && newDimensionName !== '';
  const dimensionTypeSelected = newDimensionType != null && newDimensionType !== '';
  const canSave = dimensionNameIsValid && dimensionTypeSelected;

  return [
    {
      Header: <div>Name</div>,
      id: 'dimension',
      minWidth: 50,
      headerStyle: {
        background: MID_GREEN,
        color: FONT_WHITE,
        height: '40px',
        paddingTop: '8px',
        fontWeight: 'bold',
      },
      // eslint-disable-next-line react/display-name
      accessor: d => <div style={{ ...centerTextInCellCSS }}>{d.name}</div>,
      // eslint-disable-next-line react/display-name
      Footer: () => {
        if (!addingNewDimension) {
          return null;
        }
        return (
          <div style={{ ...centerTextInCellCSS }}>
            <input
              type="text"
              style={{ width: '100%' }}
              value={newDimensionName}
              onChange={event => setNewDimensionName(event.target.value)}
            />
          </div>
        );
      },
    },
    {
      Header: <div>Type</div>,
      id: 'dimension-type',
      headerStyle: {
        background: MID_GREEN,
        color: FONT_WHITE,
        height: '40px',
        paddingTop: '8px',
        fontWeight: 'bold',
      },
      // eslint-disable-next-line react/display-name
      accessor: d => <div style={{ ...centerTextInCellCSS }}>{d.description}</div>,
      // eslint-disable-next-line react/display-name
      Footer: () => {
        if (!addingNewDimension) {
          return <div />;
        }
        return (
          <div style={{ display: 'flex' }}>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              defaultValue="DEFAULT"
              onChange={event => setNewDimensionType(event.target.value)}
              style={{ flex: 9 }}
            >
              <option value="DEFAULT" disabled>
                Choose a dimension type...
              </option>
              {dimensionTypes.map(dimType => (
                <option key={dimType.dimension_type} value={dimType.dimension_type}>
                  {dimType.description}
                </option>
              ))}
            </select>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flex: 1,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <FontAwesomeIcon
                name="editOpen"
                className="Icon"
                icon={faCheck}
                style={{
                  width: '30px',
                  pointerEvents: canSave ? 'all' : 'none',
                  cursor: canSave ? 'pointer' : 'not-allowed',
                  color: canSave ? 'black' : 'lightgray',
                }}
                onClick={() => saveNewDimensionHandler(canSave)}
              />
              <FontAwesomeIcon icon={faTimes} className="Icon" style={{ width: '30px' }} onClick={() => onCancel()} />
            </div>
          </div>
        );
      },
    },
  ];
};

const DimensionsTable = ({ setErrorMessage }) => {
  const [addingNewDimension, setAddingNewDimension] = React.useState(false);
  const [newDimensionName, setNewDimensionName] = React.useState('');
  const [newDimensionType, setNewDimensionType] = React.useState('');

  const { dimensions, refetchDimensions } = useDimensions(false);
  const { dimensionTypes } = useCreatableDimensionTypes();

  const clearNewDimensionState = () => {
    setAddingNewDimension(false);
    setNewDimensionName('');
    setNewDimensionType('');
    setErrorMessage(undefined);
  };

  const onCreateNewDimension = async () => {
    const { errors } = await tryCreateDimension(newDimensionName, newDimensionType);
    if (Array.isArray(errors) && errors.length > 0) {
      setErrorMessage(`A dimension of this type already exists.`);
    } else {
      refetchDimensions();
      clearNewDimensionState();
    }
  };

  const saveNewDimensionHandler = canSave => {
    gaEmitAddNewDimensionCheckmarkClick();

    if (canSave) {
      onCreateNewDimension();
    }
  };

  return (
    <>
      <ReactTable
        data={dimensions}
        columns={getTableColumns(
          addingNewDimension,
          setAddingNewDimension,
          newDimensionName,
          setNewDimensionName,
          newDimensionType,
          setNewDimensionType,
          dimensionTypes,
          saveNewDimensionHandler,
          clearNewDimensionState,
        )}
        getTdProps={() => {
          return {
            style: {
              borderRight: '1px solid white',
            },
          };
        }}
        getTrProps={(state, rowInfo) => {
          if (typeof rowInfo !== 'undefined') {
            return {
              style: {},
            };
          }
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal();
              }
            },
          };
        }}
        sortable={false}
        noDataText="Loading Dimensions"
        pageSizeOptions={[10]}
        minRows={1}
        defaultPageSize={10}
        className="-dims-settings"
        showPaginationBottom={dimensions.length > 10}
        style={{ minWidth: 300, width: '100%', background: 'white' }}
      />
      <div className="d-flex mt-3 save-btn-container">
        <ProfitRoverPrimaryButton onClick={() => setAddingNewDimension(true)} disabled={addingNewDimension}>
          Add Dimension
        </ProfitRoverPrimaryButton>
      </div>
    </>
  );
};
