import React from 'react';
import classnames from 'classnames';
import { Prompt, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import ShopifyStartPlanBanner from './ShopifyStartPlanBanner';
import CheckoutPanel from '../CheckoutPanel';
import { BillingDate, Branding, SubscriptionInfoCard } from '../common/CommonSubscriptionComponents';
import LocationsTable from '../LocationsTable';
import { useLocationTableState } from '../LocationsTableFunctions';
import LoadingIndicator from '../../common/LoadingIndicator';
import SettingsPageWrapper from '../../SettingsPageWrapper';
import Sidebar from '../../sidebar';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../../generic/HeaderAwarePage';
import {
  KaizenBillingInterval,
  KaizenProduct,
  KaizenProductStatus,
} from '../../../generic/subscriptions/SubscriptionConstants';
import UnpaidInvoiceBanner from '../../../generic/subscriptions/UnpaidInvoiceBanner';
import Header from '../../../header/header';
import { formatCurrency } from '../../../util/format';
import { useShopifyBillingInfo } from '../../../../data-access/query/subscriptions';
import { endTrialEarly, useRequestChangeLocationStatus } from '../../../../data-access/mutation/subscriptions';
import { useShopifyPrices } from '../../../../data-access/query/shopify';
import ReactivateSubscriptionBanner from '../../../generic/subscriptions/ReactivateSubscriptionBanner';

const getPlanType = (billingInterval, isTrialing, isFreeTier, quantity) => {
  if (isTrialing) {
    return 'Free Trial';
  }

  if (isFreeTier && quantity === 1) {
    return 'Free Tier Plan';
  }

  return billingInterval === KaizenBillingInterval.MONTHLY ? 'Monthly Plan' : 'Annual Plan';
};

const getPlanCost = (quantity, billingAmount, billingInterval, prices, isFreeTier, isTrialing, trialPeriodDays) => {
  if (isTrialing) {
    return `1 Location for ${trialPeriodDays} Days`;
  }

  if (isFreeTier && quantity < 2) {
    return `1 Free Location`;
  }

  // If there is an active subscription with a billingAmount, use that
  if (quantity > 0 && billingAmount > 0 && billingInterval) {
    const totalCost = formatCurrency(parseFloat(billingAmount) / quantity);
    return `${totalCost} per Location per ${billingInterval === KaizenBillingInterval.MONTHLY ? 'Month' : 'Year'}`;
  }

  // Otherwise get the price from the price map
  if (prices?.[billingInterval]) {
    const { unit_amount: unitAmount } = prices[billingInterval];
    const totalCost = formatCurrency(parseFloat(unitAmount));
    return `${totalCost} per Location per ${billingInterval === KaizenBillingInterval.MONTHLY ? 'Month' : 'Year'}`;
  }

  return '';
};

const PlanInfo = ({ billingInfoState, pricesState, isAutoRenewOn, isCanceled, isActive, isFreeTier, isTrialing }) => {
  const { data: billingInfo, error, isFetching } = billingInfoState;
  const {
    quantity,
    billing_amount: billingAmount,
    billing_interval: billingInterval,
    price_nickname: priceName,
    trial_period_days: trialPeriodDays,
    trial_days_remaining: trialDaysRemaining,
  } = billingInfo ?? {};
  const { data: priceMap = {}, isFetching: pricesFetching } = pricesState;

  const prices = priceMap[priceName];

  const history = useHistory();
  const navigateToManagePlanPage = () => history.push('/manage-plan/shopify');

  let planDisplay;
  if (isFetching || pricesFetching) {
    planDisplay = (
      <>
        <div className="heading-text">
          <LoadingIndicator />
        </div>
        <div className="plan-cost">
          <LoadingIndicator />
        </div>
      </>
    );
  } else if (error) {
    planDisplay = <div className="heading-text">Unknown Plan</div>;
  } else if (isCanceled) {
    planDisplay = <div className="heading-text warning">No Active Plan</div>;
  } else {
    const planType = getPlanType(billingInterval, isTrialing, isFreeTier, quantity);
    const planCost = getPlanCost(
      quantity,
      billingAmount,
      billingInterval,
      prices,
      isFreeTier,
      isTrialing,
      trialPeriodDays,
    );

    planDisplay = (
      <>
        <div className="heading-text">{planType}</div>
        <div className="plan-cost">{planCost}</div>
        {isTrialing && isAutoRenewOn && trialDaysRemaining != null && (
          <div className="days-remaining">
            <span className="days-remaining-integer">{trialDaysRemaining}</span> Days Remaining
          </div>
        )}
        {isFreeTier && <div className="first-free-text">1st Location Free</div>}
        {!isAutoRenewOn && <div className="auto-renew-disabled">Auto-Renew is Off</div>}
      </>
    );
  }

  const showManagePlan = (isActive && !isFreeTier) || (isFreeTier && quantity >= 1);

  return (
    <div className="right-side plan-info">
      <div>{planDisplay}</div>
      {showManagePlan && (
        <div className="buttons">
          <ProfitRoverSecondaryButton small onClick={navigateToManagePlanPage} disabled={isCanceled}>
            Manage Plan
          </ProfitRoverSecondaryButton>
        </div>
      )}
    </div>
  );
};

const BillingInfo = ({ billingInfoState, autoRenewIsOn, isTrialing }) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;
  const { current_billing_cycle_end: currentBillingCycleEnd, billing_interval: billingInterval } = billingInfo ?? {};

  const currentBillingCycleEndUnix = moment(currentBillingCycleEnd).unix();
  const planType = billingInterval === KaizenBillingInterval.MONTHLY ? 'Monthly Plan' : 'Annual Plan';

  return (
    <div className="left-side billing-info">
      <div>
        <div className="heading-text">
          Next Billing
          {isTrialing && autoRenewIsOn && <span className="upgrading-text"> *Upgrading to {planType}</span>}
          <div className={autoRenewIsOn ? '' : 'auto-renew-disabled'}>
            <BillingDate
              isFetching={isFetching}
              billingInfoError={billingInfoError}
              currentBillingCycleEnd={currentBillingCycleEndUnix}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ActiveLocationHeaderInfo = ({
  billingInfoState,
  pricesState,
  numberOfActiveLocations,
  isCanceled,
  isFreeTier,
  isAutoRenewOn,
  isTrialing,
}) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;
  const { billing_amount: billingAmount, billing_interval: billingInterval, price_nickname: priceName } =
    billingInfo ?? {};
  const { data: priceMap = {} } = pricesState;
  const prices = priceMap[priceName] ?? {};

  if (!isCanceled) {
    let amountDue;
    if (isFetching) {
      amountDue = <LoadingIndicator pull="right" />;
    } else if (billingInfoError) {
      amountDue = '$X.XX';
    } else if (!isAutoRenewOn) {
      amountDue = `$0.00`;
    } else if (isTrialing) {
      const { [billingInterval]: intervalPrices = {} } = prices;
      const { unit_amount: unitAmount = 0 } = intervalPrices;
      amountDue = formatCurrency(parseFloat(unitAmount), true);
    } else {
      amountDue = formatCurrency(parseFloat(billingAmount ?? 0), true);
    }

    return (
      <div className="right-side active-location-info">
        <h4 style={{ marginBottom: 0 }}>
          {amountDue}
          {!isFetching && !billingInfoError && <span className="tax-text"> + tax</span>}
        </h4>
        {isAutoRenewOn && <div className="location-count">{numberOfActiveLocations} Active Locations</div>}
        {isFreeTier && <div className="first-free-text">1st Location Free</div>}
      </div>
    );
  }

  return (
    <div className="right-side active-location-info">
      <div className="location-count">0 Active Locations</div>
      <h4>$0.00</h4>
    </div>
  );
};

const END_TRIAL_TEXT = `\
  You cannot make changes to your active locations as a trial user. \
  Start your subscription to unlock insights across more of your locations!\
`;

const ShopifySubscriptionManagmentSummary = ({
  subscriptionId,
  kaizenProductStatus,
  isFreeTier,
  // setLocationsToActivate,
  // setLocationsToDeactivate,
}) => {
  const billingInfoState = useShopifyBillingInfo(subscriptionId);
  const { data: billingInfo = {}, error, isFetching, isError } = billingInfoState;
  const locationTableState = useLocationTableState(subscriptionId);
  const { mutateAsync: requestChangeLocationStatus } = useRequestChangeLocationStatus();
  const [isLoading, setIsLoading] = React.useState(false);

  const { status: subscriptionStatus } = billingInfo;

  const isCanceled = kaizenProductStatus === KaizenProductStatus.CANCELED;
  const isActive = kaizenProductStatus === KaizenProductStatus.ACTIVE;
  const isTrialing = subscriptionStatus === KaizenProductStatus.TRIALING;

  const pricesState = useShopifyPrices(KaizenProduct.KP, {
    enabled: !isFetching && !error && !isCanceled,
  });

  const {
    resetTableState,
    numberOfActiveLocations,
    stagedLocationsPendingActivation,
    stagedLocationsPendingDeactivation,
    hasStagedChanges,
  } = locationTableState;

  const onClickContinue = async () => {
    setIsLoading(true);

    try {
      if (hasStagedChanges) {
        const { confirmation_url: confirmationUrl } = await requestChangeLocationStatus({
          subscriptionId,
          locationIdsToActivate: stagedLocationsPendingActivation.map(loc => loc.location_id),
          locationIdsToDeactivate: stagedLocationsPendingDeactivation.map(loc => loc.location_id),
        });

        if (confirmationUrl) {
          // Navigate to Shopify in the same tab
          window.open(confirmationUrl, '_self');
        } else {
          window.location.reload();
        }
      } else {
        const response = await endTrialEarly(subscriptionId);
        const { confirmation_url: confirmationUrl } = response?.data ?? {};

        if (confirmationUrl) {
          // Navigate to Shopify in the same tab
          window.open(confirmationUrl, '_self');
        } else {
          window.location.reload();
        }
      }
    } catch (_) {
      setIsLoading(false);
    }
  };

  const { quantity = 0, auto_renew: autoRenewIsOn = true } = billingInfo;
  const hideBillingInfo = isCanceled || (isFreeTier && quantity < 1);

  const locationTogglesEnabled = !isError && !isTrialing;

  let banner;
  if (isCanceled) {
    banner = <ReactivateSubscriptionBanner />;
  } else if (isTrialing && !isError) {
    banner = <ShopifyStartPlanBanner onClick={onClickContinue} disabled={isLoading} text={END_TRIAL_TEXT} />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <Sidebar />
      <SettingsPageWrapper>
        <UnpaidInvoiceBanner spacingClassName="sub-mgmt-summary-spacing" />

        <div className="container">
          <h3 className="settings-header">Subscription</h3>
          <div className={classnames('subscription-mgmt-container', { 'show-banner': banner })}>
            <div className="summary">
              <SubscriptionInfoCard>
                <Branding />
                <PlanInfo
                  billingInfoState={billingInfoState}
                  pricesState={pricesState}
                  isCanceled={isCanceled}
                  isActive={isActive}
                  isAutoRenewOn={autoRenewIsOn}
                  isFreeTier={isFreeTier}
                  isTrialing={isTrialing}
                />
              </SubscriptionInfoCard>
              {hideBillingInfo || (
                <SubscriptionInfoCard>
                  <BillingInfo
                    billingInfoState={billingInfoState}
                    autoRenewIsOn={autoRenewIsOn}
                    isTrialing={isTrialing}
                  />
                  <ActiveLocationHeaderInfo
                    billingInfoState={billingInfoState}
                    pricesState={pricesState}
                    numberOfActiveLocations={numberOfActiveLocations}
                    isCanceled={isCanceled}
                    isActive={isActive}
                    isAutoRenewOn={autoRenewIsOn}
                    isFreeTier={isFreeTier}
                    isTrialing={isTrialing}
                  />
                </SubscriptionInfoCard>
              )}
            </div>

            {banner}

            <LocationsTable
              locationTableState={locationTableState}
              locationTogglesEnabled={locationTogglesEnabled}
              autoRenewIsOn={autoRenewIsOn}
              loadingBillingInfo={billingInfoState.isLoading}
            />

            <Prompt
              when={hasStagedChanges}
              message="If you leave this page, you will lose any changes you have made to your subscription."
            />

            {hasStagedChanges && (
              <CheckoutPanel
                onContinue={onClickContinue}
                onCancel={resetTableState}
                numActivating={stagedLocationsPendingActivation.length}
                numDeactivating={stagedLocationsPendingDeactivation.length}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default ShopifySubscriptionManagmentSummary;
