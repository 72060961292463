import React from 'react';
import classnames from 'classnames';
import { faBarsStaggered, faBorderAll, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rnd } from 'react-rnd';
import { CURRENT_HOURS_COMPARISON, NUMBER_OF_VISIBLE_HOURS } from './hoursOfOperationConstants';
import HoursTabContext from './hoursTabContext';
import { hoursOfOperationToBarOffsetAndWidth, widthAndOffsetToHours } from './hoursTabUtil';
import { DashboardContext } from './revenueUpliftContexts';
import { formatNumber } from './staffingTabUtil';
import HourField from '../../../accountSettings/locationSettings/HourField';
import { areHoursOfOperationEqual } from '../../../accountSettings/locationSettings/locationHoursOfOperationUtil';
import {
  ALL_HOURS,
  CLOSE_TIME,
  IS_CLOSED,
  OPEN_24_HOURS,
  OPEN_TIME,
} from '../../../accountSettings/locationSettings/locationManagementConstants';
import { getErrorMessage } from '../../../accountSettings/locationSettings/LocationHoursOfOperation';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import {
  gaEmitBarsTableArrowsButtonClick,
  gaEmitBarsTableClosedCheckboxClick,
  gaEmitBarsTableDragHourBar,
  gaEmitGraphSourcesCheckboxClick,
  gaEmitGraphSourcesTableArrowsButtonClick,
  gaEmitResetNewHoursToCurrentHoursButtonClick,
  gaEmitResetNewHoursToRoverRecsButtonClick,
  gaEmitShowBarsViewButtonClick,
  gaEmitShowGridViewButtonClick,
  gaEmitShowMetricsCheckboxClick,
} from '../../../../google-analytics/hoursTab';

const hourColumnWidth = 67;
const dayColumnWidth = 52;
const checkboxColumnWidth = 65;
const revenueColumnWidth = 96;
const sourcesColumnWidth = 96;
const newBarHeight = 25;

const rowWidthWithMetrics =
  dayColumnWidth + checkboxColumnWidth + 2 * revenueColumnWidth + NUMBER_OF_VISIBLE_HOURS * hourColumnWidth;
const rowStyleWithMetrics = {
  width: rowWidthWithMetrics,
  minWidth: rowWidthWithMetrics,
  maxWidth: rowWidthWithMetrics,
};
const rowWidth = dayColumnWidth + checkboxColumnWidth + NUMBER_OF_VISIBLE_HOURS * hourColumnWidth;
const rowStyle = {
  width: rowWidth,
  minWidth: rowWidth,
  maxWidth: rowWidth,
};
const dayStyle = {
  width: dayColumnWidth,
  minWidth: dayColumnWidth,
  maxWidth: dayColumnWidth,
};
const checkboxStyle = {
  width: checkboxColumnWidth,
  minWidth: checkboxColumnWidth,
  maxWidth: checkboxColumnWidth,
};
const hourStyle = {
  width: hourColumnWidth,
  minWidth: hourColumnWidth,
  maxWidth: hourColumnWidth,
};
const hoursStyle = {
  width: hourColumnWidth * NUMBER_OF_VISIBLE_HOURS,
  minWidth: hourColumnWidth * NUMBER_OF_VISIBLE_HOURS,
  maxWidth: hourColumnWidth * NUMBER_OF_VISIBLE_HOURS,
};
const revenueStyle = {
  width: revenueColumnWidth,
  minWidth: revenueColumnWidth,
  maxWidth: revenueColumnWidth,
};
const sourceStyle = {
  width: sourcesColumnWidth,
  minWidth: sourcesColumnWidth,
  maxWidth: sourcesColumnWidth,
};

const formatCurrency = (value, currencySymbol) => formatNumber(value, { currencySymbol });

const NewBar = ({ offset, width, onResizeStop }) => (
  <Rnd
    className="hour-bar"
    default={{
      x: offset + 1,
      y: 0,
      width: width - 1,
      height: newBarHeight,
    }}
    size={{ width: width - 1, height: newBarHeight }}
    position={{ x: offset + 1, y: 0 }}
    onResizeStop={onResizeStop}
    disableDragging
    minHeight={newBarHeight}
    maxHeight={newBarHeight}
    minWidth={hourColumnWidth / 2 - 1}
    maxWidth={hourColumnWidth * 24}
    dragAxis="x"
    bounds="parent"
    resizeHandleClasses={{
      bottom: 'handle-hidden',
      bottomLeft: 'handle-hidden',
      bottomRight: 'handle-hidden',
      left: 'handle left',
      right: 'handle right',
      top: 'handle-hidden',
      topLeft: 'handle-hidden',
      topRight: 'handle-hidden',
    }}
  />
);

const CurrentBar = ({ offset, width }) => (
  <div
    className="current-bar"
    style={{
      height: newBarHeight * 0.4,
      width: width - 1,
      x: offset + 1,
      transform: `translate(${offset + 1}px, 0px)`,
    }}
  />
);

const RecommendedBar = ({ offset, width }) => (
  <div
    className="recommended-bar"
    style={{
      height: newBarHeight * 0.4,
      width: width - 1,
      x: offset + 1,
      transform: `translate(${offset + 1}px, 0px)`,
    }}
  />
);

const TableArrow = ({ direction = 'left', onClick, disabled, ...props }) => (
  <button type="button" className={`arrow-button ${direction}`} onClick={onClick} disabled={disabled} {...props}>
    <FontAwesomeIcon icon={direction === 'left' ? faCaretLeft : faCaretRight} />
  </button>
);

const HourBars = ({ recommended, current, newHours, setNewHours, selectedRecommendationApi }) => {
  const { selectedComparison } = React.useContext(HoursTabContext);
  const { hours, offset: hoursOffset } = selectedRecommendationApi;

  const recommendedBar = React.useMemo(
    () => hoursOfOperationToBarOffsetAndWidth(recommended, hours, hoursOffset, hourColumnWidth),
    [recommended, hours, hoursOffset],
  );
  const currentBar = React.useMemo(
    () => hoursOfOperationToBarOffsetAndWidth(current, hours, hoursOffset, hourColumnWidth),
    [current, hours, hoursOffset],
  );
  const newBar = React.useMemo(
    () => hoursOfOperationToBarOffsetAndWidth(newHours, hours, hoursOffset, hourColumnWidth),
    [newHours, hours, hoursOffset],
  );

  const compareHours = selectedComparison.value === CURRENT_HOURS_COMPARISON ? current : recommended;

  return (
    <div className="col hours" style={hoursStyle}>
      {!newHours[IS_CLOSED] && (
        <div className="hour-bar-container" style={{ height: newBarHeight }}>
          <NewBar
            {...newBar}
            setNewHours={setNewHours}
            onResizeStop={(e, direction, ref, delta, position) => {
              gaEmitBarsTableDragHourBar();
              const { start, end } = widthAndOffsetToHours(
                { width: parseInt(ref.style.width, 10), offset: position.x },
                hours,
                hoursOffset,
                hourColumnWidth,
              );
              setNewHours(start, end);
            }}
          />
        </div>
      )}
      {!compareHours[IS_CLOSED] && (
        <div className="hour-bar-container" style={{ height: newBarHeight * 0.4, marginTop: '2px' }}>
          {selectedComparison.value === CURRENT_HOURS_COMPARISON ? (
            <CurrentBar {...currentBar} />
          ) : (
            <RecommendedBar {...recommendedBar} />
          )}
        </div>
      )}
    </div>
  );
};

const ToggleButton = ({ label, isSelected, ...props }) => (
  <button
    type="button"
    className={classnames('toggle-button', {
      'toggle-button-selected': isSelected,
    })}
    {...props}
  >
    <p>{label}</p>
  </button>
);

const HoursHeader = ({ hours, offset }) => (
  <>
    {hours.map((hour, index) => (
      <div key={hour} className="col hour" style={hourStyle}>
        {hour}
        {index + offset >= 24 && <span className="next-day">Next Day</span>}
      </div>
    ))}
  </>
);

const dayOfWeekKeyToLabel = dayOfWeek => {
  switch (dayOfWeek) {
    case 'Monday':
      return 'Mon';
    case 'Tuesday':
      return 'Tue';
    case 'Wednesday':
      return 'Wed';
    case 'Thursday':
      return 'Thur';
    case 'Friday':
      return 'Fri';
    case 'Saturday':
      return 'Sat';
    case 'Sunday':
      return 'Sun';
    default:
      return dayOfWeek;
  }
};

const DayColumn = ({ day, selectedRecommendationApi }) => {
  const { locationHoursOfOperation } = selectedRecommendationApi;
  const errorFields = locationHoursOfOperation.error?.fields ?? [];

  return (
    <div
      style={dayStyle}
      className={classnames('col day', {
        error: errorFields.some(errorField => errorField.day === day),
      })}
    >
      {dayOfWeekKeyToLabel(day)}
    </div>
  );
};

const BarsTable = ({ isShowingMetrics, tableData, selectedRecommendationApi }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedComparison } = React.useContext(HoursTabContext);
  const {
    offset,
    visibleHours,
    setLocationHoursOfOperationForDay,
    onIsClosedToggledForDay,
    avgHourlyRevenueByDay,
    increaseOffset,
    decreaseOffset,
    increaseDisabled,
    decreaseDisabled,
  } = selectedRecommendationApi;

  const setNewHours = (day, start, end) => {
    const newData = {
      [OPEN_24_HOURS]: start === ALL_HOURS[0] && end === ALL_HOURS[0],
      [IS_CLOSED]: false,
      [OPEN_TIME]: start,
      [CLOSE_TIME]: end,
    };

    setLocationHoursOfOperationForDay(day, newData);
  };

  const onIncreaseOffset = () => {
    gaEmitBarsTableArrowsButtonClick();
    increaseOffset();
  };

  const onDecreaseOffset = () => {
    gaEmitBarsTableArrowsButtonClick();
    decreaseOffset();
  };

  return (
    <div className="hours-and-revenue-table" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
      <div className="row header" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
        <div className="col day" style={dayStyle}>
          Day
        </div>
        <div className="col checkbox" style={checkboxStyle}>
          Closed
        </div>
        <HoursHeader hours={visibleHours} offset={offset} />
        {isShowingMetrics && (
          <>
            <div className="col rev" style={revenueStyle}>
              Avg Rev/Hr ({selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Curr' : 'Rec'})
            </div>
            <div className="col rev" style={revenueStyle}>
              Avg Rev/Hr (New)
            </div>
          </>
        )}
        <TableArrow
          direction="left"
          onClick={onDecreaseOffset}
          disabled={decreaseDisabled}
          style={{ left: dayColumnWidth + checkboxColumnWidth }}
        />
        <TableArrow
          direction="right"
          onClick={onIncreaseOffset}
          disabled={increaseDisabled}
          style={{ left: dayColumnWidth + checkboxColumnWidth + NUMBER_OF_VISIBLE_HOURS * hourColumnWidth }}
        />
      </div>
      {tableData.map(({ day, recommended, current, newHours }) => {
        const setDayHours = (start, end) => setNewHours(day, start, end);
        const onCloseChange = () => {
          gaEmitBarsTableClosedCheckboxClick();
          onIsClosedToggledForDay(day);
        };

        return (
          <div key={day} className="row" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
            <DayColumn day={day} selectedRecommendationApi={selectedRecommendationApi} />
            <div className="col checkbox" style={checkboxStyle}>
              <input type="checkbox" checked={newHours[IS_CLOSED]} onChange={onCloseChange} />
            </div>
            <HourBars
              recommended={recommended}
              current={current}
              newHours={newHours}
              setNewHours={setDayHours}
              selectedRecommendationApi={selectedRecommendationApi}
            />
            {isShowingMetrics && (
              <>
                <div className="col rev" style={revenueStyle}>
                  {formatCurrency(
                    selectedComparison.value === CURRENT_HOURS_COMPARISON
                      ? avgHourlyRevenueByDay?.current?.[day]
                      : avgHourlyRevenueByDay?.recommended?.[day],
                    currencySymbol,
                  )}
                </div>
                <div className="col rev" style={revenueStyle}>
                  {formatCurrency(avgHourlyRevenueByDay?.newHours?.[day], currencySymbol)}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const GridTable = ({ isShowingMetrics, tableData, selectedRecommendationApi }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedComparison } = React.useContext(HoursTabContext);
  const {
    onIsClosedToggledForDay,
    locationHoursOfOperation,
    setLocationHoursOfOperationForDay,
    avgHourlyRevenueByDay,
  } = selectedRecommendationApi;

  return (
    <div className="hours-and-revenue-table" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
      <div className="row header" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
        <div className="col day" style={dayStyle}>
          Day
        </div>
        <div className="col checkbox" style={checkboxStyle}>
          Closed
        </div>
        <div className="col stretch">Open</div>
        <div className="col stretch">Close</div>
        {isShowingMetrics && (
          <>
            <div className="col rev" style={revenueStyle}>
              Avg Rev/Hr ({selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Curr' : 'Rec'})
            </div>
            <div className="col rev" style={revenueStyle}>
              Avg Rev/Hr (New)
            </div>
          </>
        )}
      </div>
      {tableData.map(({ day, current, recommended, newHours }) => {
        const compare = selectedComparison.value === CURRENT_HOURS_COMPARISON ? current : recommended;
        const openChanged =
          compare[OPEN_24_HOURS] !== newHours[OPEN_24_HOURS] || compare[OPEN_TIME] !== newHours[OPEN_TIME];
        const closeChanged =
          compare[OPEN_24_HOURS] !== newHours[OPEN_24_HOURS] || compare[CLOSE_TIME] !== newHours[CLOSE_TIME];
        const onCloseChange = () => onIsClosedToggledForDay(day);

        return (
          <div key={day} className="row" style={isShowingMetrics ? rowStyleWithMetrics : rowStyle}>
            <DayColumn day={day} selectedRecommendationApi={selectedRecommendationApi} />
            <div className="col checkbox" style={checkboxStyle}>
              <input type="checkbox" checked={newHours[IS_CLOSED]} onChange={onCloseChange} />
            </div>
            <div className="col stretch">
              <HourField
                dayHoursOfOperation={newHours}
                field={OPEN_TIME}
                errorFields={locationHoursOfOperation.error?.fields ?? []}
                setLocationHoursOfOperation={setLocationHoursOfOperationForDay}
                hideClearButton
                validateHour
              />
              {openChanged && <div className="updated-hour">*</div>}
            </div>
            <div className="col stretch">
              <HourField
                dayHoursOfOperation={newHours}
                field={CLOSE_TIME}
                errorFields={locationHoursOfOperation.error?.fields ?? []}
                setLocationHoursOfOperation={setLocationHoursOfOperationForDay}
                hideClearButton
                validateHour
              />
              {closeChanged && <div className="updated-hour">*</div>}
            </div>
            {isShowingMetrics && (
              <>
                <div className="col rev" style={revenueStyle}>
                  {formatCurrency(
                    selectedComparison.value === CURRENT_HOURS_COMPARISON
                      ? avgHourlyRevenueByDay?.current?.[day]
                      : avgHourlyRevenueByDay?.recommended?.[day],
                    currencySymbol,
                  )}
                </div>
                <div className="col rev" style={revenueStyle}>
                  {formatCurrency(avgHourlyRevenueByDay?.newHours?.[day], currencySymbol)}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const DailyHoursAndRevenueTable = ({ selectedRecommendationApi }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedComparison } = React.useContext(HoursTabContext);

  const {
    locationHoursOfOperation,
    resetToCurrent,
    resetToRecommended,
    overallAvgHourlyRevenue,
  } = selectedRecommendationApi;

  const tableData = React.useMemo(
    () =>
      locationHoursOfOperation.current.map(({ day, ...currentRest }) => ({
        day,
        current: currentRest,
        recommended: locationHoursOfOperation.recommended.find(({ day: d }) => day === d),
        newHours: locationHoursOfOperation.newHours.find(({ day: d }) => day === d),
      })),
    [locationHoursOfOperation],
  );

  const [isShowingGridView, setIsShowingGridView] = React.useState(false);
  const [isShowingMetrics, setIsShowingMetrics] = React.useState(true);

  const onResetToCurrent = () => {
    gaEmitResetNewHoursToCurrentHoursButtonClick();
    resetToCurrent();
  };

  const onResetToRecommended = () => {
    gaEmitResetNewHoursToRoverRecsButtonClick();
    resetToRecommended();
  };

  const showBarsView = () => {
    gaEmitShowBarsViewButtonClick();
    setIsShowingGridView(false);
  };
  const showGridView = () => {
    gaEmitShowGridViewButtonClick();
    setIsShowingGridView(true);
  };

  const overallNewRevenue = overallAvgHourlyRevenue.newHours ?? 0;
  const overallComparisonRevenue =
    overallAvgHourlyRevenue[selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'current' : 'recommended'];
  const uplift = overallNewRevenue - overallComparisonRevenue ?? 0;
  const upliftPercentage = (uplift / overallComparisonRevenue) * 100;

  const anyUpdateFromComparison = tableData.some(row => {
    const compareTo = selectedComparison.value === CURRENT_HOURS_COMPARISON ? row.current : row.recommended;

    return !areHoursOfOperationEqual(row.newHours, compareTo);
  });

  const newSetToRecommended = tableData.every(row => areHoursOfOperationEqual(row.newHours, row.recommended));
  const newSetToCurrent = tableData.every(row => areHoursOfOperationEqual(row.newHours, row.current));

  const onShowMetricsChange = () => {
    gaEmitShowMetricsCheckboxClick();
    setIsShowingMetrics(!isShowingMetrics);
  };

  return (
    <>
      <div className="d-flex align-items-center mt-3" style={{ gap: 20, ...rowStyleWithMetrics }}>
        <div className="tab-toggle-container">
          <ToggleButton
            label={<FontAwesomeIcon icon={faBarsStaggered} />}
            isSelected={!isShowingGridView}
            onClick={showBarsView}
          />
          <ToggleButton
            label={<FontAwesomeIcon icon={faBorderAll} />}
            isSelected={isShowingGridView}
            onClick={showGridView}
          />
        </div>
        <div className="d-flex align-items-center metrics-toggle-container" style={{ gap: 4 }}>
          <input type="checkbox" id="toggle-metrics" checked={isShowingMetrics} onChange={onShowMetricsChange} />
          <label htmlFor="toggle-metrics">Show Metrics</label>
        </div>
      </div>
      <div className="table-container">
        {isShowingGridView ? (
          <GridTable
            isShowingMetrics={isShowingMetrics}
            tableData={tableData}
            selectedRecommendationApi={selectedRecommendationApi}
          />
        ) : (
          <BarsTable
            isShowingMetrics={isShowingMetrics}
            tableData={tableData}
            selectedRecommendationApi={selectedRecommendationApi}
          />
        )}
        {!isShowingMetrics && (
          <div
            className="overall-summary"
            style={{ width: `calc(${revenueColumnWidth * 2}px - 1rem)`, marginLeft: '1rem' }}
          >
            <div className="header">Avg Rev/Hour</div>
            <div className={classnames('uplift', { negative: uplift < 0, positive: uplift >= 0 })}>
              <div className="triangle" />
              {formatCurrency(Math.abs(uplift), currencySymbol)}
            </div>
            {Number.isFinite(upliftPercentage) && (
              <div className={classnames('percentage', { negative: uplift < 0, positive: uplift >= 0 })}>
                {Math.abs(upliftPercentage).toFixed(1)}%
              </div>
            )}
            <div>New vs. {selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Current' : 'RoverRecs'}</div>
          </div>
        )}
      </div>
      <div className="table-bottom" style={rowStyleWithMetrics}>
        {isShowingGridView && anyUpdateFromComparison && (
          <div className="updated-container" style={{ gap: 10 }}>
            <div className="updated-hour">*</div>
            <div>Update from {selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Current' : 'RoverRecs'}</div>
          </div>
        )}
        {!isShowingGridView && (
          <div className="bars-legend">
            {selectedComparison.value === CURRENT_HOURS_COMPARISON ? (
              <div className="item">
                <div className="icon current" />
                <div className="label">Current Hours</div>
              </div>
            ) : (
              <div className="item">
                <div className="icon recommendations" />
                <div className="label">RoverRecs</div>
              </div>
            )}
            <div className="item">
              <div className="icon new" />
              <div className="label">New Hours</div>
            </div>
          </div>
        )}
        {isShowingMetrics && (
          <>
            <div className="overall-container">
              <div className="overall-row">
                <div className="mr-2">Overall Avg Rev/Hr</div>
                <div style={{ width: revenueColumnWidth }}>
                  {formatCurrency(overallComparisonRevenue, currencySymbol)}
                </div>
                <div style={{ width: revenueColumnWidth }}>{formatCurrency(overallNewRevenue, currencySymbol)}</div>
              </div>
              <div className={classnames('overall-row uplift mr-2', { negative: uplift < 0, positive: uplift >= 0 })}>
                <div className="triangle" />
                {formatCurrency(Math.abs(uplift), currencySymbol)}{' '}
                {Number.isFinite(upliftPercentage) ? `(${Math.abs(upliftPercentage).toFixed(1)}%)` : ''}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="error-message" style={rowStyleWithMetrics}>
        {locationHoursOfOperation.error !== null ? getErrorMessage(locationHoursOfOperation.error?.code) : ''}
      </div>
      <div className="reset-container">
        <div>Reset New Hours to:</div>
        <ProfitRoverTooltip
          shouldDisplayTooltip={newSetToCurrent}
          tooltipText="New Hours currently set to Current Hours"
          placement="bottom"
          delay={{ show: 200, hide: 100 }}
        >
          <ProfitRoverSecondaryButton onClick={onResetToCurrent} disabled={newSetToCurrent}>
            Current Hours
          </ProfitRoverSecondaryButton>
        </ProfitRoverTooltip>
        <ProfitRoverTooltip
          shouldDisplayTooltip={newSetToRecommended}
          tooltipText="New Hours currently set to RoverRecs"
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
        >
          <ProfitRoverSecondaryButton onClick={onResetToRecommended} disabled={newSetToRecommended}>
            RoverRecs
          </ProfitRoverSecondaryButton>
        </ProfitRoverTooltip>
      </div>
    </>
  );
};

export const GraphSourcesTable = ({ selectedAverageHourlyRevenueData }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedComparison } = React.useContext(HoursTabContext);
  const {
    tableData,
    visibleHours,
    offset,
    increaseOffset,
    decreaseOffset,
    increaseDisabled,
    decreaseDisabled,
  } = selectedAverageHourlyRevenueData;

  const onDecreaseOffset = () => {
    gaEmitGraphSourcesTableArrowsButtonClick();
    decreaseOffset();
  };

  const onIncreaseOffset = () => {
    gaEmitGraphSourcesTableArrowsButtonClick();
    increaseOffset();
  };

  return (
    <div className="hours-and-revenue-table graph-sources-table">
      <div className="row header">
        <div className="col graph" style={checkboxStyle}>
          Graph?
        </div>
        <div className="col source" style={sourceStyle}>
          Source
        </div>
        <HoursHeader hours={visibleHours} offset={offset} />
        <div className="col rev" style={revenueStyle}>
          Avg Rev/Hr ({selectedComparison.value === CURRENT_HOURS_COMPARISON ? 'Curr' : 'Rec'})
        </div>
        <div className="col rev" style={revenueStyle}>
          Avg Rev/Hr (New)
        </div>
        <TableArrow
          direction="left"
          onClick={onDecreaseOffset}
          disabled={decreaseDisabled}
          style={{ left: checkboxColumnWidth + sourcesColumnWidth }}
        />
        <TableArrow
          direction="right"
          onClick={onIncreaseOffset}
          disabled={increaseDisabled}
          style={{ left: checkboxColumnWidth + sourcesColumnWidth + NUMBER_OF_VISIBLE_HOURS * hourColumnWidth }}
        />
      </div>
      {tableData.map(
        ({ label, isActive, setIsActive, data: hoursData, newRevenue, currentRevenue, recommendedRevenue }) => {
          const compareRevenue =
            selectedComparison.value === CURRENT_HOURS_COMPARISON ? currentRevenue : recommendedRevenue;

          return (
            <div key={label} className="row">
              <div className="col graph" style={checkboxStyle}>
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={() => {
                    gaEmitGraphSourcesCheckboxClick();
                    setIsActive(!isActive);
                  }}
                />
              </div>
              <div className="col source" style={sourceStyle}>
                {label}
              </div>
              {hoursData.map(({ x, y }) => (
                <div key={x} className="col hour" style={hourStyle}>
                  {formatCurrency(y, currencySymbol)}
                </div>
              ))}
              <div className="col rev" style={revenueStyle}>
                {formatCurrency(compareRevenue, currencySymbol)}
              </div>
              <div className="col rev" style={revenueStyle}>
                {formatCurrency(newRevenue, currencySymbol)}
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
